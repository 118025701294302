import React, { useEffect, useState, useContext } from 'react';
import { useEcommerceContext } from './context';
import { Link } from 'react-router-dom';

import { isProductInBasket, removeItemFromBasket } from '../../components/basketUtils';

import { fetchProductData, sortAlphabeticallyWithNonAlphaAtEnd, formatNumberAsPrice } from "../../utils";
import { Product } from '../../types';

import { calculateSalesOrderBasketTotals, changeProductQuantity } from '../../components/basketUtils';
import ProductFeature from './components/productFeature';
import PrimaryButton from './components/primaryButton';

const CheckoutPage = () => {
  const { basket, setBasket } = useEcommerceContext();
  const [activeBasketItem, setActiveBasketItem] = useState("");
  useEffect(() => {
    console.log(basket, activeBasketItem)
  }, [basket, activeBasketItem]);

  const [orderNote, setOrderNote] = useState("");
  const [orderTotals, setOrderTotals] = useState({subtotal: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);

  const [fetching, setFetching] = useState(true);
  const [productData, setProductData] = useState<Product[]>([]);

  useEffect(() => {
    const { subtotal } = calculateSalesOrderBasketTotals(basket);
    setOrderTotals({subtotal});

    const basketProductCodes = basket.map(item => item.product["PRODUCT.CODE"]);
    setFilteredAndSearchedProducts(
      productData.filter(
        (product: Product) => product["PICT.FLAG"] !== "0" && !basketProductCodes.includes(product["PRODUCT.CODE"])
      )
    );
  }, [basket, productData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchProductData();
        setProductData(products);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container mx-auto flex-col gap-6 flex pb-16">
      <div className="flex items-end justify-between gap-4 w-full mt-12">
        <h1 className="font-bold text-2xl md:text-3xl">Checkout</h1>

        <Link to="/ecommerce">
          <PrimaryButton buttonText={<><i className="fa-solid fa-arrow-left"></i>&nbsp;&nbsp;Continue Shopping</>} />
        </Link>
      </div>
      
      <div className="dashboard-card">
        <div className="p-4 flex items-center">
          <h3 className="font-bold text-2xl">Your Basket</h3>
          <div className="border border-gray-300 p-2 ml-auto rounded-lg">
            {basket.length} items
          </div>
        </div>

        <table className="w-full table-auto">
          <thead>
            <tr className="table-ecommerce no-border">
              <th className="text-left-override no-border-radius"><span className="pl-2">Product</span></th>
              <th className="text-left-override">Price</th>
              <th>Quantity</th>
              <th className="text-left-override no-border-radius">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {basket.map((item, index) => (
              <tr key={index} className="no-border">
                <td className="flex items-center gap-4">
                  <div className="w-20 flex py-4">
                    <img src={typeof item.product["PICT.FLAG"] === "string" ? item.product["PICT.FLAG"] || "/product_placeholder.webp" : item.product["PICT.FLAG"][0] || "/product_placeholder.webp"} alt={item.product.DESCRIPTION} className="h-20 mx-auto" />
                  
                  </div>
                  <div className="text-left-override">
                    <p className="font-semibold">{item.product.DESCRIPTION}</p>
                    <p>{item.product.SUPPLIER}</p>
                  </div>
                  
                </td>
                <td className="text-left-override">
                  £{item.basketPrice}<br />
                  (inc VAT £{formatNumberAsPrice(item.basketPrice * 0.2)})
                </td>
                <td>
                  <div className="flex justify-between items-center gap-4 border border-gray-300 shadow-md rounded-xl">
                    <button
                      onClick={() => changeProductQuantity("decrease", item.product["PRODUCT.CODE"], basket, setBasket)}
                      disabled={item.quantity === 1}
                      className="border-r border-gray-300 py-1 px-3 text-3xl"
                    >
                      -
                    </button>
                    <p className="text-lg">{item.quantity || 1}</p>
                    <button 
                      onClick={() => changeProductQuantity("increase", item.product["PRODUCT.CODE"], basket, setBasket)}
                      className="border-l border-gray-300 py-1 px-3 text-3xl"
                    >
                      +
                    </button>
                  </div>
                </td>
                <td className="text-left-override">
                  £{formatNumberAsPrice(item.basketPrice * (item.quantity || 1))}<br />
                  (inc VAT £{formatNumberAsPrice(item.basketPrice * (item.quantity || 1) * 0.2)})
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="dashboard-card p-8">
        <h2 className="font-bold text-2xl md:text-3xl md:mr-24 mb-8">We also recommend these products...</h2>

        <div className="grid md:grid-cols-2 lg:grid-cols-6 gap-x-4 md:gap-x-8 gap-y-8">
          {sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, "DESCRIPTION")
            .sort((a: Product, b: Product) => {
              if (a["Total Stock"] > 0 && b["Total Stock"] === 0) return -1;
              if (a["Total Stock"] === 0 && b["Total Stock"] > 0) return 1;
              return 0;
            })
            .splice(0, 6)
            .map((product: Product, rowIndex: number) => {
              let itemIsInBasket = isProductInBasket(product['PRODUCT.CODE'], basket);
              return (
              <div className="flex items-center flex-col gap-2">
                <ProductFeature product={product} />
              </div>
            )}
          )}
        </div>
      </div>

      <div className="dashboard-card grid md:grid-cols-2 gap-8">
        <div className="pl-8 py-8">
          <div className="flex gap-4 items-center">
            <input
              type="text"
              placeholder="Coupon or discount code"
              className="border border-gray-300 p-2 rounded-lg w-full"
            />
            <PrimaryButton buttonText="Apply" />
          </div>
          <p className="text-muted text-sm mt-2">
            Enter a coupon or discount code and click to apply it to your order.
          </p>

          <div className="h-1 border-t border-gray-300 mt-8 mb-6"></div>

          <h3 className="font-bold text-xl">Order Note</h3>

          <textarea
            className="border border-gray-300 p-2 rounded-lg w-full mt-2"
            placeholder="Add a note to your order (optional)"
            onChange={(e) => setOrderNote(e.target.value)}
            rows={10}
          />
        </div>

        <div className="pr-8 py-8">
          <h2 className="font-bold text-2xl">Order Summary</h2>
          <p className="text-muted text-sm mt-1">
            Shipping and additional costs are calculated based on values you have entered.
          </p>

          <div className="border border-gray-300 mt-8 mb-6 rounded-xl py-4">
            <div className="flex gap-2 px-4 pb-4 items-center border-b border-gray-300">
              <div className="bg-ecommerce-green text-white rounded-xl h-10 w-10 flex items-center justify-center">
                <i className="fa-regular fa-shopping-cart"></i>
              </div>

              <h3 className="font-bold text-lg">Cart Totals</h3>
            </div>

            <div className="p-4 border-b border-gray-300">
              <div className="flex justify-between items-start gap-4">
                <div>
                  <p className="text-lg">Subtotal</p>
                  <p className="text-xl font-semibold">£{formatNumberAsPrice(orderTotals.subtotal)}</p>
                </div>

                <div className="py-1 px-2 font-semibold bg-gray-100 border border-gray-300 text-sm rounded-lg">
                  <p>Excluding VAT</p>
                </div>
              </div>
            </div>

            <div className="p-4 border-b border-gray-300">
              <p className="text-lg">Shipping</p>
              <p className="text-xl font-semibold">£143.50</p>
            </div>

            <div className="px-4 pt-4">
              <p className="text-lg">Total</p>
              <p className="text-xl font-semibold">£{formatNumberAsPrice(orderTotals.subtotal + 143.50)}</p>
            </div>
            
          </div>

          <div className="flex justify-end">
            <PrimaryButton buttonText={<>Proceed to Checkout&nbsp;&nbsp;<i className="fa-solid fa-arrow-up-right"></i></>} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPage;