import React, { useEffect, useState } from 'react';

import { processDataIntoMonthlyTotals } from '../../pages/dashboard/utils';
import { Order } from '../../types';

import SimpleLineChart from './charts/lineChart';
import MetricChangeHandler from './metricChangeHandler';

const MonthlyTotals = ({ ordersLastYear, ordersThisYear, defaultMetric, id, fetching }: { ordersLastYear: Order[], ordersThisYear: Order[], defaultMetric: string | null, id: string, fetching: boolean }) => {
  const [rollingMonthlySales, setRollingMonthlySales] = useState<{ name: string; "Last Year": number; "This Year": number; }[]>([]);
  const [widgetLoading, setWidgetLoading] = useState(true);
  const [metric, setMetric] = useState(defaultMetric ? defaultMetric : "Sales");
  const [change, setChange] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (fetching) return;
    setWidgetLoading(true);

    const monthlyFigures = processDataIntoMonthlyTotals(ordersLastYear, ordersThisYear, metric);
    setRollingMonthlySales(monthlyFigures);

    let monthsThisYearNonZero = monthlyFigures.filter(month => month["This Year"] > 0);
    console.log(monthsThisYearNonZero);
    
    if (monthsThisYearNonZero.length > 0) {
      setChange(monthsThisYearNonZero[monthsThisYearNonZero.length - 1]["This Year"] - monthsThisYearNonZero[monthsThisYearNonZero.length - 2]["This Year"]);
    }
    setWidgetLoading(false);
  }, [ordersThisYear, metric, fetching]);

  const handleMetricChange = (newMetric: string) => {
    setMetric(newMetric);
    setShowDropdown(false);
  };

  return (
    <div className="dashboard-card">
      <div className="card-header bg-green">
        <h2 className="font-semibold">HDM Monthly {metric}</h2>

        {!defaultMetric && 
          <div className="ml-auto relative">
            <i 
              className="cursor-pointer fa-regular fa-ellipsis-vertical" 
              onClick={() => setShowDropdown(!showDropdown)}
            ></i>
            {showDropdown && (
              <div className="absolute right-0 w-32 bg-white border border-gray-200 rounded shadow-md z-50 text-black">
                <ul className="">
                  <li 
                    className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${metric === 'Sales' ? 'bg-blue-100' : ''}`}
                    onClick={() => handleMetricChange('Sales')}
                  >
                    Sales
                  </li>
                  <li 
                    className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${metric === 'Orders' ? 'bg-blue-100' : ''}`}
                    onClick={() => handleMetricChange('Orders')}
                  >
                    Orders
                  </li>
                  <li 
                    className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${metric === 'Profit' ? 'bg-blue-100' : ''}`}
                    onClick={() => handleMetricChange('Profit')}
                  >
                    Profit
                  </li>
                </ul>
              </div>
            )}
          </div>
        }
      </div>

      {widgetLoading
        ? <div className="flex flex-col gap-4 items-center justify-center w-full h-48 rounded-md p-4">
            <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl md:text-4xl"></i>
            <h3 className="font-bold text-center">Fetching sales data...</h3>
          </div>
        : <>
            <p className="text-sm text-muted mb-2 px-4 pt-4">
              <MetricChangeHandler metric={metric} metricChange={change} giveTextColor={true} />
                &nbsp;{change < 0 ? `behind last month's` : `ahead of last month's`} {metric}.
            </p>
            
            <div className="pr-4 pt-2">
              <div id={id} className="">
                <SimpleLineChart chartData={rollingMonthlySales} metric={metric} />
              </div>
            </div>
          </>
      }
    </div>
  )
}

export default MonthlyTotals;