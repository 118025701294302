import React from 'react';
import { useNavigate } from 'react-router-dom';

type BackLinkProps = {
  onClick?: () => void
}

const BackLink = ({onClick}: BackLinkProps) => {
  const navigate = useNavigate();

  return (
    <button onClick={() => onClick ? onClick() : navigate(-1)}>
      <i className="fas fa-arrow-left mr-2"></i>Back
    </button>
  );
}

export default BackLink;