import React, { useState, useEffect } from 'react';
import { Product } from '../types';
import { Link } from 'react-router-dom';

import { searchPurchaseOrders, searchSalesOrders } from '../api';
import { formatNumberAsPrice } from '../utils';

interface Props {
  product: Product;
  source: "sales" | "purchase";
}

const ProductHistoryTooltip: React.FC<Props> = ({ product, source }) => {
  const [orderHistory, setOrderHistory] = useState<{ orderId: string; date: any; price: any; quantity: any; amount: any; }[]>([]);
  const [hoveredOrderHistory, setHoveredOrderHistory] = useState(false);
  const [fetchingOrderHistory, setFetchingOrderHistory] = useState(true);

  const [stockLevels, setStockLevels] = useState([]);
  const [hoveredStockLevels, setHoveredStockLevels] = useState(false);
  const [incomingStockSheffield, setIncomingStockSheffield] = useState(0);

  const [salesOrderHistory, setSalesOrderHistory] = useState<{ orderId: string; date: any; price: number; quantity: any; amount: number; }[]>([]);
  const [fetchingSalesOrderHistory, setFetchingSalesOrderHistory] = useState(true);
  const [hoveredSalesOrderHistory, setHoveredSalesOrderHistory] = useState(false);

  useEffect(() => {
    console.log("Product History Tooltip", hoveredOrderHistory);
    async function fetchData() {
      setFetchingOrderHistory(true);
      let searchResult = await searchPurchaseOrders({ "PRODUCT.CODE": product["PRODUCT.CODE"]});

      let ordersInOrder = searchResult.reverse();
      let formattedOrderHistory = [];

      console.log("Product:", product["PRODUCT.CODE"]);
      console.log(ordersInOrder);
      let incomingStockSheffieldCounter = 0;

      for (let order of ordersInOrder) {
        if (typeof order["ORDER.QUANTITY"] === "string") {
          if (formattedOrderHistory.length < 3) {
            formattedOrderHistory.push({
              orderId: order["KEY"],
              date: order["ORDER.DATE"],
              price: parseFloat(order["PRICE"]),
              quantity: order["ORDER.QUANTITY"],
              amount: parseFloat(order["PRICE"]) * parseFloat(order["ORDER.QUANTITY"]),
            });
          }

          if (order["PO.STATUS"] === "OR" || "PR") incomingStockSheffieldCounter += parseFloat(order["ORDER.QUANTITY"]);
        }
        else {
          console.log(order);
          const productIndex = order["PRODUCT.CODE"].indexOf(product["PRODUCT.CODE"]);
          if (productIndex) {
            if (formattedOrderHistory.length < 3) {
              formattedOrderHistory.push({
                orderId: order["KEY"],
                date: order["ORDER.DATE"],
                price: parseFloat(order["PRICE"][productIndex]),
                quantity: order["ORDER.QUANTITY"][productIndex],
                amount: parseFloat(order["PRICE"][productIndex]) * parseFloat(order["ORDER.QUANTITY"][productIndex]),
              });
            }

            if (order["PO.STATUS"] === "OR" || "PR") incomingStockSheffieldCounter += parseFloat(order["ORDER.QUANTITY"][productIndex]);
          }
        }
      }
      console.log(formattedOrderHistory);
      setOrderHistory(formattedOrderHistory);
      setIncomingStockSheffield(incomingStockSheffieldCounter);
      setFetchingOrderHistory(false);
      console.log(searchResult);
    }

    (hoveredOrderHistory ||  hoveredStockLevels) && orderHistory.length === 0 && fetchData();
  }, [hoveredOrderHistory, hoveredStockLevels, ]);

  useEffect(() => {
    async function fetchData() {
      console.log("Fetching Sales Order History for Product", product["PRODUCT.CODE"]);
      let searchResult = await searchSalesOrders({ "PRODUCT.CODE": product["PRODUCT.CODE"]});

      let ordersInOrder = searchResult.reverse();
      let formattedOrderHistory = [];

      console.log("Found orders for Sales Order History", ordersInOrder);

      for (let order of ordersInOrder) {
        if (formattedOrderHistory.length < 3) break;

        if (typeof order["ORDER.QUANTITY"] === "string") {
          
            formattedOrderHistory.push({
              orderId: order["ORDER.NO"],
              date: order["ORDER.DATE"],
              price: parseFloat(order["PRICE"]),
              quantity: order["ORDER.QUANTITY"],
              amount: parseFloat(order["PRICE"]) * parseFloat(order["ORDER.QUANTITY"]),
            });
        }
        else {
          console.log(order);
          const productIndex = order["PRODUCT.CODE"].indexOf(product["PRODUCT.CODE"]);
          if (productIndex) {
            formattedOrderHistory.push({
              orderId: order["ORDER.NO"],
              date: order["ORDER.DATE"],
              price: parseFloat(order["PRICE"][productIndex]),
              quantity: order["ORDER.QUANTITY"][productIndex],
              amount: parseFloat(order["PRICE"][productIndex]) * parseFloat(order["ORDER.QUANTITY"][productIndex]),
            });
          }
        }
      }

      console.log(formattedOrderHistory);
      setSalesOrderHistory(formattedOrderHistory);
      setFetchingSalesOrderHistory(false);
      console.log(searchResult);
    }

    hoveredSalesOrderHistory && salesOrderHistory.length === 0 && fetchData();
  }, [hoveredSalesOrderHistory]);

  return <div className="relative w-full">
  {/* Ellipsis Button */}
  <div className="flex justify-center w-full py-2 cursor-pointer group">
    <i className="fas fa-ellipsis-v"></i>
  </div>

  {/* Outer Dropdown */}
  <div className="absolute top-8 right-0 bg-white rounded-md shadow-md opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50 border border-gray-300 w-44"> 
    {source === "purchase" 
      ? <div className="relative hover-children-visible">
          <div 
            className="border-b hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm"
            onMouseEnter={() => setHoveredOrderHistory(true)}
          >
            <p>Purchase History</p>
            <i className="fas fa-chevron-right"></i>
          </div>

          <div className="absolute top-0 left-44 bg-white rounded-md shadow-md pointer-events-none z-50 w-auto border border-gray-300 hidden-until-hover">
            {fetchingOrderHistory 
              ? <div className="flex justify-center items-center w-full py-4 min-w-40">
                  <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl md:text-4xl"></i>
                </div>
              : orderHistory.length === 0
                ? <div className="flex justify-center items-center w-full py-4 min-w-48">
                    <p className="text-sm">No purchase history found.</p>
                  </div>
                : <table className="table w-full text-left">
                    <thead>
                      <tr>
                      <th className="border-b border-gray-400 text-center text-sm pl-4">Date</th>
                      <th className="border-b border-gray-400 text-center text-sm px-1">Price</th>
                      <th className="border-b border-gray-400 text-center text-sm px-1">Quantity</th>
                      <th className="border-b border-gray-400 text-center text-sm pr-4">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderHistory.map((order, index) => (
                        <tr key={index}>
                          <td>
                            <Link 
                              className="text-sm text-center px-1 pl-4 hover:underline"
                              to={`/purchasing/po/${order.orderId}`}
                              target='_blank'
                            >
                              {new Intl.DateTimeFormat('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: '2-digit'
                              }).format(new Date(order.date))}
                            </Link>
                          </td>
                          <td><p className="text-sm text-center px-1">£{(order.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p></td>
                          <td><p className="text-sm text-center px-1">{order.quantity}</p></td>
                          <td><p className="text-sm text-center px-1 pr-4">£{order.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              }
          </div> 
        </div>
      : <div className="relative hover-children-visible">
          <div 
            className="border-b hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm"
            onMouseEnter={() => setHoveredSalesOrderHistory(true)}
          >
            <p>Sales History</p>
            <i className="fas fa-chevron-right"></i>
          </div>

          <div className="absolute top-0 left-44 bg-white rounded-md shadow-md pointer-events-none z-50 w-auto border border-gray-300 hidden-until-hover">
            {fetchingSalesOrderHistory 
              ? <div className="flex justify-center items-center w-full py-4 min-w-40">
                  <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl md:text-4xl"></i>
                </div>
              : salesOrderHistory.length === 0
                ? <div className="flex justify-center items-center w-full py-4 min-w-40">
                    <p className="text-sm">No sales history found.</p>
                  </div>
                : <table className="table w-full text-left">
                    <thead>
                      <tr>
                      <th className="border-b border-gray-400 text-center text-sm pl-4">Date</th>
                      <th className="border-b border-gray-400 text-center text-sm px-1">Price</th>
                      <th className="border-b border-gray-400 text-center text-sm px-1">Quantity</th>
                      <th className="border-b border-gray-400 text-center text-sm pr-4">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesOrderHistory.map((order, index) => (
                        <tr key={index}>
                          <td>
                            <Link 
                              className="text-sm text-center px-1 pl-4 hover:underline"
                              to={`/sales/sales-order/${order.orderId}`}
                              target='_blank'
                            >
                              {new Intl.DateTimeFormat('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: '2-digit'
                              }).format(new Date(order.date))}
                            </Link>
                          </td>
                          <td><p className="text-sm text-center px-1">£{(order.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p></td>
                          <td><p className="text-sm text-center px-1">{order.quantity}</p></td>
                          <td><p className="text-sm text-center px-1 pr-4">£{order.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
              }
          </div> 
        </div>
    }

    <div className="relative hover-children-visible">
      <div 
        className="border-b hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm"
        onMouseEnter={() => setHoveredStockLevels(true)}
      >
        <p>Stock Levels</p>
        <i className="fas fa-chevron-right"></i>
      </div>

      <div className="absolute top-0 left-44 bg-white rounded-md shadow-md pointer-events-none z-50 w-auto border border-gray-300 hidden-until-hover">
        {fetchingOrderHistory 
          ? <div className="flex justify-center items-center w-full py-4 min-w-40">
              <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl md:text-4xl"></i>
            </div>
          :<table className="table w-full text-left">
              <thead>
                <tr>
                  <th className="border-b border-gray-400 text-center text-sm pl-4">Branch</th>
                  <th className="border-b border-gray-400 text-center text-sm px-1 shrink-0 no-wrap">Stock</th>
                  <th className="border-b border-gray-400 text-center text-sm px-1 pr-4">Incoming</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><p className="text-sm text-center px-1 pl-4">Sheffield</p></td>
                  <td><p className="text-sm text-center px-1">{product.SHEFFIELD || 0}</p></td>
                  <td><p className="text-sm text-center px-1 pr-4">{incomingStockSheffield}</p></td>
                </tr>
                <tr>
                  <td><p className="text-sm text-center px-1 pl-4">Hull</p></td>
                  <td><p className="text-sm text-center px-1">{product.HULL}</p></td>
                  <td><p className="text-sm text-center px-1 pr-4">0</p></td>
                </tr>
                <tr>
                  <td><p className="text-sm text-center px-1 pl-4">Bournemouth</p></td>
                  <td><p className="text-sm text-center px-1">{product.BOURNEMOUTH}</p></td>
                  <td><p className="text-sm text-center px-1 pr-4">0</p></td>
                </tr>
              </tbody>
            </table>
        }
      </div>
    </div>

    <div 
      className="hover:bg-gray-100 p-2 cursor-pointer relative flex justify-between items-center text-sm"
      //onClick={() => removeItemFromBasket(item.product["PRODUCT.CODE"], basket, setBasketViaDirtyForm, setActiveBasketItem, activeBasketItem)}
    >
      <p>Remove Item</p>
      <i className="fas fa-trash"></i>
    </div>
  </div>
</div>
}

export default ProductHistoryTooltip;