import { Order } from '../../types';
import { getDateFromD3DateAndTime } from '../../utils';

const getPreviousWeekRange = () => {
  const currentDate = new Date();
  
  let dayOfWeek = currentDate.getDay() === 0 ? 7 : currentDate.getDay();

  const endOfPreviousWeek = new Date(currentDate);
  endOfPreviousWeek.setDate(currentDate.getDate() - dayOfWeek);

  const startOfPreviousWeek = new Date(endOfPreviousWeek);
  startOfPreviousWeek.setDate(endOfPreviousWeek.getDate() - 6);

  startOfPreviousWeek.setHours(0, 0, 0, 0);
  endOfPreviousWeek.setHours(23, 59, 59, 999);

  return { startOfPreviousWeek, endOfPreviousWeek };
};

const getCurrentWeekRange = () => {
  const currentDate = new Date();
  
  let dayOfWeek = currentDate.getDay() === 0 ? 7 : currentDate.getDay();

  const startOfCurrentWeek = new Date(currentDate);
  startOfCurrentWeek.setDate(currentDate.getDate() - dayOfWeek + 1);

  const endOfCurrentWeek = new Date(startOfCurrentWeek);
  endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

  startOfCurrentWeek.setHours(0, 0, 0, 0);
  endOfCurrentWeek.setHours(23, 59, 59, 999);

  return { startOfCurrentWeek, endOfCurrentWeek };
}

export const groupOrders = (orders: Order[]) => {
  const { startOfPreviousWeek, endOfPreviousWeek } = getPreviousWeekRange();
  const { startOfCurrentWeek, endOfCurrentWeek } = getCurrentWeekRange();

  let ordersThisYear: Order[] = [];
  let ordersLastYear: Order[] = [];
  let ordersLastWeek: Order[] = [];
  let ordersThisWeek: Order[] = [];

  orders.forEach((order: Order) => {
    order["formattedDate"] = getDateFromD3DateAndTime(order["ORDER.DATE"], order["ORDER.TIME"])

    if (order["formattedDate"].getFullYear() === new Date().getFullYear()) {
      ordersThisYear.push(order);

      if (order["formattedDate"] >= startOfPreviousWeek && order["formattedDate"] <= endOfPreviousWeek) {
        ordersLastWeek.push(order);
      }
  
      if (order["formattedDate"] >= startOfCurrentWeek && order["formattedDate"] <= endOfCurrentWeek) {
        ordersThisWeek.push(order);
      }

    } else if (order["formattedDate"].getFullYear() === new Date().getFullYear() - 1) {
      ordersLastYear.push(order);
    }
  });

  return { ordersThisYear, ordersLastYear, ordersLastWeek, ordersThisWeek };
}

export const processDataIntoWeeklyChanges = (ordersThisWeek: Order[], ordersLastWeek: Order[]) => {

  let totalOrders = {thisWeek: 0, lastWeek: 0};
  let totalSales = {thisWeek: 0, lastWeek: 0};
  let totalProfit = {thisWeek: 0, lastWeek: 0};

  let currentDate = new Date();
  let currentDayOfWeek = currentDate.getDay();
  let currentTimeOfDay = currentDate.getHours() * 60 + currentDate.getMinutes();

  ordersLastWeek.forEach((order: Order) => {
    const orderDayOfWeek = order["formattedDate"].getDay(); // 0 (Sunday) - 6 (Saturday)
    const orderTimeOfDay = order["formattedDate"].getHours() * 60 + order["formattedDate"].getMinutes();

    if (
      (orderDayOfWeek < currentDayOfWeek) || 
      (orderDayOfWeek === currentDayOfWeek && orderTimeOfDay <= currentTimeOfDay)
    ) {
      totalOrders.lastWeek++;
      totalSales.lastWeek += order["ORDER.VAL"];
      totalProfit.lastWeek += order["MARGIN.SOLD"];
    }
  });

  ordersThisWeek.forEach((order: Order) => {
    totalOrders.thisWeek++;
    totalSales.thisWeek += order["ORDER.VAL"];
    totalProfit.thisWeek += order["MARGIN.SOLD"];
  });

  return { totalOrders, totalSales, totalProfit };
};

export const processDataIntoYearlyChanges = (ordersThisYear: Order[], ordersLastYear: Order[]) => {
  let totalOrders = {thisYear: 0, lastYear: 0};
  let totalSales = {thisYear: 0, lastYear: 0};
  let totalProfit = {thisYear: 0, lastYear: 0};

  let totalOrdersWithExternalRep = {thisYear: 0, lastYear: 0};
  let totalSalesWithExternalRep = {thisYear: 0, lastYear: 0};
  let totalProfitWithExternalRep = {thisYear: 0, lastYear: 0};

  ordersLastYear.forEach((order: Order) => {
    totalOrders.lastYear++;
    totalSales.lastYear += order["ORDER.VAL"];
    totalProfit.lastYear += order["MARGIN.SOLD"];

    if (order["REP"] !== "") {
      totalOrdersWithExternalRep.lastYear++;
      totalSalesWithExternalRep.lastYear += order["ORDER.VAL"];
      totalProfitWithExternalRep.lastYear += order["MARGIN.SOLD"];
    }
  });

  ordersThisYear.forEach((order: Order) => {
    totalOrders.thisYear++;
    totalSales.thisYear += order["ORDER.VAL"];
    totalProfit.thisYear += order["MARGIN.SOLD"];

    if (order["REP"] !== "") {
      totalOrdersWithExternalRep.thisYear++;
      totalSalesWithExternalRep.thisYear += order["ORDER.VAL"];
      totalProfitWithExternalRep.thisYear += order["MARGIN.SOLD"];
    }
  });

  return { totalOrders, totalSales, totalProfit, totalOrdersWithExternalRep, totalSalesWithExternalRep, totalProfitWithExternalRep };
}

export const processDataIntoDailyTotals = (ordersThisWeek: Order[], ordersLastWeek: Order[], metric: string) => {

  let dataKey: keyof Order | null = null;
  switch (metric) {
    case "Orders":
      dataKey = null;
      break;
    case "Profit":
      dataKey = "MARGIN.SOLD";
      break;
    case "Sales":
    default:
      dataKey = "ORDER.VAL";
  }


  const rollingWeeklyTotals = [
    { name: "Mon", "Last Week": 0, "This Week": 0 },
    { name: "Tue", "Last Week": 0, "This Week": 0 },
    { name: "Wed", "Last Week": 0, "This Week": 0 },
    { name: "Thu", "Last Week": 0, "This Week": 0 },
    { name: "Fri", "Last Week": 0, "This Week": 0 },
    { name: "Weekend", "Last Week": 0, "This Week": 0 },
  ];

  ordersThisWeek.forEach((order: Order) => {
    const dayOfWeek = order.formattedDate.toLocaleDateString('en-GB', { weekday: 'short' }) as "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";

    let index: number;
    if (dayOfWeek === "Sat" || dayOfWeek === "Sun") {
      index = 5;
    } else {
      index = rollingWeeklyTotals.findIndex((day) => day.name === dayOfWeek);
    }

    if (metric === "Orders") {
      rollingWeeklyTotals[index]["This Week"] += 1;
    } else if (dataKey && typeof order[dataKey] === "number") {
      rollingWeeklyTotals[index]["This Week"] += order[dataKey] as number;
    }
  });

  ordersLastWeek.forEach((order: Order) => {
    const dayOfWeek = order.formattedDate.toLocaleDateString('en-GB', { weekday: 'short' }) as "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";

    let index: number;
    if (dayOfWeek === "Sat" || dayOfWeek === "Sun") {
      index = 5;
    } else {
      index = rollingWeeklyTotals.findIndex((day) => day.name === dayOfWeek);
    }

    if (metric === "Orders") {
      rollingWeeklyTotals[index]["Last Week"] += 1;
    } else if (dataKey && typeof order[dataKey] === "number") {
      rollingWeeklyTotals[index]["Last Week"] += order[dataKey] as number;
    }
  });

  return rollingWeeklyTotals;
};

export const processDataIntoWeeklyTotals = (ordersThisYear: any, metric: string) => {

  let currentMonth = new Date().getMonth();
  let weeklyData = [] as any;
  let formattedWeeklyData = [] as any;

  let dataKey = "";
  if (metric === "Sales") dataKey = "ORDER.VAL";
  else if (metric === "Profit") dataKey = "MARGIN.SOLD";

  if (currentMonth > 3) {
    ordersThisYear.sort((a: any, b: any) => b.formattedDate - a.formattedDate);

    console.log("Orders YTD:", ordersThisYear);
    let weeksAgo = 0;
    let weeksIterated = [] as number[];
    let i = 0;

    let currentWeekRange = getCurrentWeekRange();
    let startOfCurrentWeek = new Date(currentWeekRange.startOfCurrentWeek);
    let endOfCurrentWeek = new Date(currentWeekRange.endOfCurrentWeek);
    let weekString = startOfCurrentWeek.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' }) + " - " + endOfCurrentWeek.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });

    while (weeksAgo < 7) {
      if (weeksAgo > 0 && !weeksIterated.includes(weeksAgo)) {
        startOfCurrentWeek.setDate(startOfCurrentWeek.getDate() - 7);
        endOfCurrentWeek.setDate(endOfCurrentWeek.getDate() - 7);
        weekString = startOfCurrentWeek.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' }) + " - " + endOfCurrentWeek.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
        weeksIterated.push(weeksAgo);
      }

      if (!ordersThisYear[i]) {
        weeklyData[weekString] = 0;
        weeksAgo++;
        continue;
      }

      if (ordersThisYear[i].formattedDate < startOfCurrentWeek) weeksAgo++;

      if (metric === "Orders") {
        weeklyData[weekString] 
          ? weeklyData[weekString] += 1 
          : weeklyData[weekString] = 1;
      }
      else {
        weeklyData[weekString] 
          ? weeklyData[weekString] += ordersThisYear[i][dataKey] 
          : weeklyData[weekString] = ordersThisYear[i][dataKey];
      }

      i++;
    }

    //console.log("Weekly data:", weeklyData);
    formattedWeeklyData = Object.keys(weeklyData).map((week) => { 
      return { name: week, [metric]: weeklyData[week] };
    });
  }

  return formattedWeeklyData.reverse();
};

export const processDataIntoMonthlyTotals = (ordersLastYear: Order[], ordersThisYear: Order[], metric: string) => {
  const rollingMonthlySales = [ 
    { name: "Jan", "Last Year": 0, "This Year": 0 }, 
    { name: "Feb", "Last Year": 0, "This Year": 0 }, 
    { name: "Mar", "Last Year": 0, "This Year": 0 }, 
    { name: "Apr", "Last Year": 0, "This Year": 0 }, 
    { name: "May", "Last Year": 0, "This Year": 0 }, 
    { name: "Jun", "Last Year": 0, "This Year": 0 }, 
    { name: "Jul", "Last Year": 0, "This Year": 0 }, 
    { name: "Aug", "Last Year": 0, "This Year": 0 }, 
    { name: "Sep", "Last Year": 0, "This Year": 0 }, 
    { name: "Oct", "Last Year": 0, "This Year": 0 }, 
    { name: "Nov", "Last Year": 0, "This Year": 0 }, 
    { name: "Dec", "Last Year": 0, "This Year": 0 } 
  ];

  let dataKey: keyof Order | null = null;
  switch (metric) {
    case "Orders":
      dataKey = null;
      break;
    case "Profit":
      dataKey = "MARGIN.SOLD";
      break;
    case "Sales":
    default:
      dataKey = "ORDER.VAL";
  }

  const setMonthlyTotalForYear = (orders: Order[], key: "Last Year" | "This Year") => {
    orders.forEach((order: Order) => {
      let month = order.formattedDate.toLocaleDateString('en-GB', { month: 'short' });
      month = month.length > 3 ? month.slice(0, 3) : month;

      let index = rollingMonthlySales.findIndex((monthData) => monthData.name === month);

      if (metric === "Orders") {
        rollingMonthlySales[index][key] +=  1;
      } else if (dataKey && typeof order[dataKey] === "number") {
        rollingMonthlySales[index][key] += order[dataKey] as number;
      }
    });
  }

  setMonthlyTotalForYear(ordersLastYear, "Last Year");
  setMonthlyTotalForYear(ordersThisYear, "This Year");

  return rollingMonthlySales;
}
