import { useId, useState } from "react";
import { AvailableThemes, EmailAttachment } from "../types";
import Attachment from "./emailAttachment";

type EmailProps = 
    {
        to: string;
        cc?: string;
        bcc?: string;
        message: string;
        headers: string;
        sent: Date;
        attachments: EmailAttachment[];
        theme?: AvailableThemes;
        onClose: () => void;
    }


const Email = ({to, cc, bcc, message, headers, sent, attachments, theme = "green", onClose}: EmailProps) => {
    const [showingHeaders, setShowingHeaders] = useState(false);
    const emailId = useId();
    
    return (
        <div className="grid grid-cols-[auto_1fr] gap-2 w-full">
            <div className="col-span-2 w-full flex flex-row-reverse place-items-end"><button className={`btn btn-primary${theme === 'green' ? ' bg-green' : ''}`} onClick={onClose}>Back</button></div>
            <label className="font-semibold" htmlFor={`to-${emailId}`}>To: </label>
            <span className="text-sm text-gray-500" id={`to-${emailId}`}>{to}</span>
            {cc &&  <>
                        <label className="font-semibold" htmlFor={`cc-${emailId}`}>CC:</label>
                        <span className="text-sm text-gray-500" id={`cc-${emailId}`}>{cc}</span>
                    </>
            }
            {bcc && <>
                        <label className="font-semibold" htmlFor={`bcc-${emailId}`}>BCC:</label>
                        <span className="text-sm text-gray-500" id={`bcc-${emailId}`}>{bcc}</span>
                    </>
            }
            <label className="font-semibold" htmlFor={`sent-${emailId}`}>Sent:</label>
            <span className="text-sm text-gray-500" id={`sent-${emailId}`}>{sent.toLocaleString()}</span>
            <div className="col-span-2"><button className={`btn btn-primary${theme === 'green' ? ' bg-green' : ''}`} onClick={() => setShowingHeaders(!showingHeaders)}>Headers...</button></div>
            {showingHeaders &&  <>
                                    <label className="font-semibold" htmlFor={`headers-${emailId}`}>Headers:</label>
                                    <textarea disabled id={`headers-${emailId}`} className="resize-y overflow-y-scroll h-60">{headers}</textarea>
                                </>
            }
            
            <label className="font-semibold col-span-2" htmlFor={`attachments-${emailId}`}>Attachments:</label>
            <div className="flex flex-row flex-wrap gap-2 col-span-2" id={`attachments-${emailId}`}>
                {attachments.map((attachment) => <Attachment fileName={attachment.fileName} fileSizeInBytes={attachment.fileSizeInBytes} contentId={attachment.contentId} isFirstComment={true} theme={theme}/>)}
            </div>
            <label className="font-semibold col-span-2" htmlFor={`message-${emailId}`}>Message: </label>
            <textarea id={`message-${emailId}`} disabled className="resize-y overflow-y-scroll col-span-2 min-h-20 h-60 max-h-96 w-full">{message}</textarea>
        </div>
    )
}

export default Email