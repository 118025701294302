import React, { useEffect, useState } from 'react';

import { fetchProductData } from '../utils';
import { Product, BasketItem } from '../types';
import { handleProductSelect, removeItemFromBasket, changeProductQuantity } from './basketUtils';
import SearchBar from './searchBar';
import Pagination from './pagination';
import ModalWithChildren from './modalWithChildren';

import { searchProducts } from '../api';

type ProductModalProps = {
  basket: BasketItem[];
  setBasket: (newBasket: BasketItem[]) => void;
  setActiveBasketItem: (productCode: string) => void;
  activeBasketItem: string | null;
  modalIsOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRelatedProducts?: React.Dispatch<React.SetStateAction<Product[]>>;
  relatedProducts?: Product[];
  screen: "purchase" | "sale" | "related";
  supplier?: string;
};

const ProductsSearchModal = ({ basket, setBasket, setActiveBasketItem, activeBasketItem, modalIsOpen, setModalOpen, screen, setRelatedProducts, relatedProducts, supplier }: ProductModalProps) => {
  const [fetching, setFetching] = useState(false);
  const [productData, setProductData] = useState([] as any[]);
  const [childQuery, setChildQuery] = useState("" as string | null);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const [newSpecialItem, setNewSpecialItem] = useState("" as string | null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetching(true);
        const { products } = await fetchProductData();

        if (screen === "sale") {
          products.forEach(product => {
            if (!product["LIST.PRICE"]) {
              console.log("No list price for product", product);
            }
            Math.random() > 0.6
              ? product["Terms Discount"] = (Math.random() * 0.35 + 0.05)
              : product["Terms Discount"] = 0

            product["postTermsPrice"] = product["Terms Discount"] 
              ? parseFloat((product["LIST.PRICE"] - (product["LIST.PRICE"] * product["Terms Discount"])).toFixed(2))
              : product["LIST.PRICE"];
          })
        }

        // Sort products by stock, with 0 stock at the end
        products.sort((a, b) => {
          if (a["Total Stock"] === 0) return 1;
          if (b["Total Stock"] === 0) return -1;
          return (b["Total Stock"] as number) - (a["Total Stock"] as number);
        });

        console.log(supplier)

        let initialProducts = supplier 
          ? products.filter((product: Product) => product["SUPPLIER"].toLocaleLowerCase() === supplier.toLocaleLowerCase())
          : products;

        console.log(initialProducts)

        setProductData(initialProducts);
        setFilteredAndSearchedProducts(initialProducts);
        setFetching(false);
      } catch (error) {
        console.error('There was a problem fetching customer data:', error);
      }
    };

    fetchData();
  }, [screen, supplier]);

  useEffect(() => {
    const searchBar = document.getElementById("modal-search-bar");
    searchBar?.focus();
  }, [modalIsOpen]);

  useEffect(() => {
    if (filteredAndSearchedProducts.length === 1 && screen !== "related") {
      setActiveBasketItem(filteredAndSearchedProducts[0]["PRODUCT.CODE"]);
      handleProductSelect(filteredAndSearchedProducts[0]["PRODUCT.CODE"], productData, basket, setBasket, setActiveBasketItem, screen)
      setTimeout(() => {
        const input = document.getElementById(`quantity-${filteredAndSearchedProducts[0]["PRODUCT.CODE"]}`);
        input?.focus();
      }, 100);
    }
  }, [filteredAndSearchedProducts, screen]);

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e.key);
    if (e.key === 'Enter') {
      console.log("Enter pressed");
      console.log(childQuery)
      setChildQuery(childQuery === null ? "" : null);
      const searchBar = document.getElementById("modal-search-bar");
      searchBar?.focus();
    }
  };

  return (
    <ModalWithChildren modalOpen={modalIsOpen} setModalOpen={setModalOpen}>
      <div className="flex flex-col flex-grow p-4">
        {fetching && 
          <div className="flex flex-col gap-4 items-center justify-center h-[300px]">
              <i className="fa-solid fa-spinner-third animate-spin text-4xl text-[#3A5BFF]"></i>
              <p className="text-center text-lg font-semibold">Fetching product data...</p>
            </div>
        }

        {!fetching && <>
          {productData.length > 0 
            ? newSpecialItem 
              ? <div className="flex gap-4 end w-full">
                  <div className="flex-grow">
                    <h3 className="text-lg font-semibold">New Special Item</h3>
                    <input 
                      type="text" 
                      className="input w-full border border-gray-300 p-2" 
                      placeholder="Enter product name" 
                      value={newSpecialItem} 
                      onChange={(e) => setNewSpecialItem(e.target.value)}
                    />
                  </div>
                  <button 
                    onClick={() => {
                      handleProductSelect(newSpecialItem, productData, basket, setBasket, setActiveBasketItem, screen)
                      setProductData([...productData, { "PRODUCT.CODE": newSpecialItem, "DESCRIPTION": newSpecialItem, "COST.PRICE": 0, "LIST.PRICE": 0, "Total Stock": 0 }]);
                      setNewSpecialItem(null);
                    }} 
                    className={`btn-${screen === "sale" ? "green" : "primary"} btn`}
                  >
                    Add to basket
                  </button>
                </div>
              : <>
                  <div className="h-[50px]">
                    <SearchBar initialData={productData} childQuery={childQuery} setResults={setFilteredAndSearchedProducts} searchFunction={searchProducts} />
                  </div>

                  <div className="flex flex-col">
                    <div style={{maxHeight: "675px"}}>
                      {filteredAndSearchedProducts.length > 0 
                        ? <table className="table-auto w-full mt-4">
                            <thead>
                              <tr className={`table-${screen === "purchase" ? "blue" : "green"}`}>
                                <th className="text-left-override">Product</th>
                                <th>Supplier</th>
                                <th>Category</th>
                                <th>Cost Price</th>
                                <th>List Price</th>
                                {screen === "sale" && <>
                                  <th>Terms</th>
                                  <th>Customer Price</th>
                                </>}
                                <th>Stock</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredAndSearchedProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item: Product, rowIndex: number) => {
                                let itemInCurrentBasket = basket.find((basketItem: BasketItem) => basketItem.product["PRODUCT.CODE"] === item["PRODUCT.CODE"])
                                let itemInRelatedProducts = relatedProducts?.find((product: Product) => product["PRODUCT.CODE"] === item["PRODUCT.CODE"]);

                                return <>
                                <tr key={rowIndex}>
                                  <td>
                                    <div className="flex gap-4 items-center pr-2 text-left-override py-2">
                                      <img src={item["PICT.FLAG"]} alt={item.DESCRIPTION} className="w-14 h-14 rounded-md" />
                                      <div>
                                        <p className="text-xs">{item["PRODUCT.CODE"]}</p>
                                        <p className="font-semibold text-sm">{item.DESCRIPTION.length > 45 ? item.DESCRIPTION.slice(0, 45) + "..." : item.DESCRIPTION}</p>
                                      </div>
                                    </div>
                                  </td>
                                  <td><p className="text-sm px-1">{item["SUPPLIER"]}</p></td>
                                  <td><p className="text-sm px-1">{item["PGROUP.DESC"]}</p></td>
                                  <td><p className="text-sm px-1">£{item["COST.PRICE"]}</p></td>
                                  <td><p className="text-sm px-1">£{item["LIST.PRICE"] || 0}</p></td>

                                  {screen === "sale" && <>
                                    <td><p className="text-sm px-1">{item["Terms Discount"] ? (item["Terms Discount"] * 100).toFixed(2) + "%" : "None"}</p></td>
                                    <td><p className="text-sm px-1">£{item["postTermsPrice"]}</p></td>
                                  </>}
                                  <td><p className="text-sm px-1">{item["Total Stock"]?.toLocaleString()}</p></td>
                                  {(screen === "sale" || screen === "purchase") 
                                    ? <td>
                                        {itemInCurrentBasket 
                                          ? <div className="flex items-center justify-center w-full">
                                              <input
                                                type="number"
                                                className="input w-14 border-y border-l border-gray-300 h-8 text-center outline-none"
                                                id={`quantity-${item["PRODUCT.CODE"]}`}
                                                value={itemInCurrentBasket.quantity || undefined}
                                                onChange={(e) => changeProductQuantity(parseFloat(e.target.value), item["PRODUCT.CODE"], basket, setBasket)}
                                                onKeyDown={(e) => handleKeyDown(e)}
                                              />
                                          
                                              <button
                                                onClick={() => removeItemFromBasket(item["PRODUCT.CODE"], basket, setBasket, setActiveBasketItem,   activeBasketItem)}
                                                className="bg-red-400 text-white px-2 flex justify-center h-8 items-center"
                                              >
                                                <i className="fa-solid fa-trash-xmark text-xs"></i>
                                              </button>
                                            </div>
                                          : <button 
                                              onClick={() => {
                                                handleProductSelect(item["PRODUCT.CODE"], productData, basket, setBasket, setActiveBasketItem, screen)
                                                setTimeout(() => {
                                                  const input = document.getElementById(`quantity-${item["PRODUCT.CODE"]}`);
                                                  input?.focus();
                                                }, 100);
                                              }}
                                              className={`btn-${screen === "sale" ? "green" : "primary"} btn  w-[82.5px] flex justify-center`}
                                            >
                                              <i className="fa-solid fa-basket-shopping-plus"></i>
                                            </button>
                                        }
                                      </td>
                                    : <td>
                                        <button 
                                          onClick={() => {
                                            if (setRelatedProducts && relatedProducts) {
                                              if (itemInRelatedProducts) {
                                                setRelatedProducts(relatedProducts.filter(product => product["PRODUCT.CODE"] !== item["PRODUCT.CODE"]));
                                              }
                                              else {
                                                setRelatedProducts([...relatedProducts, item]);
                                              }
                                            }
                                          }} 
                                          className={`btn ${itemInRelatedProducts ? "bg-red-400" : "btn-primary"} w-14 flex justify-center`}
                                        >
                                          <i className={`fa-solid fa-${itemInRelatedProducts ? "trash-xmark" : "sitemap"} text-white`}></i>
                                        </button>
                                      </td>
                                  }
                                </tr>
                              </>})}
                            </tbody>
                          </table>
                        : <div className="flex flex-col gap-4 items-center justify-center h-[300px]">
                            <i className={`fa-solid fa-exclamation-circle text-4xl text-${screen === "purchase" ? "primary" : "green"}`}></i>
                            <p className="text-center text-lg font-semibold">No products found.</p>

                            <p 
                              className="text-center text-sm font-semibold hover:underline cursor-pointer"
                              onClick={() => {
                                const searchBar = document.getElementById(`modal-search-bar`) as HTMLInputElement;
                                let searchValue = searchBar.value || "";
                                setNewSpecialItem(searchValue);
                              }}
                            >
                              Would you like to create a special item?
                            </p>
                          </div>
                      }
                    </div>
                    
                    <div className="flex items-center justify-between mt-auto px-4 py-4">            
                      <Pagination 
                        numberOfEntries={filteredAndSearchedProducts.length} 
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage}
                        itemsPerPage={itemsPerPage}
                        source="daive"
                      />

                      <button 
                        onClick={() => setModalOpen(false)} 
                        className={`btn btn-${screen === "sale" ? "green" : "primary"}`}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </>
              : <div className="flex flex-col gap-4 items-center justify-center h-[300px]">
                  <i className="fa-solid fa-exclamation-circle text-4xl text-[#3A5BFF]"></i>
                  <p className="text-center text-lg font-semibold">
                    {supplier ? `This supplier has no products in the system.` : "Unable to load product data please try again."}
                  </p>
                </div>
          }</>
        }
      </div>
    </ModalWithChildren>
  );
}

export default ProductsSearchModal;