import React, { useEffect, useState } from 'react';

import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts';
import PrimaryTable from '../components/widgets/primaryTable';
import ProgressBar from '../components/progressBar';

import { termsItems } from '../dummyData';

const TermsPage = () => {
  const tabOptions = ["All", "Unsent", "In Progress", "Completed"];
  const [tab, setTab] = useState(tabOptions[0] as string);


  return (
    <div className="flex flex-col md:flex-row flex-grow gap-4 dashboard-height-handler">
      <div className="dashboard-card bg-base-100 flex flex-col md:max-w-[350px]">
        <div className="p-4 border-b border-[#F0F0F3] flex gap-2 items-center">
          <img src="/supplierImages/duracell.png" alt="maintenance" />

          <div>
            <h3 className="font-bold text-sm">Terms Set A</h3>
            <p className="text-xs text-muted">Terms ID: SETA1</p>
          </div>

          <div className="ml-auto bg-[#F5F5FA] h-10 w-10 rounded-md" />
        </div>

        <div className="flex flex-col gap-4 p-4">
          <div>
            <h3 className="font-bold">DESCRIPTION</h3>
            <p className="text-muted text-xs">Terms generated for smaller fitters that buy from us sporadically. These are designed as backstop terms and need to be pushed higher for one-off purchases.</p>
          </div>

          <div>
            <h3 className="font-bold">Edited</h3>
            <div className="mt-1 flex gap-1">
              {["solartech", "solarclipse", "renewa_ltd"].map((customer, index) => (
                <img key={index} src={`/customerImages/${customer}.png`} alt="supplier" className="h-8 w-8 rounded-md" />
              ))}
            </div>
          </div>

          <div>
            <h3 className="font-bold">Terms Count</h3>
            <div className="mt-1 pl-3 text-sm">
              <p>145 Items</p>
              <p>12 Linked Accounts</p>
              <p>15% Average GP</p>
            </div>
          </div>

          <div>
            <h3 className="font-bold">Categories</h3>
            <div className="mt-1 flex gap-1">
              {["Local Fitter", "Electronics"].map((customer, index) => (
                <div className="bg-[#F5F5FA] text-[#8181A5] font-bold p-2 rounded-md text-xs" key={index}>{customer}</div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="font-bold">Health Check</h3>
            <div className="bg-[#F5F5FA] p-4 rounded-md mt-2">
              <div className="flex justify-between gap-2 items-center mb-2 font-semibold">
                <p className="text-sm">Good</p>
                <p className="text-sm">4.2/5</p>
              </div>

              <ProgressBar value={4.2} maxVal={5} color={"#7CE7AC"} />
            </div>
          </div>

          <div>
            <div className="mt-auto bg-[#fbfafd] overflow-hidden rounded-xl">
              <div className="flex gap-4 justify-between p-4 mb-4">
                <div>
                  <h3 className="font-bold">Usage History</h3>
                  <p className="text-muted text-xs">Week to week utilisation</p>
                </div>

                <div className="ml-auto bg-[#e6e5f2] h-10 w-10 rounded-md" />
              </div>
              
              <div style={{ padding: "-15px" }}>
              <ResponsiveContainer width="100%" height={150}>
                <AreaChart data={[
                  { name: 'Week 1', uv: 15, bv: 17 },
                  { name: 'Week 2', uv: 17, bv: 30 },
                  { name: 'Week 3', uv: 30, bv: 25 },
                  { name: 'Week 4', uv: 27, bv: 45 },
                  { name: 'Week 5', uv: 45, bv: 35 },
                  { name: 'Week 6', uv: 40, bv: 55 },
                  { name: 'Week 7', uv: 45, bv: 40 },
                ]}>
                  <Area type="monotone" dataKey="bv" stroke="none" fill="#e6e5f2" fillOpacity={1} />
                  <Area type="monotone" dataKey="uv" stroke="none" fill="#5E81F4" fillOpacity={1} />
                  <Tooltip />
                </AreaChart>
              </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-grow flex-col gap-4 w-full">

        <div className="flex flex-grow gap-4">
          <div className="flex-grow overflow-hidden">
            <PrimaryTable source="Terms" itemData={termsItems} tabOptions={tabOptions} />
          </div>

          <div className="flex flex-col flex-grow gap-4 shrink-0">
            <div className="mt-auto dashboard-card bg-base-100 overflow-hidden">
              <div className="flex gap-2 p-4 mb-4 items-center">
                <h3 className="font-bold mr-auto">GP Predictor</h3>

                {[30, 60, 90].map((days, index) => (
                  <div key={index} className="border border-[#F5F5FA] text-center rounded-md p-2 shrink-0">
                    <p className="text-sm mb-0">{days} Day</p>
                  </div>
                ))}
              </div>
              
              <div style={{ padding: "-15px" }}>
                <ResponsiveContainer width="100%" height={150}>
                  <AreaChart data={[
                    { name: 'Week 1', uv: 15 },
                    { name: 'Week 2', uv: 17 },
                    { name: 'Week 3', uv: 30 },
                    { name: 'Week 4', uv: 27 },
                    { name: 'Week 5', uv: 45 },
                    { name: 'Week 6', uv: 40 },
                    { name: 'Week 7', uv: 45 },
                  ]}>
                    <Area type="monotone" dataKey="uv" stroke="#8AF1B9" fill="#8AF1B9" fillOpacity={0.1} />
                    <Tooltip />
                  </AreaChart>
                </ResponsiveContainer>
              </div>

              <div className="grid grid-cols-3 border-t">
                <div className="flex flex-col items-center justify-center py-4">
                  <h3 className="font-bold">£342,000</h3>
                  <p className="text-sm text-muted">Total Sales</p>
                </div>

                <div className="flex flex-col items-center justify-center py-4 border-x">
                  <h3 className="font-bold">£342,000</h3>
                  <p className="text-sm text-muted">Total Sales</p>
                </div>

                <div className="flex flex-col items-center justify-center py-4">
                  <h3 className="font-bold">£342,000</h3>
                  <p className="text-sm text-muted">Total Sales</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsPage;