import { SetStateAction, useEffect, useRef, useState } from "react"
import { AvailableThemes, Email, EmailAttachment, User } from "../../types"
import TimelineUserComment from "./userComment"
import { users } from "../../dummyData"
import ModalWithChildren from "../modalWithChildren"
import EmailComponent from "../email"
import { clamp, onSameDay } from "../../utils"
import HideableContent from "../hideableContent"
import CommentInput from "../commentInput"

type HistoryTimelineProp = {
    //The screen id of the page the timeline is on
    screenId: string,
    //ID of the current item being viewed, e.g supplier id. Combined with screenId, this determines what timeline exactly to pull
    dataId: string,
    theme?: AvailableThemes
}

type HistoryTimelineEntry = /*{
    isComment: boolean,
    message: string,
    messageTime: Date,
    comment?: {
        userId: string,
        attachments?: EmailAttachment[]
    },
    email?: Email,
    detailsList?: {
        details: string
    }[]
    //Email entries have an email link inside of them
    messageType: "" | "email" | "detailed"

} | */{
    message: string,
    messageTime: Date,
    comment: {
        userId: string,
        attachments?: EmailAttachment[]
    },
    messageType: "comment"
} | {
    isComment: false,
    message: string,
    messageTime: Date,
    messageType: ""
} | {
    isComment: false,
    message: string,
    messageTime: Date,
    //Email entries have an email link inside of them
    email: Email,
    messageType: "email"
} | {
    isComment: false,
    message: string,
    messageTime: Date,
    detailsList?: {
        details: string
    }[]
    messageType: "detailed"
}

const HistoryTimeline = ({screenId, dataId, theme = "green"}: HistoryTimelineProp) => {
    const [entries, setEntries] = useState<HistoryTimelineEntry[]>([]);
    const [currentEmailSelected, setCurrentEmailSelected] = useState<Email>();
    const userId = useRef('hdmAdmin24'); // useCurrentUser();
    const username = useRef('');
    const userEmail = useRef('');
    const [showingTimelineEntries, setShowingTimelineEntries] = useState(10);

    useEffect(() => {
        const currentUser: User | undefined = users.find((value) => value.userId === userId.current);

        if (currentUser) {
            username.current = currentUser.username;
            userEmail.current = currentUser.userEmailAddress ?? "";
        }
    }, [userId])

    useEffect(() => {
        //Pull data from D3
        //But for now, test data:

        setEntries([
            {
                isComment: false,
                message: "Removed Phone Number.",
                messageTime: new Date('2024-08-09 15:32:19.016'),
                messageType: ""
            },
            {
                message: "Need to get a new email for this customer.",
                messageTime: new Date('2024-09-09 11:31:16.016'),
                comment: {
                    userId: 'hdmAdmin24'
                },
                messageType: "comment"
            },
            {
                isComment: false,
                message: "Removed Email!",
                messageTime: new Date('2024-09-09 11:29:12.016'),
                messageType: ""
            },
            {
                isComment: false,
                message: "Email removed by request of company",
                messageTime:  new Date('2024-09-09 11:34:12.016'),
                messageType: "email",
                email: {
                    to: "test@fake.com",
                    cc: "fakefakefaaaaake@notreal.com",
                    bcc: "test@ed.com",
                    message: "Hi everyone at HDM,\n\nYou might have noticed that we requested that our email be removed from your contacts. This is because this email is no longer being used, and do not want to create any confusion.\n\nBest of luck,\nTest company",
                    headers: "test",
                    sent: new Date('2024-09-08 11:34:12.016'),
                    attachments: [
                        {
                            fileName: 'renewa_logo.png',
                            fileSizeInBytes: 146234,
                            contentId: '',
                            thumbnail: '../renewa_logo.png'
                        },
                        {
                            fileName: 'not_renewa_logo.png',
                            fileSizeInBytes: 1,
                            contentId: '',
                            thumbnail: '../renewa_logo.png'
                        },
                        {
                            fileName: 'renewa_logo.png',
                            fileSizeInBytes: 4204133141025,
                            contentId: '',
                            thumbnail: '../tayah.png'
                        }
                    ]
                }
            },
            {
                isComment: false,
                message: "Edited timeline entry!",
                messageTime: new Date('2024-07-04 01:04:12.016'),
                messageType: "detailed",
                detailsList: [
                    {
                        details: "Set Supplier Code to TST001!"
                    },
                    {
                        details: "Set Supplier Name to Test company!"
                    },
                    {
                        details: "Set Address to Fauxia!"
                    },
                    {
                        details: "Set Phone Number to 08000 999999!"
                    },
                    {
                        details: "Set Email to testcompany@fake.com!"
                    }
                ]
            },
            {
                isComment: false,
                message: "Created this supplier/customer!",
                messageTime: new Date('2024-07-04 00:34:56.423'),
                messageType: ""
            }
        ])
        
        //setState(newEntries)
    }, [screenId, dataId])

    const entryDot = (): React.ReactNode => <div className={`rounded-full bg-${theme} w-2 h-2 mr-2`}></div>

    function genEntry(value: HistoryTimelineEntry): React.ReactNode {
        if (value.messageType === "comment") {
            if (!value.comment) return <span>Malformed timeline entry, missing comment object.</span>
            const user: User | undefined = users.find((userObj) => value.comment!.userId === userObj.userId);
            if (!user) return <span>Malformed timeline entry comment, invalid user ID {value.comment!.userId}.</span>
            return <TimelineUserComment 
                        comment={value.message} 
                        commentTime={value.messageTime} 
                        commenterEmail={user.userEmailAddress} 
                        isFirstComment={true}
                        commenterPfp={user.profileImage}
                        commenterUsername={user.username}
                        attachedFiles={value.comment.attachments}
                        onDelete={() => {
                            entries.splice(entries.indexOf(value), 1);
                            setEntries([...entries]);
                        }}
                        theme={theme}
                        timeOnly
                    />
        } else {
            return (
                <div className={`flex flex-row gap-2 p-2 w-full hover:bg-gray-50${!['email', 'detailed'].includes(value.messageType) ? ' place-items-center' : ''}`}>
                    <div className="flex flex-col w-full">
                        <span>{value.message}</span>
                        {getContents(value)}
                    </div>
                    <span className="text-sm text-gray-400" title={value.messageTime.toLocaleString()}>
                        {value.messageTime.toLocaleTimeString()}
                    </span>
                </div>
            )
        }
    }

    function getContents(value: HistoryTimelineEntry): React.ReactNode {
        switch (value.messageType) {
            case "email":
                //Add link button to email, open in modal
                return <button className={`mt-1 btn btn-primary${theme === 'green' ? ' bg-green' : ''} w-40`} onClick={() => setCurrentEmailSelected(value.email)}>Open Email</button>
            case "detailed":
                //Add button to expand and show additional messages
                if (value.detailsList) return (
                    <HideableContent 
                            contentHiddenText={"Details"} 
                            contentShowingText={"Details"} 
                            faIconContentHiding="fa-caret-right" 
                            faIconContentShowing="fa-caret-down" 
                            toggleClassName="font-bold text-sm hover:cursor-pointer"
                    >
                        <div className="flex flex-col gap-4 ml-4">
                            {value.detailsList.map((detail, index) => 
                                <span className="text-sm font-medium text-gray-500" key={index}>{detail.details}</span>
                            )}
                        </div>
                    </HideableContent>
                )
                break;
            default:
                return <></>
        }
    }

    const dateDivider = (messageTime: Date, isFirst?: boolean) =>
        <div className={`flex flex-row place-items-center w-full`} key={messageTime.toLocaleString()}>
            <div className="w-[50%] border-b-2 border-solid border-gray-200"></div>
            <span className="text-sm text-gray-500 mx-1">{messageTime.toLocaleDateString()}</span>
            <div className="w-[50%] border-b-2 border-solid border-gray-200"></div>
        </div>

    function postComment(text: string, attachments?: EmailAttachment[]) {
        if (text) {
            setEntries([...entries, {
                message: text,
                messageTime: new Date(),
                comment: {
                    userId: userId.current,
                    attachments: attachments
                },
                messageType: "comment"
            }])
        }
    }

    const sortedEntries = entries.sort((entry1, entry2) => {
        if (entry1.messageTime < entry2.messageTime) return -1
        else if (entry1.messageTime > entry2.messageTime) return 1
        else return 0
    }).reverse();

    return (
        <section className="flex flex-col">
            <div className="w-full pb-2">
                {/* User comment input */}
                <CommentInput postComment={postComment} theme={theme} currentUsername={username.current} currentEmail={userEmail.current}/>
            </div>
            <div className="flex flex-col gap-2 border-2 border-solid pt-2 rounded h-96 overflow-y-auto">
                {/* History and comments sorted by date descending */}
                {sortedEntries.slice(0, clamp(0, sortedEntries.length - 1, showingTimelineEntries - 1)).map((value, index) =>
                <>
                    {((index > 0 && !onSameDay(value.messageTime, sortedEntries[index - 1].messageTime)) || index === 0) &&
                        //Generate dividing line
                        dateDivider(value.messageTime, index === 0)
                    }

                    <div className="flex flex-row w-[95%] place-items-center ml-1" key={index}>{entryDot()}{genEntry(value)}</div>
                </>
                )}
                {sortedEntries.length > showingTimelineEntries && <span className="text-gray-400 w-full text-center hover:cursor-pointer hover:underline hover:text-gray-500" onClick={() => setShowingTimelineEntries(showingTimelineEntries + 15)}>Show more...</span>}
                <ModalWithChildren modalOpen={!!currentEmailSelected} setModalOpen={() => setCurrentEmailSelected(undefined)}>
                    {currentEmailSelected && <EmailComponent 
                        to={currentEmailSelected!.to} 
                        message={currentEmailSelected!.message} 
                        cc={currentEmailSelected!.cc} 
                        bcc={currentEmailSelected!.bcc} 
                        headers={currentEmailSelected!.headers} 
                        sent={currentEmailSelected!.sent} 
                        attachments={currentEmailSelected!.attachments} 
                        theme={theme}
                        onClose={() => setCurrentEmailSelected(undefined)}
                    />}
                </ModalWithChildren>
            </div>
        </section>
    )
}

export default HistoryTimeline;