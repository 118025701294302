import React from 'react';

const EcommerceFooter = () => {
  return (
    <footer className="bg-black py-4 md:py-12 text-gray-400">
      <div className="container mx-auto">
        <div className="flex gap-4 md:gap-24">
          <div className="flex flex-col gap-4 max-w-[300px]">
            <img src="/ecommerce/footer-logo.png" alt="HDM Solar UK" className="w-[139px]" />

            <p>An independent, family-run retailer of renewable energy supplies.</p>

            <img src="/ecommerce/payment-icons.png" alt="Payment Icons" className="w-[250px]" />

            <div className="flex border border-gray-400 rounded-md py-2 pl-2 items-center gap-2">
              <i className="fa-solid fa-search"></i>
              <input type="text" placeholder="Search" className="bg-transparent focus:outline-none" />
            </div>
          </div>

          <div className="ml-auto flex flex-col gap-2">
            <p className="text-bold text-white">Company</p>
            <p>About Us</p>
            <p>Careers</p>
            <p>Delivery</p>
            <p>News</p>
            <p>Contact</p>
          </div>

          <div className="flex flex-col gap-2">
            <p className="text-bold text-white">Legal</p>
            <p>Terms</p>
            <p>Privacy</p>
            <p>Cookies</p>
            <p>Refunds</p>
            <p>Anti-Slavery</p>
          </div>
        </div>

        <div className="flex gap-4 mt-8 md:mt-20 border-t-2 border-gray-500 pt-4 text-2xl">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} HDM Solar UK. HDM Solar (Wholesale) Ltd is Registered in England
            <br />
            Company Registration No: 14801658 VAT No: 439483166. All rights reserved.
          </p>

          <i className="ml-auto fa-brands fa-x-twitter"></i>
          <i className="fa-brands fa-linkedin"></i>
          <i className="fa-brands fa-facebook"></i>
          <i className="fa-brands fa-instagram"></i>
          <i className="fa-brands fa-youtube"></i>
        </div>
      </div>
    </footer>
  );
}

export default EcommerceFooter;