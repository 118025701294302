import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';

type ScreenContextType = {
  screenId: string;
  navCollapsed: boolean;
  setScreenId: (id: string) => void;
  toggleNavCollapsed: () => void;
  pageHasDirtyForm: boolean;
  setPageHasDirtyForm: (dirty: boolean) => void;
  liveChatOpen: boolean;
  setLiveChatOpen: (open: boolean) => void;
  notifications: {id: number, message: string, type: "success" | "error"}[];
  addNotification: (message: string, type: "success" | "error") => void;
  executeCommandVersion: string;
  setExecuteCommandVersion: (version: string) => void;
}

const ScreenContext = createContext<ScreenContextType>({
  screenId: '',
  navCollapsed: false,
  setScreenId: () => {},
  toggleNavCollapsed: () => {},
  pageHasDirtyForm: false,
  setPageHasDirtyForm: () => {},
  liveChatOpen: false,
  setLiveChatOpen: () => {},
  notifications: [],
  addNotification: () => {},
  executeCommandVersion: 'ExecuteCommand',
  setExecuteCommandVersion: () => {},
});


export const ScreenProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [screenId, setScreenId] = useState<string>('');
  const [navCollapsed, setNavCollapsed] = useState<boolean>(false);
  const [pageHasDirtyForm, setPageHasDirtyForm] = useState<boolean>(false);
  const [liveChatOpen, setLiveChatOpen] = useState<boolean>(false);

  const [nextId, setNextId] = useState(1);
  const [notifications, setNotifications] = useState<{id: number, message: string, type: "success" | "error"}[]>([]);
  const [executeCommandVersion, setExecuteCommandVersion] = useState<string>(() => {
    return localStorage.getItem('executeCommandVersion') || 'ExecuteCommand';
  });

  useEffect(() => {
    localStorage.setItem('executeCommandVersion', executeCommandVersion);
  }, [executeCommandVersion]);

  const addNotification = (message: string, type: "success" | "error") => {
    const id = nextId;
    setNotifications((prev) => [...prev, { id, message, type }]);
    setTimeout(() => removeNotification(id), 5000);
  };

  const removeNotification = (id: number) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  const toggleNavCollapsed = () => {
    setNavCollapsed(!navCollapsed);
  }

  const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
    console.log("Page has dirty form: ", pageHasDirtyForm);
    if (pageHasDirtyForm) {
      event.preventDefault();
      event.returnValue = '';
    }
  }, [pageHasDirtyForm]);

  // Effect to set up the beforeunload event listener for browser tab or window closing
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  return (
    <ScreenContext.Provider value={{ screenId, setScreenId, navCollapsed, toggleNavCollapsed, pageHasDirtyForm, setPageHasDirtyForm, liveChatOpen, setLiveChatOpen, notifications, addNotification, executeCommandVersion, setExecuteCommandVersion }}>
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreen = () => useContext(ScreenContext);