import { formatNumber } from "../../utils";

type MetricChangeProps = {
  metric: string;
  metricChange: number;
  giveTextColor?: boolean;
}

const MetricChangeHandler = ({ metric, metricChange, giveTextColor }: MetricChangeProps) => {
  let leadingString = "";

  if (metric === "Sales" || metric === "Profit") leadingString = "£"

  return (
    <span className={`${metricChange < 0 ? "text-red-600" : "text-green"} font-bold`}>
      {leadingString}
      {formatNumber(Math.abs(metricChange))}
    </span>
  )

}

export default MetricChangeHandler;