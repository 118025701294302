import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useEcommerceContext } from './context';

import Pagination from '../../components/pagination';

import { fetchProductData, sortAlphabeticallyWithNonAlphaAtEnd } from "../../utils";
import { Product } from '../../types';  

import PrimaryButton from './components/primaryButton';
import ProductFeature from './components/productFeature';

const SalesHomePage = () => {
  const { basket, setBasket } = useEcommerceContext();
  const [activeBasketItem, setActiveBasketItem] = useState("");
  useEffect(() => {
    console.log(basket, activeBasketItem)
  }, [basket, activeBasketItem]);

  const [fetching, setFetching] = useState(true);
  const [productData, setProductData] = useState<Product[]>([]);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [productCategory, setProductCategory] = useState("Solar Panels");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchProductData();
        setProductData(products);
        setFilteredAndSearchedProducts(products);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredAndSearchedProducts(productData
      .filter((product: Product) => product['PGROUP.DESC'] === productCategory && product["PICT.FLAG"] !== "0")
    );
  }, [productCategory, productData]);

  return (
    <div className="flex flex-col flex-grow bg-white">
      <img src="/ecommerce/hero.png" alt="HDM Solar" className="w-full h-[450px] object-cover" />

      <div className="container mx-auto flex flex-col gap-4 md:gap-12 items-center mt-8">
        <h1 className="text-lg md:text-xl font-semibold text-center md:max-w-[950px]">
          High-Quality Renewable Energy Systems from Leading Global Brands.<br />Discover Solar Panels, Batteries, Inverters, and Accessories for Home and Business.
        </h1>

        <div className="flex items-center justify-center gap-2 rounded-md p-1 bg-[#F9F9F9] border border-[#E9EAEB]">
          {["Solar Panels", "Power Inverters", "Mount Brackets", ].map((category: string, index: number) => (
            <div 
              key={index} 
              className={`
                cursor-pointer py-2 px-4 font-bold
                ${productCategory === category ? "text-white bg-ecommerce-green rounded-md" : "text-[#717680] border-transparent"}
              `}
              onClick={() => setProductCategory(category)}
            >
              {category}
            </div>  
          ))}
        </div>
      </div>

      <div className="container mx-auto py-12">
        {fetching 
          ? <div className="flex justify-center items-center h-full">
              <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl"></i>
            </div>
          : <>
              <div className="flex overflow-x-scroll invisible-scrollbar mb-6">
                {sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, "DESCRIPTION")
                  .sort((a: Product, b: Product) => {
                    if (a["Total Stock"] > 0 && b["Total Stock"] === 0) return -1;
                    if (a["Total Stock"] === 0 && b["Total Stock"] > 0) return 1;
                    return 0;
                  })
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map((product: Product, rowIndex: number) => {
                    return (
                    <div className="flex flex-col gap-6 min-w-[25%] pr-8">
                      <ProductFeature product={product} key={rowIndex} />
                    </div>
                  )}
                )}
              </div>

              <div className="flex items-center justify-between gap-4 pr-8">
                <div />
                <Pagination 
                  numberOfEntries={filteredAndSearchedProducts.length}
                  currentPage={currentPage} 
                  setCurrentPage={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                  source="ecommerce"
                />
              </div>
            </>

        }
      </div>

      <div className="bg-gray-100 py-12">
        <div className="container mx-auto flex flex-col items-center justify-center gap-8">
          <h2 className="font-bold text-2xl md:text-3xl mt-6">Shop by Brand</h2>

          <div className="grid md:grid-cols-3 lg:grid-cols-6 gap-x-2 gap-y-6">
              {[
              { image: "aiko", link: "AIKO" },
              { image: "canadianSolar", link: "Canadian Solar" },
              { image: "duracell", link: "Duracell Energy" },
              { image: "ecoflow", link: "Ecobat" },
              { image: "enphase", link: "Enphase" },
              { image: "foxess", link: "Fox ESS" },
              { image: "givEnergy", link: "GivEnergy" },
              { image: "hyundai", link: "Hyundai" },
              { image: "JAsolar", link: "JA Solar" },
              { image: "jinko", link: "Jinko" },
              { image: "mountingSystems", link: "Mounting Systems" },
              { image: "myenergi", link: "myenergi" },
              { image: "pylonTech", link: "Puredrive" },
              { image: "solarEdge", link: "SolarEdge" },
              { image: "solax", link: "SolaX" },
              { image: "solis", link: "Solis" },
              { image: "sunSynk", link: "Sunsynk" },
              { image: "tigo", link: "Tigo" }
              ].map((brand, index) => (
              <Link to={`/ecommerce/products/?brand=${brand.link}`} key={index}>
                <div>
                  <img src={`/supplierImages/${brand.image}.svg`} alt={brand.link} className="w-full cursor-p" />
                </div>

                <p className="text-center text-gray-600 mt-2">{brand.link}</p>
              </Link>
                
              ))}  
          </div>  
        </div>
      </div>

      <div className="container mx-auto my-12">
        <h2 className="font-bold text-2xl md:text-3xl mt-6 text-center">Shop by Category</h2>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-12 gap-y-12">
          {[
            { 
              title: "Solar Panels", 
              icon: "solar-panels", 
              category: "Solar Panels",
              description: "High-quality solar panels for efficient, sustainable energy." 
            },
            { 
              title: "Power Inverters", 
              icon: "inverters", 
              category: "Power Inverters",
              description: "Inverters for converting solar energy into usable electricity."
            },
            { 
              title: "Battery Storage", 
              icon: "battery-storage", 
              category: "Batteries",
              description: "Efficient energy storage solutions for uninterrupted power supply."
            },
            { 
              title: "EV Chargers", 
              icon: "ev-charger", 
              category: "Vehicle Battery Chargers",
              description: "High-performance EV chargers for fast, convenient charging."
            },
            { 
              title: "Portable Solar Products", 
              icon: "portable-solar", 
              category: "Portable Solar Products",
              description: "Compact, portable solar solutions for energy on the go."
            },
            { 
              title: "Cables & Accessories", 
              icon: "cables-accessories", 
              category: "Cables & Accessories",
              description: "Essential solar cables and accessories for installations."
            },
            { 
              title: "Solar Panel Mountings", 
              icon: "panel-mountings", 
              category: "Solar Panel Mountings",
              description: "Durable mounting systems for secure solar panel installations."
            },
            { 
              title: "Special Offers", 
              icon: "special-offers", 
              category: "Special Offers",
              description: "Exclusive deals on premium solar products and equipment."
            },
          ].map((category, index) => (
            <Link 
              to={`/ecommerce/products/?category=${category.category}`} 
              key={index}
              className="w-full aspect-square bg-cover bg-center relative cursor-pointer rounded-xl overflow-hidden shadow-md transition-transform transform hover:scale-105"
              style={{ backgroundImage: `url(/categoryImages/${category.icon}.png)` }}
            >
              <div className="bg-gradient-to-t from-black via-transparent to-transparent p-4 md:p-6 w-full h-full flex flex-col justify-end items-start">
                <div className="border-[0.5px] border-white p-4 rounded-xl glassy-effect">
                  <div className="flex items-center justify-between gap-4 w-full">
                    <h3 className="text-white text-lg md:text-xl font-semibold">{category.title}</h3>
                    <i className="fa-solid fa-arrow-up-right text-white text-xl"></i>
                  </div>
                  <p className="text-white text-sm md:text-base mt-6">{category.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto flex flex-col items-center justify-center gap-8">
          <h2 className="font-bold text-2xl md:text-3xl mt-6">Join Our Solar Community</h2>

          <div className="grid md:grid-cols-2 gap-4 mt-8">
            <div className="bg-white border border-gray-200 bg-white rounded-2xl grid grid-cols-2">
              <div className="flex flex-col gap-4 px-6 py-8">
                <h3 className="font-bold text-2xl">Become an Installer</h3>
                <p className="text-gray-600 mb-auto">Are you a qualified, MSC registered solar panel installer? Join our growing network of partners and benefit from a consistent flow of ready made jobs.</p>

                <PrimaryButton buttonText="Enquire" />
              </div>

              <img src="/ecommerce/become-installer.png" alt="Installer" className="w-full h-full" />
            </div>

            <div className="bg-white border border-gray-200 bg-white rounded-2xl grid grid-cols-2">
              <div className="flex flex-col gap-4 px-6 py-8">
                <h3 className="font-bold text-2xl">Become an Trade Customer</h3>
                <p className="text-gray-600 mb-auto">Join one of the UK's fastest growing solar suppliers by registering for a Trade Hub account and take advantage of a range of benefits.</p>

                <PrimaryButton buttonText="Enquire" />
              </div>

              <img src="/ecommerce/become-trade-customer.png" alt="Installer" className="w-full h-full" />
            </div>
          </div>
        </div>
      </div>

      <div 
        className="bg-cover bg-center h-[300px]"
        style={{ backgroundImage: `url(/ecommerce/installer-network.jpeg)` }}
      >
        <div className="bg-black bg-opacity-50 h-full flex items-center">
          <div className="container mx-auto flex justify-between gap-4">
            <h2 className="font-bold text-2xl md:text-5xl text-white">Our Installer Network</h2>

            <div className="flex flex-col gap-1 items-end">
              <p className="text-white">Find an independent installer in your area.</p>
              <button className="bg-white font-semibold p-2 rounded-md mt-2">Search our network</button>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-12">
        <div className="flex justify-between items-start gap-4">
          <div>
            <h2 className="font-semibold text-2xl md:text-3xl">Chat to our friendly team</h2>
            <p className="text-gray-600 text-xl mt-4">We’d love to hear from you. Please fill out this form or drop us an email.</p>
          </div>
          
          <div className="flex flex-col gap-2 items-end">
            <p className="font-semibold text-right text-xl">4.9+ on Trustpilot</p>
            <div className="flex gap-1">
              {Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className="h-8 w-8 bg-[#00b67a] flex items-center text-white justify-center">
                  <i className="fa-sharp fa-solid fa-star"></i>
                </div>
              ))}
            </div>

            <a href="https://uk.trustpilot.com/review/hdmsolar.co.uk" className="py-2 px-4 border border-gray-300 rounded-lg" target="_blank">
              Review us&nbsp;&nbsp;<i className="fa-solid fa-external-link"></i>
            </a>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-4 mt-8">
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <i className="fa-regular fa-envelope text-2xl text-ecommerce-green"></i>

              <h3 className="font-bold text-xl mt-4">Email</h3>
              <p className="my-2">Our friendly team is here to help.</p>

              <a href="mailto:support@hdmsolar.co.uk" className="text-lg font-bold">
                support@hdmsolar.co.uk
              </a>
            </div>

            <div>
              <i className="fa-regular fa-comment text-2xl text-ecommerce-green"></i>

              <h3 className="font-bold text-xl mt-4">Live Chat</h3>
              <p className="my-2">Chat to us in real-time online.</p>

              <a href="#" className="text-lg font-bold">
                Start Chat
              </a>
            </div>

            <div>
              <i className="fa-regular fa-map-marker text-2xl text-ecommerce-green"></i>

              <h3 className="font-bold text-xl mt-4">Address</h3>
              <p className="my-2">Come say hello at our office HQ.</p>

              <a href="#" className="text-lg font-bold">
                40 Kingfisher Way, <br />Dinnington, Sheffield<br /> S25 3AF, UK.
              </a>
            </div>

            <div>
              <i className="fa-regular fa-phone text-2xl text-ecommerce-green"></i>

              <h3 className="font-bold text-xl mt-4">Phone</h3>
              <p className="my-2">Available Mon-Fri from 9am to 5pm.</p>

              <a href="tel:08000016802" className="text-lg font-bold">
                0800 0016 802
              </a>
            </div>
          </div>

          <div className="bg-gray-100 bg-opacity-50 p-4 md:p-8 rounded-2xl border border-[#E9EAEB]">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">First Name&nbsp;*</label>
                <input 
                  type="text" 
                  id="name" 
                  name="name" 
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md outline-none"
                  placeholder="First name..."
                />
              </div>

              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name&nbsp;*</label>
                <input 
                  type="text" 
                  id="lastName" 
                  name="lastName" 
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md outline-none"
                  placeholder="Last name..."
                />
              </div>
            </div>

            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-6">Email&nbsp;*</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md outline-none"
              placeholder="you@company.com"
            />

            <label htmlFor="message" className="block text-sm font-medium text-gray-700 mt-6">Message&nbsp;*</label>
            <textarea 
              id="message" 
              name="message" 
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md outline-none" 
              placeholder='Leave us a message...'
              rows={4}
            />

            <div className="flex justify-between items-center mt-4">
              <div>
                <input type="checkbox" id="newsletter" name="newsletter" />
                <label htmlFor="newsletter" className="text-sm ml-2">You agree to our friendly <a className="underline" href="#">privacy policy</a> .</label>
              </div>


              <PrimaryButton buttonText="Submit" />
            </div>

          </div>
        </div>
      </div>

      <div 
        className="flex items-center justify-center gap-4 py-12 bg-cover bg-center h-[450px] relative"
        style={{ backgroundImage: `url(/ecommerce/hero.png)` }}
      >
        <div className="bg-white bg-opacity-95 px-8 py-12 rounded-xl flex flex-col items-center gap-4 text-center container">
          <h2 className="font-bold text-2xl md:text-3xl">Sign up for our newsletter</h2>
          <p className="text-gray-600 text-xl">Hear about the latest renewables products and offers.</p>

          <div className="flex gap-4 items-start w-full max-w-[450px]">
            <div className="flex-grow block text-left">
              <input type="email" placeholder="Email Address" className="border border-gray-300 p-2 rounded-md w-full" />
              <p className="text-gray-600 text-xs mt-1">We care about your data. View our <a className="underline" href="#">privacy policy</a>.</p>
            </div>

            <PrimaryButton buttonText="Subscribe" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesHomePage;