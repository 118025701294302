import React, { useState, useEffect } from 'react';
import { Broadcast } from '../../types';

import { listBroadcasts } from '../../api';

import { getDateFromD3DateAndTime } from '../../utils';

const BroadcastsCard = () => {
  const [broadcasts, setBroadcasts] = useState([] as Broadcast[]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await listBroadcasts() as Broadcast[];
        console.log(data);
        let filteredBroadcasts = data.filter(broadcast => 
            broadcast["MESSAGE.CONTENT"] &&
            broadcast["EXPIRY.DATE"] &&
            broadcast["EXPIRY.TIME"] &&
            getDateFromD3DateAndTime(broadcast["EXPIRY.DATE"], broadcast["EXPIRY.TIME"]) > new Date()
        );

        //console.log(filteredBroadcasts)
        setBroadcasts(filteredBroadcasts);
      } catch (error) {
        console.error('There was a problem fetching broadcasts:', error);
      }
      setFetching(false);
    };

    fetchData();
  }, []);


  return (
    <div className="dashboard-card">
      <div className="card-header bg-green text-white">
        <h3 className="font-bold">Latest Announcements</h3>

        <i className="ml-auto cursor-pointer fa-regular fa-ellipsis-vertical" ></i>
      </div>

      <div className="px-4 pb-4 pt-2">
        {fetching
          ? <div className="flex justify-center items-center">
              <i className="fa-sharp fa-circle-notch fa-spin text-4xl"></i>
              <h3 className="font-semibold text-lg ml-2">Fetching data...</h3>
            </div>
          : broadcasts.length === 0
              ? <h3 className="text-lg text-center mt-24">No broadcasts at this time...</h3>
              : broadcasts.map((broadcast, index) => (
              <div key={index} className={`${index < broadcasts.length - 1 && "border-b border-gray-300"} py-2`}>
                <div className="flex items-center justify-between gap-2">
                  <h4 className="font-semibold text-lg">{broadcast.TITLE}</h4>

                  <span className="text-xs text-gray-400">
                    {broadcast["EXPIRY.DATE"]} {broadcast["EXPIRY.TIME"].slice(0, 5)}
                  </span>
                </div>
                <p className="text-sm">
                  {typeof broadcast["MESSAGE.CONTENT"] === "string"
                    ? broadcast["MESSAGE.CONTENT"]
                    : broadcast["MESSAGE.CONTENT"].map((line, index) => (
                      <span key={index}>{line}<br /></span>
                    ))
                  }
                </p>
              </div>
            ))
        }
      </div>
    </div>
  )

}

export default BroadcastsCard;