import { forwardRef, useEffect, useState } from "react";
import { TriState } from "../../types";

type ToggleSwitchProps = {
    initialState?: TriState,
    onToggled?: (isToggledOn: TriState) => void,
    toggledOnColour?: string,
    toggledOffColour?: string,
    toggledMiddleColour?: string,
    toggledOnTooltip?: string,
    toggledOffTooltip?: string,
    toggledMiddleTooltip?: string,
    isTriState?: boolean,
    disabled?: boolean
}

//NOTE: Due to the way Tailwind builds its CSS classes, you MUST use the CSS colour syntax for toggledOn/OffColour!
export const ToggleSwitch = forwardRef<HTMLButtonElement, ToggleSwitchProps>(({
                                                                                initialState = undefined, 
                                                                                onToggled, 
                                                                                toggledOnColour = '#0ea5e9' /*sky-500*/, 
                                                                                toggledOffColour = '#f87171' /*red-400*/,
                                                                                toggledMiddleColour = '#d1d5db'/*'gray-300'*/,
                                                                                toggledOnTooltip = "Enabled",
                                                                                toggledOffTooltip = "Disabled",
                                                                                toggledMiddleTooltip = "Unset",
                                                                                isTriState, 
                                                                                disabled = false} : ToggleSwitchProps, ref) => {
    const [isToggledOn, setIsToggledOn] = useState<TriState>(initialState);
    let clickTimeout: NodeJS.Timeout | null = null;

    function toggle() {
        if (clickTimeout) clearTimeout(clickTimeout);
        clickTimeout = setTimeout(() => {
            const newToggle = !isToggledOn;
            setIsToggledOn(newToggle);
            if (onToggled) onToggled(newToggle);
        }, 200);
    }

    function attemptSetToUndefined() {
        if (clickTimeout) clearTimeout(clickTimeout);
        if (isTriState) {
            setIsToggledOn(undefined);
        } else {
            toggle();
        }
    }

    function getForCurrentToggledState(toggledOnChoice: string, toggledOffChoice: string, toggledMiddleChoice: string) : string {
        return isToggledOn ? toggledOnChoice : (isToggledOn === undefined ? toggledMiddleChoice : toggledOffChoice)
    }

    function toggledValueForHiddenInput() {
        return isToggledOn === undefined ? '' : isToggledOn.toString();
    }

    useEffect(() => {
        return () => {
            if (clickTimeout) clearTimeout(clickTimeout);
        }
    }, [clickTimeout])

    return (
        <abbr title={getForCurrentToggledState(toggledOnTooltip + (isTriState ? " (double click to unset)" : ""), toggledOffTooltip + (isTriState ? " (double click to unset)" : ""), toggledMiddleTooltip)}>
        {/* <Tooltip content={getForCurrentToggledState(toggledOnTooltip + (isTriState ? " (double click to unset)" : ""), toggledOffTooltip + (isTriState ? " (double click to unset)" : ""), toggledMiddleTooltip)}> */}
            <button ref={ref} onClick={() => toggle()} onDoubleClick={attemptSetToUndefined} className="h-6 bg-red" disabled={disabled}>
                {/* Tailwind and dynamic colour classes do not play together nicely, so we have to manually style it. */}
                <div className={`rounded-xl h-6 w-14`} style={{backgroundColor: getForCurrentToggledState(toggledOnColour, toggledOffColour, toggledMiddleColour)}}>
                    <div style={{borderColor: getForCurrentToggledState(toggledOnColour, toggledOffColour, toggledMiddleColour)}} className={`h-8 w-8 rounded-lg bg-white border-2 -translate-y-1 transition-transform duration-300 ease-in-out ${isToggledOn ? 'translate-x-6' : (isToggledOn === undefined ? 'translate-x-3' : '')}`}>
                        <input type='hidden' value={toggledValueForHiddenInput()} data-testid="toggle-switch-value"/>
                    </div>
                </div>
            </button>
        {/* </Tooltip> */}
        </abbr>
    );
})