import React, { useState, useEffect, useRef } from 'react';
import { useScreen } from '../context';

import LightMetricCard from '../components/widgets/lightMetricCard';

import { businessAreas, maintenanceData } from '../dummyData';
import { RoleMaintenance } from './userMaintenance/roleMaintenance';
import PageTabs from '../components/pageTabs';
import { NonEmptyObject, Sort, User, UserOrderLimits, UserRole } from '../types';
import { getSortByKey, setSortOn, sort, ucFirst } from '../utils';
import { CollapsibleText } from '../components/widgets/collapsibleText';
import { ExportCSVButton } from '../components/widgets/exportCSVButton';
import { UserMaintenance } from './userMaintenance/userMaintenance';
import { UIToggles } from '../uitoggles';
import { Enable, Resizable } from 're-resizable';
import { SortColumnArrow } from '../components/widgets/sortColumnArrow';
import { listRoles, listUsers } from '../api';


const UsersAndRolesPage = () => {
  const tabOptions = ["Users", "Roles"];
  const [tab, setTab] = useState(tabOptions[0]);

  const [maintenanceCardOpen, setMaintenanceCardOpen] = useState(false);
  const searchTerm = useRef<string>("");
  const [clickedUserId, setClickedUserId] = useState('');
  const [clickedRoleId, setClickedRoleId] = useState('');
  const [userSorts, setUserSorts] = useState<Sort<User>[]>([])
  const [roleSorts, setRoleSorts] = useState<Sort<UserRole>[]>([])
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const [loading, setLoading] = useState(true);

  const dataPerTab: NonEmptyObject = {
    "Roles": roles,
    "Users": users
  }

  function clicked(value?: string) {
    if (tab === 'Users' && value) setClickedUserId(value);
    if (tab === 'Roles' && value) setClickedRoleId(value);
    setMaintenanceCardOpen(true)
  }

  function formatOrderLimits(orderLimits: UserOrderLimits) {
    const noLimits: string = "No limits applied";
    if (orderLimits) {
      const stringified = Object.entries(orderLimits).map(([orderLimitName, limit]) => 
        limit ? `${ucFirst(orderLimitName)}: ${limit}` : ''
      ).filter((value) => !!value).join(', ');
      return <CollapsibleText text={!!stringified ? stringified : noLimits} maxLength={35} expandedByDefault={false} />
    }
    return noLimits;
  }

  //...Because the Resizable library couldn't just do a falsey check for each...
  const enableObj: Enable = { right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }
  const roleHeaders = () => 
    <tr className='table-green'>
      <th className='hover:cursor-pointer text-left' onClick={() => setRoleSorts(setSortOn(roleSorts, "name"))}><Resizable minWidth={125} enable={enableObj}>Name<SortColumnArrow ascending={getSortByKey(roleSorts, "name")?.asc}/></Resizable></th>
      <th className='hover:cursor-pointer text-left' onClick={() => setRoleSorts(setSortOn(roleSorts, "businessArea"))}><Resizable minWidth={125} enable={enableObj}>Business Area<SortColumnArrow ascending={getSortByKey(roleSorts, "businessArea")?.asc}/></Resizable></th>
      <th className='hover:cursor-pointer text-left' onClick={() => setRoleSorts(setSortOn(roleSorts, "userRoleId"))}><Resizable minWidth={125} enable={enableObj}>Role Id<SortColumnArrow ascending={getSortByKey(roleSorts, "userRoleId")?.asc}/></Resizable></th>
      <th className='hover:cursor-pointer text-left' onClick={() => setRoleSorts(setSortOn(roleSorts, "description"))}><Resizable minWidth={125} enable={enableObj}>Description<SortColumnArrow ascending={getSortByKey(roleSorts, "description")?.asc}/></Resizable></th>
    </tr>
  
  const roleRow = (role: UserRole) => 
    <tr className='h-14 hover:bg-gray-100' key={role.userRoleId}>
      <td onClick={() => clicked(role.userRoleId)} className='hover:cursor-pointer'>{role.name}</td>
      <td onClick={() => clicked(role.userRoleId)} className='hover:cursor-pointer'>{role.businessArea}</td>
      <td onClick={() => clicked(role.userRoleId)} className='hover:cursor-pointer'>{role.userRoleId}</td>
      <td onClick={() => clicked(role.userRoleId)} className='hover:cursor-pointer'><CollapsibleText text={role.description} maxLength={150} expandedByDefault={false}/></td>
    </tr>

  const userHeaders = () => 
    <tr className='table-green'>
      <th className='w-12'></th>
      <th className='hover:cursor-pointer text-left' onClick={() => setUserSorts(setSortOn(userSorts, "username"))}><Resizable minWidth={125} enable={enableObj}>Username<SortColumnArrow ascending={getSortByKey(userSorts, "username")?.asc}/></Resizable></th>
      <th className='hover:cursor-pointer text-left' onClick={() => setUserSorts(setSortOn(userSorts, "role", undefined, "name" ))}><Resizable minWidth={125} enable={enableObj}>Role<SortColumnArrow ascending={getSortByKey(userSorts, "role")?.asc}/></Resizable></th>
      <th className='hover:cursor-pointer text-left' onClick={() => setUserSorts(setSortOn(userSorts, "userId"))}><Resizable minWidth={125} enable={enableObj}>User ID<SortColumnArrow ascending={getSortByKey(userSorts, "userId")?.asc}/></Resizable></th>
      <th className='hover:cursor-pointer w-16 text-left' onClick={() => setUserSorts(setSortOn(userSorts, "userEmailAddress"))}><Resizable minWidth={125} enable={enableObj}>Email<SortColumnArrow ascending={getSortByKey(userSorts, "userEmailAddress")?.asc}/></Resizable></th>
      <th className='text-left'><Resizable minWidth={125} enable={enableObj}>Order Limits</Resizable></th>
      {UIToggles.pronounsShown && <th className='hover:cursor-pointer text-left' onClick={() => setUserSorts(setSortOn(userSorts, "pronouns"))}><Resizable minWidth={125} enable={enableObj}>Pronouns</Resizable></th>}
      <th><Resizable minWidth={125} enable={enableObj}>Super User?</Resizable></th>
    </tr>

  const userRow = (user: User) =>
    <tr className='h-14 hover:bg-gray-100' key={user.userId}>
      <td onClick={() => clicked(user.userId)} className='hover:cursor-pointer'><img className='w-10 h-10 rounded-md' src={user.profileImage} alt={user.profileImage ?? "Pfp"}/></td>
      <td onClick={() => clicked(user.userId)} className='hover:cursor-pointer'>{user.username}</td>
      <td onClick={() => clicked(user.userId)} className='hover:cursor-pointer'>{user.role?.name ?? '<no role>'}</td>
      <td>{user.userId}</td>
      <td className='pr-2'><CollapsibleText text={user.userEmailAddress ?? ""} maxLength={35} expandedByDefault={false} /></td>
      <td>{formatOrderLimits(user.orderLimits)}</td>
      {UIToggles.pronounsShown && <td>{user.pronouns}</td>}
      <td className='text-center'><input type="checkbox" checked={user.isSuperUser} disabled></input></td>
    </tr>

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("USERS & ROLES");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  async function closeCard() {
    setMaintenanceCardOpen(false); 
    await fetchData();
  }

  async function fetchData() {
    setLoading(true);
    setUsers(await listUsers());
    setRoles(await listRoles());
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="topnav-title">
        <div>
          <h1 className="text-2xl md:text-4xl font-bold">Users & Roles</h1>
          <h3>Manage users and roles for your organisation.</h3>
        </div>

        {!maintenanceCardOpen && <button 
          className="btn btn-primary btn h-full bg-green ml-auto" 
          onClick={() => {
            tab === "Roles" ? setClickedRoleId('') : setClickedUserId('');
            setMaintenanceCardOpen(true);
          }}
        >
          <i className="fa-solid fa-plus mr-2"></i>New {tab === "Roles" ? "Role" : "User"}
        </button>}
      </div>

      <div className="grid md:grid-cols-4 gap-4">
        <LightMetricCard title="Users" count={users.length} icon="user-tag" theme="green"/>
        <LightMetricCard title="Active Users" count={users.length} icon="user-tie-hair" theme="green"  />
        <LightMetricCard title="Total roles" count={roles.length} icon="user-pen" theme="green"  />
        <LightMetricCard title="Admins Active" count={users.filter((user) => user.isSuperUser).length} icon="user-crown" theme="green"  />
      </div>

      {maintenanceCardOpen 
        ? <div className="flex-grow overflow-hidden">
            {tab === "Roles" && 
              <RoleMaintenance userRoleId={clickedRoleId} roleAreas={businessAreas} closeCard={closeCard}/>
            }
            {tab === "Users" &&
              <UserMaintenance userId={clickedUserId} closeCard={closeCard}/>
            }
          </div>
          : <>
              <div className='flex flex-row h-[50px] gap-4'>
                <PageTabs tabs={tabOptions} activeTab={tab} setActiveTab={setTab} card={true} theme="green"/>

                <button className="dashboard-card bg-base-100 flex items-center gap-1 p-2 px-3 h-[50px] text-[#667085] ml-auto">
                  <i className="fa-solid fa-filter-list"></i><span className="hidden md:block">&nbsp;&nbsp;Filters</span>
                </button>

                <ExportCSVButton dataExporting={dataPerTab[tab] ?? maintenanceData} fieldsToIgnore={[]} outputFileName={'Maintenance' + (searchTerm.current ? `-${searchTerm.current}` : '')}/>
              </div>

              <div className='dashboard-card'>
                <table className="table-auto w-full">
                  <thead>
                    {tab === "Roles" && roleHeaders()}
                    {tab === "Users" && userHeaders()}
                  </thead>
                  {!loading &&
                  <tbody className="divide-y divide-gray-200">
                    {tab === "Roles" && roles.length > 0 && sort(roles, roleSorts).map((role) => roleRow(role))}
                    {tab === "Users" && users.length > 0 && sort(users, userSorts).map((user) => userRow(user))}
                  </tbody>
                  }
                  {loading && <span>Loading...</span>}
                </table>
              </div>
            </>
      }
    </div>
  );
}

export default UsersAndRolesPage;