import React, { useEffect, useState } from 'react';
import { useScreen } from '../../context';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import SimpleMetricCard from '../../components/widgets/simpleMetricCard';
import MonthlyTotals from '../../components/widgets/monthlyTotals';

import { fetchDashboardData } from '../../api';
import { Order } from '../../types';

import { groupOrders, processDataIntoYearlyChanges, processDataIntoMonthlyTotals } from '../dashboard/utils';

import ReportTemplate from '../../components/pdfs/templates/report';
import { exportChartToImage } from './utils';

const YearlySalesReport = () => {
  const [fetching, setFetching] = useState(true);
  const [totalOrders, setTotalOrders] = useState({ thisYear: 0, lastYear: 0 });
  const [totalSales, setTotalSales] = useState({ thisYear: 0, lastYear: 0 });
  const [totalProfit, setTotalProfit] = useState({ thisYear: 0, lastYear: 0 });

  const [ordersThisYear, setOrdersThisYear] = useState<Order[]>([]);
  const [ordersLastYear, setOrdersLastYear] = useState<Order[]>([]);
  const [rollingMonthlySales, setRollingMonthlySales] = useState<{ name: string; "Last Year": number; "This Year": number; }[]>([]);
  const [pdfDownloading, setPdfDownloading] = useState(false);

  const { setScreenId } = useScreen();
  setScreenId("DASH");

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching dashboard data...');
        const fetchResponse = await fetchDashboardData();
        if (!fetchResponse) throw new Error('No data returned from fetchDashboardData');
        
        const orders = fetchResponse;

        const grouppedOrders = groupOrders(orders);
        const yearlyChanges = processDataIntoYearlyChanges(grouppedOrders.ordersThisYear, grouppedOrders.ordersLastYear);

        setOrdersThisYear(grouppedOrders.ordersThisYear);
        setOrdersLastYear(grouppedOrders.ordersLastYear);
        const monthlySales = processDataIntoMonthlyTotals(grouppedOrders.ordersLastYear, grouppedOrders.ordersThisYear, "Sales");
        console.log(monthlySales);
        setRollingMonthlySales(monthlySales);
        setTotalOrders(yearlyChanges.totalOrders);
        setTotalProfit(yearlyChanges.totalProfit);
        setTotalSales(yearlyChanges.totalSales);
      
        console.log("Data processed.");
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching dashboard data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDownload = async () => {
    setPdfDownloading(true);
    const orderImageUrl = await exportChartToImage('monthly-orders-chart');
    const salesImageUrl = await exportChartToImage('monthly-sales-chart');
    const profitImageUrl = await exportChartToImage('monthly-profit-chart');

    const invoicePayload = {
      totalOrders,
      totalSales,
      totalProfit,
      rollingMonthlySales,
      orderImageUrl,
      salesImageUrl,
      profitImageUrl,
    };

    const doc = <ReportTemplate {...invoicePayload} />;
    const blob = await pdf(doc).toBlob();
    saveAs(blob, 'invoice.pdf');
    setPdfDownloading(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex w-full items-end justify-between gap-4 dashboard-card p-4">
        <h1 className="text-2xl md:text-4xl font-bold">Yearly Sales Report</h1>

        <button
          className="btn btn-primary disabled:opacity-50"
          onClick={handleDownload}
          disabled={pdfDownloading || fetching}
        >
          <i className={`fa-solid ${pdfDownloading || fetching ? 'fa-spinner fa-spin' : 'fa-download'}`}></i>
          &nbsp;&nbsp;Download PDF
        </button>
      </div>


      <div className="grid md:grid-cols-3 gap-4 flex-grow mt-4">
          <SimpleMetricCard
            identifier='Total HDM'
            metric='Orders'
            count={totalOrders.thisYear}
            change={totalOrders.thisYear - totalOrders.lastYear}
            period='year'
            icon='cart-shopping'
            fetching={fetching}
            theme="green"
          />

          <SimpleMetricCard
            identifier='Total HDM'
            metric='Sales'
            count={totalSales.thisYear}
            change={totalSales.thisYear - totalSales.lastYear}
            period='year'
            icon='cash-register'
            fetching={fetching}
            theme="green"
          />

          <SimpleMetricCard
            identifier='Total HDM'
            metric='Profit'
            count={totalProfit.thisYear}
            change={totalProfit.thisYear - totalProfit.lastYear}
            period='year'
            icon='arrow-trend-up'
            fetching={fetching}
            theme="green"
          />

        <MonthlyTotals ordersLastYear={ordersLastYear} ordersThisYear={ordersThisYear} defaultMetric="Orders" id="monthly-orders-chart" fetching={fetching} />
        <MonthlyTotals ordersLastYear={ordersLastYear} ordersThisYear={ordersThisYear} defaultMetric="Sales" id="monthly-sales-chart" fetching={fetching} />
        <MonthlyTotals ordersLastYear={ordersLastYear} ordersThisYear={ordersThisYear} defaultMetric="Profit" id="monthly-profit-chart" fetching={fetching} />
      </div>
    </div>
  );
}

export default YearlySalesReport;