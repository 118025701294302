import { useState } from "react";

const subjectivePronouns: string[] = [
    "he",
    "she",
    "they"
]

const objectivePronouns: string[] = [
    "him",
    "her",
    "them"
]

//These are the ones that most people will ascribe to, show them first
const mostCommon: string[] = ["they/them", "he/him", "she/her"];
const chooseOtherPronounOption: string = 'Other (please specify)';

//Take all of the concatenated pronouns and flatten them down to one array, then exclude the common ones to avoid duplicates.
const pronouns: string[] = mostCommon/*.concat(subjectivePronouns.flatMap<string>((subjectivePronoun) => {
    //Pronouns always take the form of subjective/objective. Some people identify as things like she/them, so make sure to include all of those by forming every
    //possible permutation of the subjective and objectives.
    return objectivePronouns.map<string>((objectivePronoun) => {
        return `${subjectivePronoun}/${objectivePronoun}`
    })
}).filter((value) => !mostCommon.includes(value)))*/.concat(["Would rather not say", chooseOtherPronounOption]);

type PronounSelectProps = {
    onPronounSelected?: (pronoun: string) => void,
    className?: string,
    defaultValue?: string,
    id?: string
}

export const PronounSelect = ({onPronounSelected, className="", defaultValue="", id} : PronounSelectProps) => {
    const [pronoun, setPronoun] = useState('');

    function pronounUpdated(selected: string) {
        if (!selected) return;

        setPronoun(selected);
        if (onPronounSelected) onPronounSelected(selected);
    }

    function selectPronoun(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedPronoun: string = e.target.value;

        pronounUpdated(selectedPronoun);
    }

    function setOtherPronoun(e: React.ChangeEvent<HTMLInputElement>) {
        const selectedPronoun: string = e.target.value;
        pronounUpdated(selectedPronoun);
    }

    return (
        <div className={className}>
            <select onChange={selectPronoun} defaultValue={defaultValue} className={`border-2 rounded border-gray-300 w-full`} id={id}>
                <option value="" disabled>Specify pronoun for System Display</option>
                {pronouns.map((pronoun) => (
                    <option value={pronoun} key={pronoun}>{pronoun}</option>
                ))}
            </select>
            {pronoun === chooseOtherPronounOption &&
                <input type="text" placeholder="Enter other pronoun here..." defaultValue={pronoun !== chooseOtherPronounOption ? pronoun : ''} onChange={setOtherPronoun} className="mt-2 border-2 rounded border-gray-300 w-full md:w-80 lg:w-96"></input>
            }
        </div>
    )
}