import { useEffect, useRef, useState } from "react";
import VerticalTextInput from "../../components/verticalTextInput"
import { TriState, User, UserPermissions, UserRole } from "../../types";
import { permissionsHierarchy, propToHumanReadable } from "../../dummyData";
import { UserPermissionCustomisation } from "../../components/userPermissionCustomisation";
import { createNewRole, fetchRoleByKey, fetchUserByKey, listRoles, updateRole } from "../../api";
import BackLink from "../../components/backLink";


type RoleMaintenanceProps = {
    userRoleId: string,
    roleAreas: string[],
    closeCard: () => void
}

export const RoleMaintenance = ({userRoleId, roleAreas, closeCard}: RoleMaintenanceProps) => {
    const [roleId, setRoleId] = useState<string>(userRoleId);
    const [roleName, setRoleName] = useState<string>("");
    const [roleArea, setRoleArea] = useState<string>("");
    const [selectedAccessLevels, setSelectedAccessLevels] = useState<UserPermissions>({} as UserPermissions)
    const [description, setDescription] = useState<string>('')
    const [creatingNewUser, setCreatingNewUser] = useState<boolean>(false);
    //Not updated until save is pressed.
    const chosenRole = useRef<UserRole>({
        userRoleId: "",
        name: "",
        businessArea: "",
        permissions: {} as UserPermissions,
        description: ""
    });
    const roles = useRef<UserRole[]>([]);

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    function areAllFieldsValid() : {success?: boolean, errorMessage?: string} {
        //TODO: Check for no-no words
        if (!roleName) return {errorMessage: "Empty role name!"};
        if (!roleArea) return {errorMessage: "No business area specified!"};
        if (!roleAreas.includes(roleArea)) return {errorMessage: `Invalid role area! Role area is: ${roleArea}`};
        if (!selectedAccessLevels) return {errorMessage: "No permissions defined!"};
        if (!Object.values(selectedAccessLevels).includes(true)) return {errorMessage: "Role has no permissions."}
        //Description is allowed to be false
        return {success: true}
    }

    async function saveUserChanges() {
        const fieldsValid = areAllFieldsValid();

        if (fieldsValid.success) {
            chosenRole.current.name = roleName;
            chosenRole.current.businessArea = roleArea;
            chosenRole.current.permissions = selectedAccessLevels;
            chosenRole.current.description = description;

            const foundExistingRole = roles.current.find((value) => value.userRoleId === chosenRole.current.userRoleId);

            setSaving(true);
            if (userRoleId && foundExistingRole) {
                //Update role
                await updateRole(chosenRole.current, permissionsHierarchy);
                roles.current[roles.current.indexOf(foundExistingRole)] = chosenRole.current
            } else {
                //Create role
                await createNewRole(chosenRole.current, permissionsHierarchy);
                chosenRole.current.userRoleId = roleId;
                roles.current.push(chosenRole.current);
            }
            setSaving(false);

            if (closeCard) closeCard();
        } else {
            alert("Cannot save. " + fieldsValid.errorMessage);
        }
    }

    function accessLevelToggled(prop: string, newValue: TriState) {
        if (chosenRole.current.permissions) setSelectedAccessLevels({...chosenRole.current.permissions, [prop]: newValue});
        else setSelectedAccessLevels({[prop]: newValue} as UserPermissions);
    }

    const backButton = () => <BackLink onClick={() => closeCard()}/>

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            roles.current = await listRoles();
            //Get role from userRoleId
            if (userRoleId) {
                const searchedRole = await fetchRoleByKey(userRoleId);//roles.filter((value: UserRole) => userRoleId === value.userRoleId)[0];

                if (searchedRole) {
                    chosenRole.current = searchedRole;
                    setRoleName(chosenRole.current.name);
                    setRoleArea(chosenRole.current.businessArea);
                    setDescription(chosenRole.current.description);
                    setSelectedAccessLevels(chosenRole.current.permissions);
                }
            } else {
                setCreatingNewUser(true);
            }

            setLoading(false);
        }

        fetchData();
    }, [userRoleId])

    if (loading) return (
        <section className="dashboard-card p-4 flex flex-row">
            <span className="w-[95%]">Loading...</span>
            {backButton()}
        </section>
    );

    return (
        <section className="dashboard-card flex flex-col p-2">
            <header className="flex flex-row w-full">
                <div className="w-[95%]"><span className="font-bold text-2xl">Role Maintenance</span></div>
                {backButton()}
            </header>
            <div className="flex flex-col gap-2">
                <span className="font-bold">Overview</span>
                {!creatingNewUser && <VerticalTextInput 
                    label="Role Id" 
                    labelColourClass="text-black" 
                    labelClassName="text-lg" 
                    value={roleId}
                    labelFontClass="text-lg font-bold"
                    inputClassName={`border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base${!creatingNewUser && " bg-gray-100"}`}
                    className="m-0" 
                    onValueChanged={(newValue: string) => setRoleId(newValue)}
                    readonly={true}
                />}
                <VerticalTextInput 
                    label="Name" 
                    labelColourClass="text-black" 
                    labelClassName="text-lg" 
                    value={roleName}
                    labelFontClass="text-lg font-bold"
                    inputClassName="border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base" 
                    className="m-0" 
                    onValueChanged={(newValue: string) => setRoleName(newValue)}
                />
                <span className="text-sm text-[#8181A5]">Name of Role that can be allocated to system users on a 1 to many relationship.</span>
                <VerticalTextInput
                    label="Description" 
                    labelColourClass="text-black" 
                    labelClassName="text-lg" 
                    value={description}
                    labelFontClass="text-lg font-bold"
                    inputClassName="border-2 rounded border-gray-300 w-full md:w-80 lg:w-96 text-base" 
                    className="m-0" 
                    onValueChanged={(newValue: string) => setDescription(newValue)}
                    multiline
                />
                <label htmlFor="roleMaintenanceAreaSelect" className="font-bold">Role Area</label>
                <select id="roleMaintenanceAreaSelect" value={roleArea} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setRoleArea(e.target.value)} className="border-2 rounded border-gray-300 w-96">
                    <option value="" disabled>Select a role area...</option>
                    {roleAreas.map((roleArea) => <option value={roleArea} key={roleArea}>{roleArea}</option>)}
                </select>
                <span className="text-sm text-[#8181A5]">Select role area of role for description purposes. Limit of 1 role area per role.</span>
                <label htmlFor="roleMaintenancePermissionCustomisation" className="font-bold">Access Level</label>
                <UserPermissionCustomisation 
                    id="roleMaintenancePermissionCustomisation" 
                    userPermissions={chosenRole.current.permissions ?? {} as UserPermissions} 
                    propToHumanReadable={propToHumanReadable} 
                    onAccessLevelToggled={accessLevelToggled}
                    toggledOnColour="#308e63"/>
                <span className="text-sm text-[#8181A5] mb-2">These can be overriden on a per user basis in the user maintenance menu.</span>

                <button className="btn-secondary-green pt-2 pb-2 pl-4 pr-4 w-full lg:w-96 md:w-28">View Site as Role</button>

                <button 
                    className="btn-primary bg-green pt-2 pb-2 pl-4 pr-4 w-full lg:w-96 md:w-28" 
                    onClick={saveUserChanges}
                    disabled={saving}
                >
                        <i className="fa-solid fa-floppy-disk mr-1"></i>{saving ? "Saving..." : creatingNewUser ? "Create" : "Save"}
                    
                </button>
            </div>
        </section>
    );
}