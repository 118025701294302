import Attachment from "../emailAttachment";
import { AvailableThemes, EmailAttachment, User } from "../../types";
import { CollapsibleText } from "../widgets/collapsibleText";

type TimelineUserCommentProps = {
    comment: string,
    commentTime: Date,
    commenterUsername?: string,
    commenterEmail?: string,
    commenterPfp?: string,
    attachedFiles?: EmailAttachment[],
    isFirstComment: boolean,
    onDelete?: () => void,
    theme?: AvailableThemes,
    timeOnly?: boolean
}

const TimelineUserComment = ({ comment, commentTime, commenterPfp, commenterUsername = "Unknown user", commenterEmail = "No email", attachedFiles, isFirstComment = false, onDelete, theme="blue", timeOnly=false}: TimelineUserCommentProps) => {

    

    function deleteComment() {
        if (onDelete && window.confirm("Are you sure you want to delete this comment? Comment snippet: " + comment.slice(0, 40) + (comment.length > 40 ? '...' : ''))) onDelete();
    }

    return (
        <div className="dashboard-card min-h-20 flex flex-row w-full pt-2 pb-4 gap-3 hover:bg-gray-50 hover:border-gray-50">
            <div className="min-w-20 w-1/5 p-2">
                {/* Profile picture */}
                <img src={commenterPfp ?? "tayah.png"} alt={commenterUsername} height={64} width={64} className="h-[64px] w-[64px] rounded-full"/>
            </div>
            <div className="flex flex-col w-3/5 gap-3">
                <div className="flex flex-row gap-2 place-items-center mt-0.5">
                    <span className="font-bold" title={commenterUsername}>{commenterUsername}</span>
                    <span className="text-gray-500 w-[30%] truncate" title={commenterEmail}>{commenterEmail}</span>
                    <span className="text-gray-500 text-xs" title={commentTime.toLocaleString()}>{timeOnly ? commentTime.toLocaleTimeString() : commentTime.toLocaleString()}</span>
                </div>
                <div className="flex flex-row w-full gap-2 flex-wrap">
                    {/* Attachments */}
                    {attachedFiles && attachedFiles?.map((value, index) => 
                        <Attachment
                            fileName={value.fileName}
                            fileSizeInBytes={value.fileSizeInBytes}
                            contentId={value.contentId}
                            thumbnail={value.thumbnail}
                            isFirstComment={isFirstComment}
                            theme={theme}
                        />
                    )}
                </div>
                <span className="overflow-wrap-normal"><CollapsibleText className="w-full" text={comment} maxLength={130} expandedByDefault={false} appearsBelowText textOverrideToShow="Show more" textOverrideToHide="Show less"/></span>
            </div>
            <div>
                {onDelete && <i title="Delete" className="fa-solid fa-trash text-gray-300 hover:cursor-pointer hover:text-red-500 m-2 text-lg" onClick={deleteComment}/>}
            </div>
        </div>
    );
}

export default TimelineUserComment;