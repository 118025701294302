import React, { useState } from 'react';
import { useScreen } from '../context';

const ExecuteCommandScreen = () => {
  const { setScreenId, executeCommandVersion, setExecuteCommandVersion } = useScreen();
  setScreenId("EXECUTE_COMMAND");

  console.log(executeCommandVersion);

  return (
    <div className="flex flex-col">
      <div className="p-4 dashboard-card">
        <h1 className="text-2xl md:text-4xl font-bold">Execute Command</h1>
        <h3>Choose the Execute Command version you would like to use for API calls. This will only affect this device.</h3>
      </div>
      <div className="border-x border-b border-gray-300 pr-4 overflow-hidden">
        <select
          className="p-2 w-full outline-none"
          onChange={(e) => setExecuteCommandVersion(e.target.value)}
          value={executeCommandVersion}
        >
          <option value="ExecuteCommand" selected={executeCommandVersion === 'ExecuteCommand'}>
            Execute Command
          </option>
          <option value="ExecuteCommand2">Execute Command V2</option>
        </select>
      </div>
    </div>
  );
}

export default ExecuteCommandScreen;