import React, { useEffect, useState } from 'react';
import { useScreen } from '../../context';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import SimpleMetricCard from '../../components/widgets/simpleMetricCard';
import MonthlyTotals from '../../components/widgets/monthlyTotals';

import { fetchDashboardData } from '../../api';
import { Order } from '../../types';

import { groupOrders, processDataIntoYearlyChanges, processDataIntoMonthlyTotals } from '../dashboard/utils';

import ReportTemplate from '../../components/pdfs/templates/report';

import { exportChartToImage, processSalesIntoSalesReps } from './utils';

import SimpleLineChart from '../../components/widgets/charts/lineChart';

import { sortData } from '../../components/tableSorting';
import Pagination from '../../components/pagination';
import { Resizable } from 're-resizable';

import { formatNumber } from '../../utils';

const ExternalRepsReport = () => {
  const [fetching, setFetching] = useState(true);
  const [totalOrders, setTotalOrders] = useState({ thisYear: 0, lastYear: 0 });
  const [totalSales, setTotalSales] = useState({ thisYear: 0, lastYear: 0 });
  const [totalProfit, setTotalProfit] = useState({ thisYear: 0, lastYear: 0 });

  const [totalOrdersWithExternalRep, setTotalOrdersWithExternalRep] = useState({ thisYear: 0, lastYear: 0 });
  const [totalSalesWithExternalRep, setTotalSalesWithExternalRep] = useState({ thisYear: 0, lastYear: 0 });
  const [totalProfitWithExternalRep, setTotalProfitWithExternalRep] = useState({ thisYear: 0, lastYear: 0 });

  const [ordersThisYear, setOrdersThisYear] = useState<Order[]>([]);
  const [ordersLastYear, setOrdersLastYear] = useState<Order[]>([]);
  const [rollingMonthlySales, setRollingMonthlySales] = useState<{ name: string; "Last Year": number; "This Year": number; }[]>([]);
  const [pdfDownloading, setPdfDownloading] = useState(false);

  const [dataByReps, setDataByReps] = useState<{name: string, orders: number, sales: number, profit: number}[]>([]);
  const [rollingRepMonthlySales, setRollingRepMonthlySales] = useState<{ name: string, [key: string]: number | string }[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const { setScreenId } = useScreen();
  setScreenId("DASH");

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching dashboard data...');
        const fetchResponse = await fetchDashboardData();
        if (!fetchResponse) throw new Error('No data returned from fetchDashboardData');
        
        const orders = fetchResponse;

        const grouppedOrders = groupOrders(orders);
        const yearlyChanges = processDataIntoYearlyChanges(grouppedOrders.ordersThisYear, grouppedOrders.ordersLastYear);

        setOrdersThisYear(grouppedOrders.ordersThisYear);
        setOrdersLastYear(grouppedOrders.ordersLastYear);
        const monthlySales = processDataIntoMonthlyTotals(grouppedOrders.ordersLastYear, grouppedOrders.ordersThisYear, "Sales");

        const dataBySalesRep = await processSalesIntoSalesReps(grouppedOrders.ordersThisYear);
        setDataByReps(dataBySalesRep.salesRepData);
        setRollingRepMonthlySales(dataBySalesRep.rollingMonthlySales);

        console.log(dataBySalesRep);
        setRollingMonthlySales(monthlySales);
        setTotalOrders(yearlyChanges.totalOrders);
        setTotalProfit(yearlyChanges.totalProfit);
        setTotalSales(yearlyChanges.totalSales);

        setTotalOrdersWithExternalRep(yearlyChanges.totalOrdersWithExternalRep);
        setTotalProfitWithExternalRep(yearlyChanges.totalProfitWithExternalRep);
        setTotalSalesWithExternalRep(yearlyChanges.totalSalesWithExternalRep);
      
        console.log("Data processed.");
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching dashboard data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDownload = async () => {
    setPdfDownloading(true);
    const orderImageUrl = await exportChartToImage('monthly-orders-chart');
    const salesImageUrl = await exportChartToImage('monthly-sales-chart');
    const profitImageUrl = await exportChartToImage('monthly-profit-chart');

    const invoicePayload = {
      totalOrders,
      totalSales,
      totalProfit,
      rollingMonthlySales,
      orderImageUrl,
      salesImageUrl,
      profitImageUrl,
    };

    const doc = <ReportTemplate {...invoicePayload} />;
    const blob = await pdf(doc).toBlob();
    saveAs(blob, 'invoice.pdf');
    setPdfDownloading(false);
  };

  const defaultSortKey = "profit";
  const [sortConfig, setSortConfig] = useState({ key: defaultSortKey, direction: 'desc' });
  const handleSort = (key: string) => {
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      setSortConfig({ key, direction: 'desc' });
    } else {
      setSortConfig({ key, direction: 'asc' });
    }
  };
  const sortedData = sortData({ defaultSortKey, data: dataByReps, sortConfig });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full items-end justify-between gap-4">
        <h1 className="text-2xl md:text-4xl font-bold">External Sales Rep Report</h1>

        <button
          className="btn btn-green disabled:opacity-50"
          onClick={handleDownload}
          disabled={pdfDownloading || fetching}
        >
          <i className={`fa-solid ${pdfDownloading || fetching ? 'fa-spinner fa-spin' : 'fa-download'}`}></i>
          &nbsp;&nbsp;Download PDF
        </button>
      </div>

      <div className="grid md:grid-cols-3 gap-4 flex-grow">
          <SimpleMetricCard
            identifier='External Rep'
            metric='Orders'
            count={totalOrdersWithExternalRep.thisYear}
            change={totalOrdersWithExternalRep.thisYear - totalOrdersWithExternalRep.lastYear}
            period='year'
            icon='cart-shopping'
            fetching={fetching}
            theme="green"
          />

          <SimpleMetricCard
            identifier='External Rep'
            metric='Sales'
            count={totalSalesWithExternalRep.thisYear}
            change={totalSalesWithExternalRep.thisYear - totalSalesWithExternalRep.lastYear}
            period='year'
            icon='cash-register'
            fetching={fetching}
            theme="green"
          />

          <SimpleMetricCard
            identifier='External Rep'
            metric='Profit'
            count={totalProfitWithExternalRep.thisYear}
            change={totalProfitWithExternalRep.thisYear - totalProfitWithExternalRep.lastYear}
            period='year'
            icon='arrow-trend-up'
            fetching={fetching}
            theme="green"
          />
      </div>

      <div className="dashboard-card rounded-lg flex-grow overflow-show-override">
        <div className="card-header bg-green mb-6 rounded-t-[8px]">
          <i className="fas fa-chart-line"></i>
          <h3 className="font-bold">Monthly Sales by Rep</h3>
        </div>
        {rollingRepMonthlySales.length > 0 && 
          <div className="pr-4 pb-4">
            <SimpleLineChart chartData={rollingRepMonthlySales} metric="Sales" /> 
          </div>
        }
      </div>

      <div className="dashboard-card rounded-lg overflow-hidden flex-grow">
        <table className="table-auto w-full">
          <thead>
            <tr className="table-green">
                {[
                  { name: "Sales Rep", key: "name" },
                  { name: "Orders", key: "orders" },
                  { name: "Sales", key: "sales" },
                  { name: "Profit", key: "profit" },
                ].map((header, index) => (
                <th
                  key={index}
                  className="cursor-pointer"
                  onClick={() => handleSort(header.key)}
                >
                  <Resizable
                  enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                  minWidth={125}
                  className={`flex items-center gap-2 shrink-0 w-full ${index !== 0 && "justify-center"}`}
                  >
                  <p className="shrink-0">{header.name}</p>
                  {sortConfig.key === header.key && (
                    <p><i className={`fa-solid fa-caret-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i></p>
                  )}
                  </Resizable>
                </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {sortedData
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((salesRep: any, rowIndex: number) => (
                <tr key={rowIndex}>
                  <td>
                    <p className="font-semibold text-sm text-left-override">{rowIndex + 1}.&nbsp;&nbsp;{salesRep.name || "HDM Direct"}</p>
                  </td>
                  <td><p className="text-sm px-1 pr-4">{salesRep.orders.toLocaleString()}</p></td>
                  <td><p className="text-sm px-1">£{formatNumber(salesRep.sales)}</p></td>
                  <td><p className="text-sm px-1">£{formatNumber(salesRep.profit)}</p></td>
                </tr>
              )
            )}
          </tbody>
        </table>
        
        <div className="px-4 pb-4 mt-auto">
          <Pagination 
            numberOfEntries={sortedData.length} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            source="daive"
          />
        </div>
      </div>
    </div>
  );
}

export default ExternalRepsReport;