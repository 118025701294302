import { Customer, Product, Order, User, UserRole, PermissionHierarchy, UserPermissions } from './types';
import { combinePermissions, convertD3RoleToWTRole, convertD3UserToWTUser, packPermissionsObjToD3Permissions, unpackD3PermissionsToPermissionObj } from './utils';

const BASE_URL = process.env.REACT_APP_API_URL;

const fetchFromApi = async (apiPath: string, bodyData: {}) => {
  let executeCommandVersion = localStorage.getItem('executeCommandVersion') || 'ExecuteCommand';
  let userApiToken = localStorage.getItem('userApiToken') || '';

  bodyData = {
    ...bodyData,
    ExecuteCommand: executeCommandVersion,
  };
  
  const response = await fetch(`${BASE_URL}${apiPath}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  if (!response.ok) throw new Error('Network response was not ok');
  
  let data = await response.json();
  console.log(data);

  return data;
}

export const fetchProducts = async () => {
  try {
    const response = await fetchFromApi('products/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchProductCategories = async () => {
  try {
    const response = await fetchFromApi('products/list', {});

    if (!response.ExecuteCommand.Output) {
      throw new Error('No data in response');
    }

    const uniqueCategories = Array.from(new Set(response.ExecuteCommand.Output.map((product: Product) => product['PGROUP.DESC'])));
    console.log(uniqueCategories, response);
    return uniqueCategories as string[];
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchProduct = async (productCode: string) => {
  try {
    const response = await fetchFromApi('products/fetch', { productCode });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching product:', error);
  }
}

export const updateProduct = async (productCode: string, OriginalValue: any, Value: any) => {
  try {
    const response = await fetchFromApi('products/update', { productCode, OriginalValue, Value });
    //console.log(response);
    return response.ExecuteCommand;
  }
  catch (error) {
    console.error('There was a problem updating product:', error);
  }
}

export const fetchBulkProducts = async (productCodes: string[]) => {
  try {
    const response = await fetchFromApi('products/bulk-fetch', { productCodes });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchProductsToClear = async () => {
  try {
    const response = await fetchFromApi('products/fetch-to-clear', {});
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchMetaDataForTopProducts = async (productCodes: string[]) => {
  try {
    const response = await fetchFromApi('products/top-products-data', { productCodes });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching top product data:', error);
  }
}

export const searchProducts = async (searchQuery: string) => {
  try {
    const response = await fetchFromApi('products/search', { searchQuery });
    console.log(response.ExecuteCommand.Output);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem searching products:', error);
  }
}

export const listSuppliers = async () => {
  try {
    const response = await fetchFromApi('suppliers/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching suppliers:', error);
  }
}

export const createSupplier = async (supplier: any) => {
  try {
    const response = await fetchFromApi('suppliers/create', { supplier });
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem creating supplier:', error);
  }
}

export const updateSupplier = async (supplierId: string, OriginalValue: any, Value: any) => {
  try {
    const response = await fetchFromApi('suppliers/update', { supplierId, OriginalValue, Value });
    console.log(response);

    if (response.ExecuteCommand.Success.includes('Error')) {
      throw new Error(response.ExecuteCommand.Message);
    }

    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem updating supplier:', error);
  }
}

export const fetchSupplier = async (supplierId: string) => {
  try {
    const response = await fetchFromApi('suppliers/fetch', { supplierId });
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching supplier:', error);
  }
}

export const updateSupplierBankInfo = async (supplierId: string, OriginalValue: any, Value: any) => {
  try {
    const response = await fetchFromApi('suppliers/update-bank-info', { supplierId, OriginalValue, Value });
    console.log(response);
    return response.ExecuteCommand;
  }
  catch (error) {
    console.error('There was a problem fetching supplier bank info:', error);
  }
}

export const searchSuppliers = async (searchQuery: string) => {
  try {
    const response = await fetchFromApi('suppliers/search', { searchQuery });
    console.log(response.ExecuteCommand.Output);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem searching suppliers:', error);
  }
}

export const listCustomers = async () => {
  try {
    const response = await fetchFromApi('customers/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching customers:', error);
  }
}

export const fetchCustomer = async (customerId: string) => {
  try {
    const response = await fetchFromApi('customers/fetch', { customerId });
    console.log(response);
    return response.ExecuteCommand.Output[0] || null;
  }
  catch (error) {
    console.error('There was a problem fetching customers:', error);
  }
}

export const updateCustomer = async (customerId: string, OriginalValue: Customer, Value: Customer) => {
  try {
    const response = await fetchFromApi('customers/update', { customerId, OriginalValue, Value });
    console.log(response);
    
    // Check for the presence of "Error" in the response.ExecuteCommand.Success string
    if (response.ExecuteCommand.Success.includes('Error')) {
      throw new Error(response.ExecuteCommand.Message);
    }

    return response.ExecuteCommand.Output;
  }
  catch (error) {
    throw error;
  }
} 

export const searchCustomers = async (searchQuery: string) => {
  try {
    const response = await fetchFromApi('customers/search', { searchQuery });
    console.log(response.ExecuteCommand.Output);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem searching customers:', error);
  }
}

export const fetchCustomerLocations = async (customerId: string) => {
  console.log('fetching locations for customer:', customerId);
  try {
    const response = await fetchFromApi('locations/fetch', { customerId });
    console.log(response);
    return response.ExecuteCommand.Output || null;
  }
  catch (error) {
    console.error('There was a problem fetching customers:', error);
  }
}

export const fetchDashboardData = async (customerId?: string) => {
  try {
    let payload = customerId ? { customerId } : {};
    const response = await fetchFromApi('salesOrders/fetch-dashboard', payload);
    console.log(response);
    if (!response.orders) throw new Error('Missing data in response');

    let orders = response.orders.ExecuteCommand.Output as Order[];
    return orders;
  }
  catch (error) {
    console.error('There was a problem fetching dashboard data:', error);
  }
}

export const listSalesOrders = async () => {
  try {
    const response = await fetchFromApi('salesOrders/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchSalesOrder = async (orderId: string) => {
  try {
    const response = await fetchFromApi('salesOrders/fetch', { orderId });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching order:', error);
  }
}

export const fetchSalesOrderByCustomer = async (customerId: string) => {
  try {
    const response = await fetchFromApi('salesOrders/fetch-by-customer', { customerId });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching order:', error);
  }
}

export const searchSalesOrders = async (searchQuery: {}) => {
  try {
    const response = await fetchFromApi('salesOrders/search', { searchQuery });
    console.log(response.ExecuteCommand.Output);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem searching sales orders:', error);
  }
}

export const listPurchaseOrders = async () => {
  try {
    const response = await fetchFromApi('purchaseOrders/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching purchase orders:', error);
  }
}

export const fetchPurchaseOrder = async (orderId: string) => {
  try {
    const response = await fetchFromApi('purchaseOrders/fetch', { orderId });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching order:', error);
  }
}

export const createPurchaseOrder = async (purchaseOrder: any) => {
  try {
    const response = await fetchFromApi('purchaseOrders/create', { purchaseOrder });
    console.log(response);
    return response.ExecuteCommand;
  }
  catch (error) {
    console.error('There was a problem creating purchase order:', error);
  }
}

export const searchPurchaseOrders = async (searchQuery: {}) => {
  try {
    const response = await fetchFromApi('purchaseOrders/search', { searchQuery });
    console.log(response.ExecuteCommand.Output);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem searching purchase orders:', error);
  }
}

export const updatePurchaseOrder = async (orderId: string, OriginalValue: any, Value: any) => {
  try {
    const response = await fetchFromApi('purchaseOrders/update', { orderId, OriginalValue, Value });
    console.log(response);
    return response.ExecuteCommand;
  }
  catch (error) {
    console.error('There was a problem updating purchase order:', error);
  }
}

export const listBroadcasts = async () => {
  try {
    const response = await fetchFromApi('broadcasts/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching broadcasts:', error);
  }
}

export const createBroadcast = async (broadcast: any) => {
  try {
    const response = await fetchFromApi('broadcasts/create', { broadcast });
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem creating broadcast:', error);
  }
}

export const listRoles = async (): Promise<UserRole[]> => {
  try {
    const authToken = 'faketoken';
    const d3Response = await fetchFromApi('roles/list', {authToken: authToken});
    const execCMDData = d3Response.ExecuteCommand.Output;

    if (execCMDData.length < 1) {
      return [];
    }

    return execCMDData.map((value: any) => convertD3RoleToWTRole(value));
  } catch (error) {
    console.log("There was a problem listing roles: ", error);
    return []
  }
}

export const fetchRoleByKey = async (userRoleId: string): Promise<UserRole | undefined> => {
  console.log("Fetching role with key: ", userRoleId)
  if (!userRoleId) {return undefined;}
  try {
    const authToken = 'faketoken';
    const d3Response = await fetchFromApi('roles/fetch', {authToken: authToken, roleKey: userRoleId});

    return convertD3RoleToWTRole(d3Response.ExecuteCommand.Output[0]);

  } catch (error) {
    console.log("There was a problem fetching role by key: ", error);
    return undefined;
  }
}

export const listUsers = async (): Promise<User[]> => {
  try {
    const authToken = 'faketoken';
    const response = await fetchFromApi('users/list', {authToken: authToken});

    //Map execute command data to expected users
    const execCMDData = response.ExecuteCommand.Output;

    if (execCMDData.length < 1) {
      return [];
    }

    return await Promise.all(execCMDData.map(async (userData: any) => 
      await convertD3UserToWTUser(userData)
    ))
    //return await execCMDData.map(async (userData: any) => await convertD3UserToWTUser(userData));
  } catch (error: any) {
    console.error('There was a problem fetching users: ', error.toString());
    return [];
  }
}

export const fetchUserByKey = async (userId: string): Promise<User | undefined> => {
  console.log("Fetching user by key: ", userId);
  if (!userId) return undefined;
  try {
    const authToken = 'faketoken';
    const response = await fetchFromApi('users/fetch', {authToken: authToken, userKey: userId});

    console.log("User response: ", JSON.stringify(response));

    const user: User = await convertD3UserToWTUser(response.ExecuteCommand.Output[0]);

    console.log("Converted user");
    return user;
  } catch (error) {
    console.error('There was a problem fetching user: ', userId);
    return undefined;
  }
}

export const createNewUser = async (user: User, permissionHierarchy: PermissionHierarchy): Promise<any> => {
  console.log(user);

  if (!user) {
    console.error('User not provided to updateUser!');
    return;
  }

  if (!permissionHierarchy) {
    console.error('Permission hierarchy not provided to updateUser!');
    return;
  }

  try {
    const authToken = 'faketoken';
    const {permissionNodes, permissionValues} = packPermissionsObjToD3Permissions(permissionHierarchy, combinePermissions(user.role?.permissions ?? {}, user.permissionOverrides))
    const response = await fetchFromApi('users/new', {
      authToken: authToken, 
      username: user.username,
      email: user.userEmailAddress,
      password: user.password,
      pronouns: user.pronouns,
      profileImage: user.profileImage,
      roleKey: user.role?.userRoleId ?? undefined,
      purchaseOrderLimit: user.orderLimits?.purchase ?? undefined,
      salesOrderLimit: user.orderLimits?.sales ?? undefined,
      quoteLimit: user.orderLimits?.quote ?? undefined,
      invoiceLimit: user.orderLimits?.invoice ?? undefined,
      superUser: user.isSuperUser,
      permissionNodes: permissionNodes,
      permissionValues: permissionValues
    })
    return response.d3Response;
    //TODO: error handling
  } catch (error) {
    console.error(error);
  }
};

export const updateUser = async (user: User, permissionHierarchy: PermissionHierarchy): Promise<any> => {
  if (!user) {
    console.error('User not provided to updateUser!');
    return;
  }

  if (!permissionHierarchy) {
    console.error('Permission hierarchy not provided to updateUser!');
    return;
  }

  try {
    const authToken = 'faketoken';
    const {permissionNodes, permissionValues} = packPermissionsObjToD3Permissions(permissionHierarchy, combinePermissions(user.role?.permissions ?? {}, user.permissionOverrides));
    console.log("Before request");
    const response = await fetchFromApi('users/edit', {
      authToken: authToken,
      userKey: user.userId,
      username: user.username,
      email: user.userEmailAddress,
      pronouns: user.pronouns,
      profileImage: user.profileImage,
      roleKey: user.role?.userRoleId ?? undefined,
      purchaseOrderLimit: user.orderLimits?.purchase ?? undefined,
      salesOrderLimit: user.orderLimits?.sales ?? undefined,
      quoteLimit: user.orderLimits?.quote ?? undefined,
      invoiceLimit: user.orderLimits?.invoice ?? undefined,
      superUser: user.isSuperUser,
      permissionNodes: permissionNodes,
      permissionValues: permissionValues
    });
    console.log("After request");
    return response.d3Response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createNewRole = async (role: UserRole, permissionHierarchy: PermissionHierarchy) => {
  if (!role) {
    console.error('Role not provided to createNewRole!');
    return;
  }

  if (!permissionHierarchy) {
    console.error('Permission hierarchy not provided to createNewRole!');
    return;
  }

  try {
    const authToken = 'faketoken';
    const {permissionNodes, permissionValues} = packPermissionsObjToD3Permissions(permissionHierarchy, role.permissions)
    const response = await fetchFromApi('roles/new', {
      authToken: authToken,
      roleName: role.name,
      description: role.description,
      businessArea: role.businessArea,
      permissionNodes: permissionNodes,
      permissionValues: permissionValues
    });
  } catch (error) {
      console.error(error);
  }
}

export const updateRole = async (role: UserRole, permissionHierarchy: PermissionHierarchy) => {
  if (!role) {
    console.error('Role not provided to createNewRole!');
    return;
  }

  if (!permissionHierarchy) {
    console.error('Permission hierarchy not provided to createNewRole!');
    return;
  }

  try {
    const authToken = 'faketoken';
    const {permissionNodes, permissionValues} = packPermissionsObjToD3Permissions(permissionHierarchy, role.permissions)
    const response = await fetchFromApi('roles/update', {
      authToken: authToken,
      key: role.userRoleId,
      roleName: role.name,
      description: role.description,
      businessArea: role.businessArea,
      permissionNodes: permissionNodes,
      permissionValues: permissionValues
    });
  } catch (error) {
      console.error(error);
  }
}

export const attemptLogin = async (email: string, password: string) => {
  console.log('Attempting login with email:', email, 'and password:', password)

  try {
    const response = await fetchFromApi('users/login', { email, password });
    console.log(response);  

    if (!response.ExecuteCommand.Output) {
      return { success: false, message: response.ExecuteCommand.Message + "." };
    }

    if (response.ExecuteCommand.Message.includes('ERROR')) {
      return { success: false, message: response.ExecuteCommand.Message.replace("ERROR:", "") + "." };
    }

    if (response.ExecuteCommand.Message === "Disapproved") {
      return { success: false, message: "Incorrect email or password. Please try again." };
    }

    else if (response.ExecuteCommand.Message !== "Approved") {
      return { success: false, message: "There was a problem with this request. Please try again." };
    }

    return { success: true, message: response.ExecuteCommand.Message + "." };
  } catch (error) {
    console.error('There was a problem logging in:', error);
    return { success: false, message: "There was a problem with this request. Please try again."};
  }
}

export const updateUserPassword = async (userId: string, OriginalValue: any, Value: any) => {
  try {
    const response = await fetchFromApi('users/update-password', { userId, OriginalValue, Value });
    console.log(response);
    return response.ExecuteCommand;
  }
  catch (error) {
    console.error('There was a problem updating user password:', error);
  }
}