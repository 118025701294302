import React, { useRef, useEffect } from 'react';

type ProgressBarProps = {
  value: number;
  maxVal: number;
  color: string;
}

const ProgressBar = ({ value, maxVal, color }: ProgressBarProps) => {
  
  const percentageFill = (value / maxVal) * 100;

  const progressRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.setProperty('--progress-width', `${percentageFill}%`);
      progressRef.current.style.backgroundColor = color;
    }
  }, [percentageFill, color]);

  return (
    <div className="stat-progress-bar" data-testid='progress-outer-div'>
      <div ref={progressRef} className="progress" data-testid='progress-div'/>
    </div>
  )
}

export default ProgressBar;