import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useKeyPress } from "../utils";
import { useScreen } from '../context';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { BasketItem, PurchaseOrder } from '../types';

import { fetchPurchaseOrder, fetchBulkProducts, createPurchaseOrder, updatePurchaseOrder } from '../api';

import PurchaseOrderTemplate from '../components/pdfs/templates/purchaseOrder';
import OrderAddressCard from '../components/widgets/orderAddressHandler';
import CustomerAndSupplierNotes from '../components/widgets/newNoteModal';
import ModalWithChildren from '../components/modalWithChildren';

import { Resizable } from 're-resizable';

import {  
  calculatePurchaseOrderBasketTotals, 
  formatBasketForD3,
  valueIsNullOrZero,
  getExtension
} from '../components/basketUtils';

import ProductHistoryTooltip from '../components/productHistoryTooltip';
import SupplierSearchModal from '../components/suppliersAndCustomersSearchModal';
import ProductsSearchModal from '../components/productsSearchModal';

import { getDateInD3Format, getTimeInD3Format, unwrapD3DateToISO } from '../utils';

const NewPurchase = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const [basket, setBasket] = useState([] as BasketItem[]);
  const navigate = useNavigate();

  const [deliveryLocation, setDeliveryLocation] = useState("" as string | string[]);
  const [creatingNewLocation, setCreatingNewLocation] = useState(false);
  const branches = ["Bournemouth Branch", "Hull Branch", "Sheffield Branch"];

  const [fetching, setFetching] = useState(true);

  const [supplierData, setSupplierData] = useState(null as any);
  const [basketStats, setBasketStats] = useState({ totalQuoted: 0, vat: 0 });
  const [activeBasketItem, setActiveBasketItem] = useState(null as null | string);
  const [suppliersModalOpen, setSuppliersModalOpen] = useState(false);
  const [productsModalOpen, setProductsModalOpen] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);

  const [orderIdForUpdate, setOrderIdForUpdate] = useState("");

  const [deliveryFee, setDeliveryFee] = useState(null as null | string);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [goodsInModal, setGoodsInModal] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);

  const [orderData, setOrderData] = useState({} as PurchaseOrder);
  const [originalOrderData, setOriginalOrderData] = useState({} as PurchaseOrder);

  const { setScreenId, setPageHasDirtyForm, pageHasDirtyForm, addNotification } = useScreen();
  setScreenId(`PO-${orderId || "NEW"}`)
  
  const clearEQSI = () => {
    setSupplierData(null);
    setBasket([]);
    setBasketStats({ totalQuoted: 0, vat: 0 });
    setActiveBasketItem(null);
  }

  const backAction = () => {

  }
  useKeyPress('F1', backAction);

  const upAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item: BasketItem) => item.product["PRODUCT.CODE"] === activeBasketItem);
      if (indexOfItem !== 0) setActiveBasketItem(basket[indexOfItem - 1].product["PRODUCT.CODE"]);
    }
  }
  useKeyPress('ArrowUp', upAction);

  useKeyPress('Escape', () => {
    if (productsModalOpen) setProductsModalOpen(false);
    if (suppliersModalOpen) setSuppliersModalOpen(false);
  });

  const downAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item: BasketItem) => item.product["PRODUCT.CODE"] === activeBasketItem);
      if (indexOfItem !== basket.length - 1) setActiveBasketItem(basket[indexOfItem + 1].product["PRODUCT.CODE"]);
    }
  }
  useKeyPress('ArrowDown', downAction);

  useEffect(() => {
    async function fetchOrderData() {
      if (orderId === "NEW" || !orderId) {
        setFetching(false);
        return;
      }

      let fetchedOrderData = await fetchPurchaseOrder(orderId);
      if  (!fetchedOrderData || fetchedOrderData.length === 0) return;

      let order = fetchedOrderData[0]
      setOrderData(order)
      setOriginalOrderData(order)
      
      setDeliveryFee(parseFloat(order["CARRIAGE.FEE"]).toFixed(2));
      setExpectedDeliveryDate(unwrapD3DateToISO(order["DELIVERY.DATE"]));
      setSupplierData({ ACCNO: order["SUPPLIER.CODE"], NAME: order["SU.NAME"] });
      setDeliveryLocation(order["DELIVER.TO"]);
      setOrderIdForUpdate(order.KEY);
      console.log(order);

      typeof order["PRODUCT.CODE"] === "string" && (order["PRODUCT.CODE"] = [order["PRODUCT.CODE"]]);
      typeof order["ORDER.QUANTITY"] === "string" && (order["ORDER.QUANTITY"] = [order["ORDER.QUANTITY"]]);
      (typeof order["PRICE"] === "string" || typeof order["PRICE"] === "number") && (order["PRICE"] = [order["PRICE"]]);

      if (order["PRODUCT.CODE"] && order["PRODUCT.CODE"].length > 0) {
        let products = await fetchBulkProducts(order["PRODUCT.CODE"]);
        
        console.log("Products", products, order);
        if (products.length !== order["PRODUCT.CODE"].length) return;
        let basketData = order["PRODUCT.CODE"].map((productCode: string, index: number) => {
          return {
            product: products[index],
            quantity: parseInt(order["ORDER.QUANTITY"][index]),
            basketPrice: parseFloat(order["PRICE"][index]),
            basketPriceString: parseFloat(order["PRICE"][index]).toFixed(2),
            tax: 0
          }
        });

        console.log(basketData)
        setBasket(basketData);
        console.log(products);
      }

      setFetching(false);
    }

    fetchOrderData();
  }, [orderId]);

  useEffect(() => {
    console.log("Basket changed", basket);
    if (basket.length == 0) {
      setBasketStats({ totalQuoted: 0, vat: 0 });
      return
    }

    setBasketStats(calculatePurchaseOrderBasketTotals(basket));
  }, [basket]);

  useEffect(() => {
    if (!productsModalOpen) {
      let lastItemsQuantityInput = document.getElementById(`quantity-${basket.length - 1}`) as HTMLInputElement;
      if (lastItemsQuantityInput) lastItemsQuantityInput.focus();
    }
  }, [productsModalOpen]);

  const attemptCreatePurchaseOrder = async (status: "DR" | "OR") => {
    if (!supplierData || !deliveryLocation  || basket.length === 0) return;

    console.log("Creating Purchase Order...");
    const formattedBasket = await formatBasketForD3(basket);
    let currentDateTime = new Date();

    const orderPayload = {
      "ORDER.DATE": getDateInD3Format(currentDateTime),
      "ORDER.TIME": getTimeInD3Format(currentDateTime),
      "DELIVER.TO": deliveryLocation,
      "DELIVERY.DATE": expectedDeliveryDate,
      "SUPPLIER.CODE": supplierData.ACCNO,
      "ORDER.QUANTITY": formattedBasket.productQuantities,
      "PRODUCT.CODE": formattedBasket.productCodes,
      "PRICE": formattedBasket.productPrices,
      "PO.COMMENTS": orderData["PO.COMMENTS"] || "Temp comment",
      "CARRIAGE.FEE": deliveryFee || "0.00",
      "PO.STATUS": status,
    } as PurchaseOrder;

    const response = await createPurchaseOrder(orderPayload);
    console.log(response);

    if (!response.Success.includes("Error")) {
      navigate(-1);
      return;
    }

    addNotification(response.message, "error");
  }

  const attemptUpdatePurchaseOrder = async (status: "DR" | "OR" | "PR" | "FR") => {
    if (!supplierData || !deliveryLocation  || basket.length === 0 || !orderId || !orderIdForUpdate) return;
    console.log("Creating Purchase Order...");
    const formattedBasket = await formatBasketForD3(basket);

    const originalValuePayload = {
      "DELIVER.TO": originalOrderData["DELIVER.TO"],
      "DELIVERY.DATE": originalOrderData["DELIVERY.DATE"],
      "ORDER.QUANTITY": originalOrderData["ORDER.QUANTITY"],
      "PRODUCT.CODE": originalOrderData["PRODUCT.CODE"],
      "PRICE": originalOrderData["PRICE"],
      "PO.COMMENTS": originalOrderData["PO.COMMENTS"],
      "CARRIAGE.FEE": originalOrderData["CARRIAGE.FEE"],
      "PO.STATUS": originalOrderData["PO.STATUS"],
    }

    const newValuePayload = {
      "DELIVER.TO": deliveryLocation,
      "DELIVERY.DATE": expectedDeliveryDate.includes("-") ? getDateInD3Format(new Date(expectedDeliveryDate)) : expectedDeliveryDate,
      "ORDER.QUANTITY": formattedBasket.productQuantities,
      "PRODUCT.CODE": formattedBasket.productCodes,
      "PRICE": formattedBasket.productPrices,
      "PO.COMMENTS": orderData["PO.COMMENTS"] || "Temp comment",
      "CARRIAGE.FEE": deliveryFee || "0.00",
      "PO.STATUS": status,
    } as any;

    const response = await updatePurchaseOrder(orderIdForUpdate, originalValuePayload, newValuePayload); 
    console.log(response.Success);

    if (!response.Success.includes("Error")) {
      navigate(-1);
      return;
    }
  
    addNotification(response.message, "error");
  }

  const handleKeyDownForBasketInputs = (key: string, index: number, inputKey: string) => {
    if (key === "ArrowDown") {
      let nextInput = document.getElementById(`${inputKey}-${index + 1}`) as HTMLInputElement;
      if (nextInput) nextInput.focus();
      else {
        let addProductButton = document.getElementById("addProductButton");
        if (addProductButton) addProductButton.focus();
      }
    }
    if (key === "ArrowUp") {
      let prevInput = document.getElementById(`${inputKey}-${index - 1}`) as HTMLInputElement;
      if (prevInput) prevInput.focus();
    }
    if (key === "Enter" || key === "Tab") {
      console.log(key, inputKey, index);
      if (inputKey !== "discount") {
        let nextInputKey = "";
        switch (inputKey) {
          case "quantity":
            nextInputKey = "price";
            break;
          case "price":
            nextInputKey = "per";
            break;
          case "per":
            nextInputKey = "discount";
            break;
        }
        console.log("From key", inputKey, "to key", nextInputKey);

        let nextInput = document.getElementById(`${nextInputKey}-${index}`) as HTMLInputElement;
        if (nextInput) nextInput.focus();
        else {
          let addProductButton = document.getElementById("addProductButton");
          if (addProductButton) addProductButton.focus();
        }
      }
      else {
        let nextInput = document.getElementById(`quantity-${index + 1}`) as HTMLInputElement;
        if (nextInput) nextInput.focus();
        else {
          let addProductButton = document.getElementById("addProductButton");
          if (addProductButton) addProductButton.focus();
        }
      }
    }
  }

  const handleDownload = async () => {
    if (!supplierData || !deliveryLocation || !expectedDeliveryDate || !orderId || basket.length === 0 || !deliveryFee) return;

    setPdfDownloading(true);

    const invoicePayload = {
      purchaseOrderId: orderId,
      supplierName: supplierData.NAME,
      supplierAddress: supplierData.ADDRESS || ["303 Fake Street", "Fake Town", "Fake County", "FA1 1KE"],
      deliveryAddress: typeof deliveryLocation === "string" ? deliveryLocation : deliveryLocation.join(", "),
      deliveryDate: expectedDeliveryDate,
      basket: basket,
      notes: orderData["PO.COMMENTS"],
      subtotal: basketStats.totalQuoted,
      vat: basketStats.vat,
      deliveryFee: deliveryFee,
    };

    const doc = <PurchaseOrderTemplate {...invoicePayload} />;
    const blob = await pdf(doc).toBlob();
    saveAs(blob, `purchase_order_${orderId}.pdf`);
    setPdfDownloading(false);
  };

  const setBasketViaDirtyForm = (newBasket: BasketItem[]) => {
    setPageHasDirtyForm(true);
    setBasket(newBasket);
  }

  const goBack = async () => {
    console.log("Go back");
    if (pageHasDirtyForm || JSON.stringify(orderData) !== JSON.stringify(originalOrderData)) {
      console.log(pageHasDirtyForm)
      console.log("Page has dirty form");
      setGoBackModal(true);
    }
    else {
      console.log("Page does not have dirty form");
      navigate(-1);
    }
  }

  const basketIsEditable = () => {
    return orderData['PO.STATUS'] === "DR" || orderId === "NEW";
  }

  return (
    <div className="flex flex-col items-start w-full gap-4">
      <ProductsSearchModal 
        basket={basket}
        setBasket={setBasketViaDirtyForm}
        setActiveBasketItem={setActiveBasketItem}
        activeBasketItem={activeBasketItem}
        modalIsOpen={productsModalOpen}
        setModalOpen={setProductsModalOpen}
        screen="purchase"
        supplier={supplierData ? supplierData.NAME : ""}
      />

      <SupplierSearchModal
        setData={setSupplierData}
        modalIsOpen={suppliersModalOpen}
        setModalOpen={setSuppliersModalOpen}
        dataSource='Suppliers'
      />

      {goodsInModal && 
        <ModalWithChildren modalOpen={goodsInModal} setModalOpen={setGoodsInModal}>
          <div className="">
            <div className="p-4 dashboard-card">
              <h1 className="text-2xl font-semibold">Receive Goods</h1>
              <p>Receive goods for purchase order #{orderId}</p>
            </div>

            <table className="table-auto w-full text-left mb-12">
              <tbody>
                {basket.map((item: BasketItem, index: number) => (
                  <tr key={index}>
                    <td><p className="text-sm pr-1 font-semibold">{item.product.DESCRIPTION}</p></td>
                    <td className="no-padding-override">
                      <div className="flex items-center">
                        <input 
                          type="number" 
                          className="h-14 w-36 outline-none p-4" 
                          placeholder="Enter amount..."
                        />
                        <button className="h-14 px-4 bg-blue font-semibold text-white">Receive</button>
                      </div>
                    </td>
                    <td className="no-padding-override">
                      <div className="flex items-center">
                        <input 
                          type="number" 
                          className="h-14 w-36 outline-none p-4" 
                          placeholder="Enter amount..."
                        />
                        <button className="h-14 px-4 bg-red-500 font-semibold text-white">Return</button>
                      </div>
                    </td>
                    <td>
                      <div className="h-3 w-full min-w-48 bg-gray-200">
                        <div 
                          className="h-3 bg-black"
                          style={{width: `${((0) / 100) * 100}%`}}
                        />
                      </div>
                      <div className="flex justify-between items-center mt-1">
                        <p className="text-sm">Received</p>
                        <p className="text-sm">0/{item.quantity}</p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalWithChildren>
      }

      {goBackModal && 
        <ModalWithChildren modalOpen={goBackModal} setModalOpen={setGoBackModal}>
          <div className="p-4 text-center">
            <h1 className="text-2xl font-semibold">Save Pending Changes</h1>
            <p className="mt-6">You have unsaved changes to this Purchase Order.<br/>Would you like to save before you leave this page?</p>

            <button 
              className="btn btn-primary  w-full mt-4"
              onClick={() => {
                attemptUpdatePurchaseOrder("DR");
                setGoBackModal(false);
              }}
            >
              Save Changes
            </button>

            <button 
              className="btn btn-secondary w-full mt-4"
              onClick={() => {
                setGoBackModal(false);
                navigate(-1);
              }}
            >
              Discard Changes
            </button>
          </div>
        </ModalWithChildren>
      }

      <div className="flex items-center justify-center gap-4 w-full">
        {orderId == "NEW" 
          ? <>
              <div className="p-4">
                <button onClick={() => navigate(-1)}>
                  <i className="fas fa-arrow-left mr-2"></i>Back
                </button>
                <h3>Order stock for your branch</h3>
              </div>

              <button
                className="btn btn-secondary ml-auto border-r border-white"
                onClick={() => {
                  clearEQSI();
                  window.location.href = "/purchasing";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-primary disabled:opacity-50 border-r border-white"
                onClick={() => attemptCreatePurchaseOrder("DR")}
                disabled={basket.length === 0 || !supplierData || !deliveryLocation}
              >
                Create Draft
              </button>

              <button
                className="btn btn-primary disabled:opacity-50"
                onClick={() => attemptCreatePurchaseOrder("OR")}
                disabled={basket.length === 0 || !supplierData || !deliveryLocation}
              >
                Create Purchase Order
              </button>
            </>
          : <>
            <div>
              <button onClick={() => goBack()}>
                <i className="fas fa-arrow-left mr-2"></i>Back
              </button>
              <h2 className="font-semibold text-2xl">Purchase Order {orderId}</h2>
            </div>

            <button
              className="btn btn-secondary ml-auto border-r border-white"
              onClick={() => {
                clearEQSI();
                window.location.href = "/purchasing";
              }}
            >
              Discard
            </button>

            {orderData['PO.STATUS'] !== "DR" && <>
              <button
                className="btn btn-primary disabled:opacity-50 border-r border-white"
                onClick={handleDownload}
                disabled={pdfDownloading}
              >
                <i className={`fa-solid ${pdfDownloading ? 'fa-spinner fa-spin' : 'fa-download'}`}></i>
                &nbsp;&nbsp;Download PDF
              </button>

              <button
                className="btn btn-primary disabled:opacity-50"
                onClick={() => setGoodsInModal(true)}
              >
                Receive Goods
              </button>
            </>
            }

            {orderData['PO.STATUS'] === "DR" && <>
              <button
                className="btn btn-primary"
                onClick={() => attemptUpdatePurchaseOrder(orderData['PO.STATUS'])}
                disabled={basket.length === 0 || !supplierData || !deliveryLocation}
              >
                Update
              </button>
              <button
                className="btn btn-primary"
                onClick={() => attemptUpdatePurchaseOrder("OR")}
                disabled={basket.length === 0 || !supplierData || !deliveryLocation}
              >
                Send PO
              </button>
              </>
            }
          </>}
      </div>

      <div className="grid md:grid-cols-2 w-full gap-4">
        <div className="dashboard-card">
          <div className="card-header bg-blue">
            <i className="fas fa-user"></i>
            <h3 className="font-semibold uppercase text-sm">Supplier</h3>
          </div>

          <div className="p-4">
            <div className={`flex gap-2 items-center ${orderId === "NEW" && "cursor-pointer"}`} onClick={() => {
              orderId === "NEW" && setSuppliersModalOpen(true)
            }}>
              {supplierData
                ? <h2 className="font-light text-xl md:text-2xl">{supplierData.NAME}</h2>
                : <h2 className="font-semibold text-xl">Select a supplier</h2>
              }
              {orderId === "NEW" && <i className="fas fa-chevron-down"></i>}
            </div>
          </div>
        </div>

        <div className="dashboard-card">
          <div className="card-header bg-blue">
            <i className="fas fa-map-location"></i>
            <h3 className="font-semibold uppercase text-sm">Branch</h3>
          </div>

          <div className="p-4">
            <select 
              className="input text-xl text-light outline-none"
              value={deliveryLocation}
              onChange={(e) => setDeliveryLocation(e.target.value)}
              disabled={!basketIsEditable()}
            >
              <option value="" disabled>Select a branch</option>
              {branches.map((branch: string, index: number) => (
                <option key={index} value={branch}>{branch}</option>
              ))}
            </select>
          </div>

        </div>
      </div>

      {fetching 
        ? <div 
          className="flex justify-center items-center flex-col gap-4 w-full"
          style={{height: "calc(100vh - 200px)"}}
        >
          <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl md:text-4xl"></i>
          <h2 className="text-2xl">Fetching purchase order...</h2>
        </div>

        : <div className="flex flex-col md:flex-row w-full items-start gap-4">
            <div className="flex-grow dashboard-card overflow-show-override">
              <div className="">
                <table className="w-full text-left table-auto">
                  <thead>
                    <tr className="table-blue">
                        {["Quantity", "Product", "Cost", "VAT", "Extension", ""].map((header: string, index: number) => (
                        <th
                          key={index}
                          className="px-1 py-4 text-sm"
                        >
                          <Resizable
                          enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                          minWidth={50}
                          className={`flex items-center px-2 w-full justify-center`}
                          >
                            <p className="shrink-0">{header}</p>
                          </Resizable>
                        </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {basket.map((item: BasketItem, index: number) => (
                      <tr 
                        key={index} 
                        onClick={() => setActiveBasketItem(item.product["PRODUCT.CODE"])}
                      >
                        <td className="max-w-12 px-2">
                          <input
                            type="number"
                            value={item.quantity || undefined}
                            id={`quantity-${index}`}
                            className="w-full text-center outline-none bg-gray-100 rounded-md border border-gray-300"
                            onChange={(e) => {
                              const updatedBasket = [...basket];
                              updatedBasket[index].quantity = parseInt(e.target.value);
                              setBasketViaDirtyForm(updatedBasket);
                            }}
                            onBlur={(e) => {
                              if (valueIsNullOrZero(e.target.value)) {
                                const updatedBasket = [...basket];
                                updatedBasket[index].quantity = 1;
                                setBasketViaDirtyForm(updatedBasket);
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Tab") e.preventDefault()
                              handleKeyDownForBasketInputs(e.key, index, "quantity")}
                            }
                            disabled={!basketIsEditable()}
                          />
                        </td>
                        <td className="px-2">
                          <p className="text-sm font-semibold text-left-override">{item.product.DESCRIPTION}</p>
                        </td>
                        <td className="text-sm text-center px-2">
                          <input 
                            type="text"
                            step="0.01"
                            value={item.basketPriceString}
                            id={`price-${index}`}
                            onChange={(e) => {
                              const updatedBasket = [...basket];
                              updatedBasket[index].basketPrice = parseFloat(e.target.value);
                              updatedBasket[index].basketPriceString = e.target.value;
                              setBasketViaDirtyForm(updatedBasket);
                            }}
                            onBlur={(e) => {
                              const updatedBasket = [...basket];
                              if (valueIsNullOrZero(e.target.value)) {
                                updatedBasket[index].basketPriceString = "1.00";
                                updatedBasket[index].basketPrice = 1;
                                setBasketViaDirtyForm(updatedBasket);
                              }
                              else updatedBasket[index].basketPriceString = parseFloat(e.target.value).toFixed(2);
                              setBasketViaDirtyForm(updatedBasket);
                            }}
                            className="w-full text-center outline-none bg-gray-100 rounded-md border border-gray-300 max-w-24"
                            onKeyDown={(e) => {
                              if (e.key === "Tab") e.preventDefault()
                              handleKeyDownForBasketInputs(e.key, index, "price")}
                            }
                            disabled={!basketIsEditable()}
                          />
                        </td>
                        <td className="text-sm text-center">
                          {item.product["VAT.CODE"] === "1" 
                            ? <p>£{((item.product["VAT.CODE"] === "1" ? item.basketPrice * 0.2 : 0) * (item.quantity || 0)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            : <p className="text-center">Exempt</p>
                          }
                          
                        </td>
                        <td className="text-sm text-center">
                          £{getExtension(item).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </td>
                        <td className="flex justify-center"><ProductHistoryTooltip product={item.product} source="purchase" /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {(orderData['PO.STATUS'] == "DR"  || orderId === "NEW") && supplierData && 
                  <div className="p-4">
                    <button 
                      className="btn btn-primary w-full flex items-center justify-center uppercase font-extrabold hover:bg-black focus:bg-black"
                      onClick={() => setProductsModalOpen(true)}
                      id="addProductButton"
                    >
                      <i className="fas fa-plus mr-2"></i>
                      Add Product
                    </button>
                  </div>
                }
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <OrderAddressCard
                address={deliveryLocation}
                setAddress={setDeliveryLocation}
                theme='blue'
                saveNewLocation={creatingNewLocation}
                setSaveNewLocation={setCreatingNewLocation}
              />
              <div className="dashboard-card">
                <div className="card-header bg-blue">
                  <i className="fas fa-calculator"></i>
                  <h3 className="font-semibold uppercase text-sm">Order Summary</h3>
                </div>
                
                <div className="p-4">
                  <div className="flex justify-between items-center gap-2">
                    <p>Subtotal:</p>
                    <p>£{basketStats.totalQuoted.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  </div>
                  <div className="flex justify-between items-center gap-2">
                    <p>VAT (20%):</p>
                    <p>£{(basketStats.vat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  </div>
    
                  <div className="flex justify-between items-center gap-2 mt-8">
                    <p>Est. Delivery:</p>
                    <input 
                      type="date" 
                      className="input outline-none border border-gray-300 text-right pr-2 rounded-md" 
                      value={expectedDeliveryDate ? unwrapD3DateToISO(expectedDeliveryDate) : ""}
                      onChange={(e) => {
                        setPageHasDirtyForm(true);
                        setExpectedDeliveryDate(getDateInD3Format(new Date(e.target.value)))
                      }}
                    />
                  </div>
                  <div className="flex justify-between items-center gap-2 mt-2">
                    <p>Carriage/Shipping Fee:</p>
                    <div className="flex rounded border border-gray-300 bg-white items-center justify-start">
                      <div className="border-gray-300 border-r py-1 px-2">
                        <p className="text-sm">£</p>
                      </div>
                        <div className="px-1">
                        <input 
                          type="text"
                          className="input w-24 text-right pr-2 outline-none" 
                          value={deliveryFee || undefined}
                          onChange={(e) => setDeliveryFee(e.target.value)}
                          onBlur={(e) => {
                            let twoDecimalValue = parseFloat(e.target.value).toFixed(2);
                            setDeliveryFee(twoDecimalValue);
                          }}
                          onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            let dateInput = document.getElementById("expectedDeliveryDate") as HTMLInputElement;
                            dateInput.focus();
                            dateInput.showPicker();
                          }
                          }}
                        />
                        </div>
                    </div>
                  </div>
                  <div className="flex justify-between items-center gap-2 mt-2">
                    <p>VAT (20%):</p>
                    <p>£{(deliveryFee ? parseFloat(deliveryFee) * 0.2 : 0).toFixed(2)}</p>
                  </div>
                  {basketIsEditable() && expectedDeliveryDate && new Date(expectedDeliveryDate) < new Date() && 
                    <p className="font-bold text-red-400 text-lg">Your delivery date is in the past!</p>
                  }

                  <div className="flex justify-between items-center gap-2 mt-10">
                    <h3 className="font-semibold text-lg">Order Total:</h3>
                    <p>£{(basketStats.totalQuoted + (deliveryFee ? parseFloat(deliveryFee) * 1.2 : 0) + basketStats.vat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="dashboard-card">
                  <div className="card-header bg-blue">
                    <i className="fas fa-sticky-note"></i>
                    <h3 className="font-semibold uppercase text-sm">Order Notes</h3>
                  </div>

                  <CustomerAndSupplierNotes
                    note={orderData["PO.COMMENTS"]}
                    setNote={(note: string[]) => setOrderData({ ...orderData, "PO.COMMENTS": note }) }
                    theme='blue'
                  />
                </div>
              </div>

              <div className="dashboard-card">
                <div className="card-header bg-blue">
                  <i className="fas fa-history"></i>
                  <h3 className="font-semibold uppercase text-sm">Activity History</h3>
                </div>
                
                <div className="p-4">
                  <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created draft Purchase Order.</p>
                </div>
              </div>
            </div>
          </div>
    }
    </div>
  );
}

export default NewPurchase;