import { useState } from "react";

type CollapsibleTextProps = {
    text: string, 
    maxLength: number, 
    expandedByDefault: boolean,
    appearsBelowText?: boolean,
    textOverrideToShow?: string,
    textOverrideToHide?: string,
    className?: string
}

export const CollapsibleText = ({text, maxLength, expandedByDefault = false, appearsBelowText = false, textOverrideToShow, textOverrideToHide, className}: CollapsibleTextProps) => {
    const [expanded, setExpanded] = useState(expandedByDefault);

    function toggleExpanded() {
        const newExpanded: boolean = !expanded

        setExpanded(newExpanded);

    }

    const toggle = () => 
            <>
                {(textOverrideToShow && textOverrideToHide) && 
                    <span 
                        title={expanded ? "See less" : "See more"}
                        onClick={() => toggleExpanded()} 
                        data-testid={`collapsible-text-max-${maxLength}`}
                        className="text-gray-400 hover:cursor-pointer hover:text-gray-500 text-sm"
                    >
                        {expanded ? textOverrideToHide : textOverrideToShow}...
                    </span>
                }
                {!(textOverrideToShow || textOverrideToHide) && 
                <i title={expanded ? "See less" : "See more"}
                    className="ml-0.5 hover:cursor-pointer fa-solid fa-ellipsis text-gray-500 rounded-lg bg-[#3A5BFF26] pl-1 pr-1" 
                    onClick={() => toggleExpanded()} 
                    data-testid={`collapsible-text-max-${maxLength}`}>
                </i>}
            </>

    return (
        <span className={`flex flex-${appearsBelowText ? "col" : "row items-end"}${className ? ' ' + className : ''}`}>
            {text.slice(0, (expanded ? undefined : maxLength - 3)) + (!expanded && text.length > maxLength ? "..." : "")}
            {text.length > maxLength && toggle()}
        </span>
    );
}