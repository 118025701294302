import { useState } from "react";
import { AvailableThemes } from "../types";

type HideableContentProps = {
    children: React.ReactNode,
    contentHiddenText: string,
    contentShowingText: string,
    isButton?: boolean,
    faIconContentShowing?: string,
    faIconContentHiding?: string,
    defaultShowing?: boolean,
    className?: string,
    toggleClassName?: string,
    theme?: AvailableThemes,
    id?: string
}

const HideableContent = ({
                            children, 
                            contentHiddenText, 
                            contentShowingText, 
                            defaultShowing = false, 
                            isButton, 
                            faIconContentShowing, 
                            faIconContentHiding, 
                            className, 
                            toggleClassName, 
                            theme="green", 
                            id
                        }: HideableContentProps) => {
    const [showing, setShowing] = useState(defaultShowing);

    const icon = (): React.ReactNode => <>{faIconContentShowing && faIconContentHiding && <i className={`fa-solid ${showing ? faIconContentShowing : faIconContentHiding} mr-3`}/>}</>

    return (
        <div className={className}>
            {!isButton && <label htmlFor={id} onClick={() => setShowing(!showing)} className={toggleClassName}>{icon()}{showing ? contentShowingText : contentHiddenText}</label>}
            {isButton && <button className={`btn btn-primary bg-${theme} ${toggleClassName}`} onClick={() => setShowing(!showing)}>{icon()}{showing ? contentShowingText : contentHiddenText}</button>}
            {showing && children}
        </div>
    )
}

export default HideableContent;