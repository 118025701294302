import React, { useEffect, useState } from 'react';
import { ucFirst } from "../../utils";
import { searchProducts } from '../../api';
import { Tooltip } from 'react-tooltip'

type Supplier = {
  name: string;
  image: string;
};

type Customer = {
  name: string;
  image: string;
};

type ProductItemData = {
  "COST.PRICE":  number, 
  DESCRIPTION: string, 
  "PICT.FLAG": string, "PGROUP.DESC": string, 
  "PRODUCT.CODE": string, 
  "LIST.PRICE": number
};

type SupplierItemData = {
  supplier: Supplier;
  date: string;
  number: string;
  amount: number;
  status: string[];
};

type EqsiItemData = {
  customer: Customer;
  date: string;
  number: string;
  amount: number;
  status: string[];
};

type CustomerItemData = {
  customer: Customer;
  contactDetail: string,
  lastTraded: string,
  credit: number,
  ["Terms Level 1 & Level 2"]: string[],
};

type TermsItemData = {
  item: string,
  group: string,
  GP: number
}

type ItemData = ProductItemData | SupplierItemData | CustomerItemData | EqsiItemData | TermsItemData;

type PrimaryTableProps = {
  source: string;
  itemData: ItemData[];
  onClickEvent?: (value: string) => void;
  tabOptions: string[];
  actionButtons?: JSX.Element;
  onChangeTab?: (value: string) => void;
};

const isProductItemData = (value: any): value is ProductItemData => {
  return (value as ProductItemData)["PICT.FLAG"] !== undefined;
};

const isSupplierItemData = (value: any): value is SupplierItemData => {
  return (value as SupplierItemData).supplier !== undefined;
};

const isCustomerItemData = (value: any): value is CustomerItemData => {
  return (value as CustomerItemData).customer !== undefined;
}

const PrimaryTable = ({ source, itemData, onClickEvent, tabOptions, actionButtons, onChangeTab }: PrimaryTableProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [tab, setTab] = useState(tabOptions[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState(itemData);
  const itemsPerPage = 25;

  const formatStatus = (status: string | number, key?: number) => {
    let coreClass = 'text-xs px-3 py-2 rounded-md font-bold ';
  
    const getStatusClass = (status: string) => {
      switch (status) {
        // Yellow badges
        case 'Published':
        case 'Scheduled':
        case 'Invoiced':
        case 'Customer Terms':
          return 'text-[#F4BE5E] bg-[#F4BE5E30]';
        
        // Red badges
        case 'Low Stock':
        case 'N/A':
        case 'Unpaid':
        case 'Expired':
          return 'text-[#CC5F5F] bg-[#F8717130]';
  
        // Blue badges
        case 'Paid':
        case 'No Cust Terms':
          return 'text-[#5E81F4] bg-[#5E81F430]';
  
        // Default neutral badges
        default:
          return 'text-[#8181A5] bg-[#F5F5FA]';
      }
    };
  
    const getNumberClass = (number: number) => {
      if (number < 10) {
        return 'text-[#CC5F5F] bg-[#F8717130]'; // Red badges
      } else if (number < 20) {
        return 'text-[#5E81F4] bg-[#5E81F430]'; // Blue badges
      } else {
        return 'text-[#F4BE5E] bg-[#F4BE5E30]'; // Yellow badges
      }
    };
  
    if (typeof status === 'string') {
      coreClass += getStatusClass(status);
      return <span className={coreClass} key={key}>{status}</span>
    } else if (typeof status === 'number') {
      coreClass += getNumberClass(status);
      return <span className={coreClass} key={key}>{status}%</span>
    }
  
    ;
  };

  const formatTableItem = (key: string, value: any, item: any) => {
    if (key === 'product' && isProductItemData({ product: value })) {
      return (
        <div className="flex items-center gap-2">
          <img 
            src={`/productImages/${value.image}.png`}
            alt={value.name} 
            className="w-10 h-10 rounded-md" 
          />

          <div>
            <p className="text-[#344767] text-bold">{value.name}</p>
            <p className="text-xs">{value.brand}</p>
          </div>
        </div>
      )
    }

    if (key === '"PICT.FLAG"') {
      return (
        <div 
          className="flex items-center gap-2 hover:underline cursor-pointer"
          onClick={() => { if (onClickEvent) onClickEvent(item["PRODUCT.CODE"]) }}
        >
          <img 
            src={value}
            alt={item.DESCRIPTION} 
            className="w-10 h-10 rounded-md" 
          />

          <div>
            <p className="text-[#344767] text-bold">{item.DESCRIPTION}</p>
            <p className="text-xs">{item["PGROUP.DESC"]}</p>
          </div>
        </div>
      )
    }

    if ((key === 'supplier' && isSupplierItemData({ supplier: value })) || (key === 'customer' && isCustomerItemData({ customer: value }))) {
      return (
        <div 
          className="flex items-center gap-2 hover:underline cursor-pointer"
          onClick={() => { if (onClickEvent) onClickEvent(value.name) }}
        >
          <img 
            src={`/${key}Images/${value.image}.png`}
            alt={value.name} 
            className="w-10 h-10 rounded-md" 
          />
          <div>
            <p className="text-[#344767] text-bold">{value.name}</p>
          </div>
        </div>
      )
    }

    if (key === 'Terms Discount') {
      if (value > 0) {
        return (
        <div className="flex justify-center">
          <div className="rounded-md bg-orange-400 bg-opacity-60 text-black text-xs p-1 px-2 items-center flex justify-center">
            {(value * 100).toFixed(0)}%
          </div>
        </div>
        )
      }
      else return null;
    }

    if (key === 'Previously Purchased') {
      return value
        ? <div className="flex justify-center">
            <i className="fa-sharp fa-solid fa-circle-check text-green-500 text-xl"></i>
          </div>
        : null;
    }


    if ((key === 'status' && typeof value === "object") || key === 'Terms Level 1 & Level 2') {
      return (
        <div className="flex items-center gap-2">
          {value.map((status: string, index: number) => (
            formatStatus(status, index)
          ))}
        </div>
      );
    }

    if (key === 'status' || key === 'GP') {
      return formatStatus(value);
    }
    
    if (key === 'actionButton') {
      return value;
    }

    return <span className={`${key === 'Product Code' ? "text-[#3A5BFF] font-bold" : ""}`}>
      {key === 'price' || key === 'amount' || key === 'LIST.PRICE' || key === 'Cost Price' ? '£' : ''}
      {key === 'Product Code' ? value : value.toLocaleString()}
    </span>
  }

  function changeTab(tabName: string) {
    if (tabOptions.includes(tabName)) {
      setTab(tabName);
      if (onChangeTab) onChangeTab(tabName);
    }
  }

  useEffect(() => {
    const executeSearch = async () => {
      const locallyFilteredItems = itemData.filter((item) => {
        return Object.values(item).some((value) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(searchTerm.toLowerCase());
          }
          else if (typeof value === 'object') {
            return Object.values(value).some((nestedValue) => {
              return nestedValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
            });
          }
          return false;
        });
      });

      let apiFilteredItems: ItemData[] = 
        (searchTerm.length > 2 && source === "Products")
          ? await searchProducts(searchTerm)
          : [];

      // Create a combined array where items only appear once
      const combinedItems = Array.from(new Set([...locallyFilteredItems, ...apiFilteredItems]));
      setFilteredAndSearchedData(combinedItems);
    }

    executeSearch();
  }, [searchTerm, itemData]);

  const exportDataAsCSV = () => {
    if (filteredAndSearchedData.length === 0) return;

    console.log("Filtered and searched data:", filteredAndSearchedData);

    let csvHeaders = [];
    let csvData = [];

    for (let i = 0; i < filteredAndSearchedData.length; i++) {
      if (i === 0) {
        const item = filteredAndSearchedData[i];
        const itemKeys = Object.keys(item).filter(value => !fieldsToIgnore.includes(value.toString()));
        csvHeaders.push(itemKeys);
      }

      const row = Object.values(filteredAndSearchedData[i]);
      let values = [];
      for (let j = 0; j < row.length; j++) {
        if (typeof row[j] === 'object') {
          console.log("Object values:", Object.values(row[j]));
          values.push(Object.values(row[j])[0]);
        }
        else {
          let columnHeader = csvHeaders[0][j];
          if (!fieldsToIgnore.includes(columnHeader) && j < csvHeaders[0].length) values.push(row[j]);
        }
      }
      csvData.push(values);
    }

    console.log("CSV Headers:", csvHeaders);
    console.log("CSV Data:", csvData);
  
    const headers = Object.keys(filteredAndSearchedData[0]).filter(value => !fieldsToIgnore.includes(value.toString())).join(',');
    const csv = [
      csvHeaders,
      ...csvData.map(row => row.join(','))
    ].join('\n');
  
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${source}${searchTerm ? `-${searchTerm}` : ""}.csv`;
    a.click();
  }

  const fieldsToIgnore = ['Prod Group', 'DESCRIPTION', "_id", "id", "Supplier's pc", "BOURNEMOUTH", "SHEFFIELD", "HULL"];

  return (
    <>
      <div className="flex items-center gap-4 min-h-[50px] flex-wrap">
        {actionButtons}

        <div className="flex gap-1 dashboard-card bg-base-100 p-1 shrink-0">
          {tabOptions.map((option, index) => (
            <button 
              key={index} 
              onClick={() => changeTab(option)} 
            className={`btn hover:bg-[#3A5BFF26] hover:text-[#3A5BFF] ${tab === option ? 'btn-secondary btn-no-focus' : 'text-[#667085] focus:border-2 focus:border-[#667085]'}`}
            >
              {option}
            </button>
          ))}
        </div>

        <button className="dashboard-card bg-base-100 flex items-center gap-1 p-2 px-3 h-[50px] text-[#667085] ml-auto">
          <i className="fa-solid fa-filter-list"></i><span className="hidden md:block">&nbsp;&nbsp;Filters</span>
        </button>

        <Tooltip content="Download product data as a .csv file.">
          <button 
            className="btn btn-secondary h-full"
            onClick={exportDataAsCSV}
          >
            <i className="fa-solid fa-download"></i><span className="hidden md:block">&nbsp;&nbsp;Export</span>
          </button>
        </Tooltip>

        

        <div className="search-bar h-[50px] dashboard-card bg-base-100 px-4 flex-grow">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="dashboard-card bg-base-100 p-4 h-full ">
        <table className="table-auto w-full">
          <thead className="sticky top-0 bg-white">
            <tr>
              {Object.keys(itemData[0])
                .filter(header => !fieldsToIgnore.includes(header))
                .map((header, index) => (
                  <th key={index} className="text-left pl-2">{ucFirst(header.replace("Object", ""))}{header === "GP" ? "%" : ""}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredAndSearchedData.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage).map((item, rowIndex) => (
              <tr key={rowIndex}>
                {Object.entries(item)
                  .filter(([key]) => !fieldsToIgnore.includes(key))
                  .map(([key, value], cellIndex) => (
                  <td key={cellIndex} className="text-left text-sm">
                    {formatTableItem(key, value, item)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex items-center mt-4 gap-4">
          <div className="flex gap-2">
            <button 
              className="btn btn-secondary"
              disabled={pageNumber === 1}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <button 
              className="btn btn-secondary"
              disabled={pageNumber * itemsPerPage >= filteredAndSearchedData.length}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>
          <p className="text-[#667085] text-xs">Showing {pageNumber * itemsPerPage - itemsPerPage + 1} to  {pageNumber * itemsPerPage > filteredAndSearchedData.length ? filteredAndSearchedData.length : pageNumber * itemsPerPage}. {/*of {filteredAndSearchedData.length} entries.*/}</p>
        </div>
      </div>
    </>
  )

}

export default PrimaryTable;