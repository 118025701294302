
type LabelledButtonProps = {
    buttonName: string,
    buttonLabel: string,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    buttonNameClassName?: string,
    buttonLabelClassName?: string,
    buttonNameTestId?: string,
    buttonLabelTestId?: string
}

const LabelledButton = ({
                            buttonName, 
                            buttonLabel, 
                            onClick, 
                            buttonNameClassName = "", 
                            buttonLabelClassName = "", 
                            buttonNameTestId = (buttonName.toLowerCase().replace(" ", "-").trim()) + "-name", 
                            buttonLabelTestId = (buttonLabel.toLowerCase().replace(" ", "-").trim()) + "-label"
                        } : LabelledButtonProps) => {
    const clicked: React.MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) onClick(event);
    }

    return (
        <button type="button" title={buttonName} onClick={clicked} className="hover:bg-[#5E81F4]/10">
            <div className="flex flex-col px-5 py-4 w-full">
                <span className={(`font-bold text-[#1C1D21] ${buttonNameClassName}`).trim()} data-testid={buttonNameTestId}>{buttonName}</span>
                <span className={(`text-[#8181A5] text-sm shrink-0 ${buttonLabelClassName}`).trim()} data-testid={buttonLabelTestId}>{buttonLabel}</span>
            </div>
        </button>
    )
}

export default LabelledButton;