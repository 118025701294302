import React, { useState, useEffect } from 'react';
import { useScreen } from '../context';
import { useParams, useNavigate } from 'react-router-dom';
import { Product } from '../types';
import BackButton from '../components/backLink';

import { fetchProduct, updateProduct, fetchProductCategories } from '../api';
import { formatChangedDataForWrite } from "../utils";
import PageTabs from '../components/pageTabs';

import ProductsSearchModal from '../components/productsSearchModal';
import MarkdownEditor from '../components/markdownEditor';

const ProductCard = () => {
  // Get product Id and back value from URL which will be /products/:id?back=/
  const { id: productId } = useParams<{ id: string }>();
  const tabOptions = ["Overview", "Images", "Documents", "Shipping", "FIFO", "Stock Level Management", "Terms"];
  const [tab, setTab] = useState(tabOptions[0]);
  const creatingNewProduct = productId === "newProd";
  const [fetchingData, setFetchingData] = useState(creatingNewProduct ? false : true);
  const [productData, setProductData] = useState({} as Product);
  const [originalProductData, setOriginalProductData] = useState({} as Product);
  const [showExhaustedBands, setShowExhaustedBands] = useState(false);
  const [productSearchModalOpen, setProductSearchModalOpen] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([] as Product[]);
  const [uniqueProductCategories, setUniqueProductCategories] = useState([] as string[]);
  const [termsDiscounts, setTermsDiscounts] = useState<{ [key: string]: number }>({ "Gold": 0, "Silver": 0, "Bronze": 0 });
  
  const { setScreenId, setPageHasDirtyForm, addNotification } = useScreen();
  setScreenId(`PRODUCT ${productId}`);

  useEffect(() => {
    if (!productId) return;

    const fetchData = async () => {
      try {
        const productData = await fetchProduct(productId);
        const categories = await fetchProductCategories();

        if (!categories || !productData || productData.length === 0) {
          console.error('There was a problem fetching product categories');
          return;
        }

        console.log("PRODUCT DATA", productData);
        setUniqueProductCategories(categories);
        setOriginalProductData(productData[0]);
        setProductData(productData[0]);
        setFetchingData(false);
      } catch (error) {
        setFetchingData(false);
        console.error('There was a problem fetching accounts:', error);
      }
    };

    fetchData();
    //deleteLocalProducts();
  }, [productId, creatingNewProduct]);

  const inputField = (label: string, productDataKey: keyof Product) => <>
    <div className="label">{label}</div>
    <input 
      type="text" 
      className="input outline-none" 
      placeholder={`${label}...`} 
      value={productData[productDataKey] as string | number}
      onChange={(e) => {
        setPageHasDirtyForm(true);
        setProductData({...productData, [productDataKey]: e.target.value})
      }}
    />
  </>;

  const attemptUpdateProduct = async () => {
    try {
      console.log("Updating product...");
      if (!productId) return;

      let formattedValues = formatChangedDataForWrite(originalProductData, productData);
      if (!formattedValues) {
        addNotification("No changes detected.", "error");
        return;
      }

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ////////////////// THIS IS A BANDAID AND NEEDS REMOVING LATER ONCE DESCRIPTION IS FIXED ///////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (formattedValues.originalPayload["WDESCRIPTION"]) {
        delete formattedValues.originalPayload["WDESCRIPTION"];
        delete formattedValues.updatedPayload["WDESCRIPTION"];
      }
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      const response = await updateProduct(productId, formattedValues.originalPayload, formattedValues.updatedPayload);
      console.log(response);
      if (!response || response.Success.includes("Error")) {
        addNotification(response.Message, "error");
        return;
      }
      console.log(response);
      addNotification("Product has been successfully updated.", "success");
    } catch (error) {
      addNotification("There was a problem updating product. Please try again.", "error");
      console.error('There was a problem updating product:', error);
    }
  }

  return (
    <div className="flex-grow flex flex-col items-start gap-4">
      {productSearchModalOpen && 
        <ProductsSearchModal 
          basket={[]}
          setBasket={() => {}}
          setActiveBasketItem={() => {}}
          activeBasketItem={null}
          modalIsOpen={productSearchModalOpen}
          setModalOpen={setProductSearchModalOpen}
          setRelatedProducts={setRelatedProducts}
          relatedProducts={relatedProducts}
          screen="related"
        />
      }


      <div className="flex items-center justify-between gap-4 w-full">
        <div>
          <BackButton />

          <h2 className="font-semibold text-2xl">
            Product {productId}
          </h2>
        </div>

        <button className="btn btn-primary" onClick={attemptUpdateProduct}>Save</button>
      </div>
      
      {fetchingData &&  (
        <div className="flex flex-col justify-center items-center w-full md:min-h-[90vh]">
          <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-4xl"></i>
          <h3 className="font-semibold text-lg md:text-2xl ml-2">Fetching data...</h3>
        </div>
      )}

      {!fetchingData && (
      
        (productData || creatingNewProduct)
        ? <div className="dashboard-card flex-grow w-full">
            <div className="flex px-4 pt-3 border-b border-gray-300 gap-2 bg-blue rounded-t-md">
              {tabOptions.map((option, index) => (
                <button 
                  key={index} 
                  className={`px-2 pb-1 font-bold border-b-4 text-white ${tab === option ? 'border-white' : 'border-primary'}`} 
                  onClick={() => setTab(option)}
                >
                  {option}
                </button>
              ))}
            </div>

            <div className="p-4">
              {tab === "Overview" && 
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <div className="form-container">
                      <div className="label">Product Code</div>
                      <input 
                        type="text" 
                        className="input outline-none" 
                        value={productId}
                        readOnly={true}
                        disabled={true}
                      />

                      {inputField("Supplier Part Code", "SUPPLIER.PC")}
                      {inputField("Product name", "DESCRIPTION")}
                      
                      <div className="label">Product Category</div>
                      <select 
                        className="input outline-none" 
                        value={productData["PGROUP.DESC"]} 
                        onChange={(e) => setProductData({...productData, "PGROUP.DESC": e.target.value})}
                      >
                        <option value="">No category...</option>
                        {uniqueProductCategories
                          .filter((category) => category !== "")
                          .map((category, index) => (
                            <option key={index} value={category}>{category}</option>
                        ))}
                      </select>

                      <div className="label">Price</div>
                      <div className="flex w-full">
                        <div className="input flex flex-grow">
                          <div className="border-gray-300 border-r pr-2 h-full flex items-center">
                            <p>£</p>
                          </div>
                          <input 
                            type="number" 
                            className="w-full outline-none h-full px-2" 
                            placeholder={`Customer's credit limit...`}
                            value={productData["LIST.PRICE"]}
                            onChange={(e) => {
                              setPageHasDirtyForm(true);
                              setProductData({...productData, "LIST.PRICE": parseInt(e.target.value)})
                            }}
                          />
                        </div>
                      </div>

                      {inputField("VAT Code", "VAT.CODE")}
                      {inputField("Supplier name", "SUPPLIER")}
                      {inputField("Contact Number", "Supplier's pc")}
                    </div>

                    <h3 className="font-semibold mt-6">Related Products</h3>
                    <div className="flex flex-col">
                      {relatedProducts.map((product, index) => (
                        <div key={index} className="flex items-center gap-4 p-4 dashboard-card">
                          <div>
                            <h3 className="text-gray-500 text-sm">{product["PRODUCT.CODE"]}</h3>
                            <h3 className="font-semibold text-sm">{product.DESCRIPTION}</h3>
                          </div>
                        </div>
                      ))}

                      <button 
                        className="btn btn-primary w-full mt-2"
                        onClick={() => setProductSearchModalOpen(true)}
                      >
                        Manage Related Products
                      </button>
                    </div>
                  </div>

                  <div>
                    <h3 className="font-semibold mb-2">Product Description</h3>

                    <MarkdownEditor 
                      content={productData.WDESCRIPTION}
                      setContent={(content: string) => setProductData({...productData, WDESCRIPTION: content})}
                    />
                  </div>
                </div>
              }

              {tab === "Images" && (
                <div className="flex w-100 gap-4 lg:gap-4 flex-grow flex-col xl:flex-row">
                  <div className="flex-grow p-4">
                    <h3 className="font-semibold">Product Images</h3>

                      {productData["PICT.FLAG"] && productData["PICT.FLAG"] !== "" && <>
                        <div className="flex gap-4 h-40 mb-6">
                        {typeof productData["PICT.FLAG"] === "string" 
                          ? <img src={productData["PICT.FLAG"]} alt="Product" className="object-cover rounded-md" />
                          : productData["PICT.FLAG"] && (productData["PICT.FLAG"] as string[]).map((image: string, index: number) => (
                              <img key={index} src={image} alt="Product" className="w-fill object-cover rounded-md" />
                            ))
                        }
                      </div>
                    </>}

                    <div className="flex gap-4 items-center p-4 mb-4 bg-gray-100 border-gray-400 rounded-md cursor-pointer" onClick={() => {
                      const fileInput = document.getElementById("file-upload");
                      if (fileInput) fileInput.click();
                    }}>
                      <i className="fa-solid fa-file-upload text-gray-600 text-2xl"></i>
                      <div className="flex-grow">
                        <div className="font-semibold text-gray-700">Product Image</div>
                        <div className="text-gray-500">Select a file to upload</div>
                      </div>
                      <input id="file-upload" type="file" className="hidden" />
                    </div>
                  </div>
                </div>
              )}

              {tab === "Documents" && (
                <div className="flex w-100 gap-4 lg:gap-4 flex-grow flex-col xl:flex-row">
                  <div className="flex-grow p-4">
                    <h3 className="font-semibold mb-4">Manage Product Documents</h3>

                    {["Warranty", "Installer Instructions", "Product Sheet"].map((label, index) => (
                      <div key={index} className="flex gap-4 items-center p-4 mb-4 bg-gray-100 border-gray-400 rounded-md cursor-pointer max-w-[600px]" onClick={() => {
                        const fileInput = document.getElementById(`file-upload-${index}`);
                        if (fileInput) fileInput.click();
                      }}>
                        <i className="fa-solid fa-file-upload text-gray-600 text-2xl"></i>
                        <div className="flex-grow">
                          <div className="font-semibold text-gray-700">{label}</div>
                          <div className="text-gray-500">Select a file to upload</div>
                        </div>
                        <input id={`file-upload-${index}`} type="file" className="hidden" />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {tab === "Shipping" && <div className="form-container">
                {inputField("Weight (kg)", "WEIGHTVALUE")}
                {inputField("Length (cm)", "LENGTH")}
                {inputField("Width (cm)", "WIDTH")}
                {inputField("Height (cm)", "HEIGHT")}
              </div>}

              {tab === "FIFO" && (
                <div className="flex w-100 gap-4 lg:gap-4 flex-grow flex-col xl:flex-row">
                  <div className="flex-grow">
                      <div className="flex justify-between items-center">
                        <h3 className="font-semibold">FIFO Stock Levels</h3>
                        <button className="btn btn-primary" onClick={() => setShowExhaustedBands(!showExhaustedBands)}>
                        {showExhaustedBands ? 'View Current FIFO Table' : 'View Exhausted FIFO Bands'}
                        </button>
                      </div>

                      {showExhaustedBands ? (
                        <table className="table-auto dashboard-card w-full mt-4">
                          <thead>
                            <tr className="table-blue">
                              <th className="px-4 text-left py-2">Date</th>
                              <th className="px-4 py-2">Type</th>
                              <th className="px-4 py-2">Customer/Supplier</th>
                              <th className="px-4 py-2">Stock Movement</th>
                              <th className="px-4 py-2">Price</th>
                            </tr>
                          </thead>
                        <tbody>
                          {[
                          { date: '2023-09-21', type: 'Sale', customerSupplier: 'Customer X', stockMovement: -50, balance: 0, price: '$145' },
                          { date: '2023-09-20', type: 'Sale', customerSupplier: 'Customer Y', stockMovement: -30, balance: 0, price: '$150' },
                          { date: '2023-09-19', type: 'Stock Adjustment', customerSupplier: 'Warehouse', stockMovement: -20, balance: 0, price: '$155' },
                          { date: '2023-09-18', type: 'Sale', customerSupplier: 'Customer Z', stockMovement: -10, balance: 0, price: '$160' },
                          { date: '2023-09-17', type: 'Sale', customerSupplier: 'Customer W', stockMovement: -40, balance: 0, price: '$165' },
                          { date: '2023-09-16', type: 'Sale', customerSupplier: 'Customer V', stockMovement: -60, balance: 0, price: '$170' },
                          { date: '2023-09-15', type: 'Sale', customerSupplier: 'Customer U', stockMovement: -70, balance: 0, price: '$145' },
                          { date: '2023-09-14', type: 'Sale', customerSupplier: 'Customer T', stockMovement: -80, balance: 0, price: '$150' },
                          { date: '2023-09-13', type: 'Sale', customerSupplier: 'Customer S', stockMovement: -90, balance: 0, price: '$155' },
                          { date: '2023-09-12', type: 'Sale', customerSupplier: 'Customer R', stockMovement: -100, balance: 0, price: '$160' }
                          ].map((entry, index) => (
                          <tr key={index}>
                          <td className="px-4 py-2">{entry.date}</td>
                          <td className="px-4 text-center py-2">{entry.type}</td>
                          <td className="px-4 text-center py-2">{entry.customerSupplier}</td>
                          <td className="px-4 text-center py-2">{entry.stockMovement > 0 ? `+${entry.stockMovement}` : entry.stockMovement}</td>
                          <td className="px-4 text-center py-2">{entry.price}</td>
                          </tr>
                          ))}
                        </tbody>
                        </table>
                      ) : (
                        <table className="table-auto border border-gray-300 w-full mt-4">
                          <thead>
                            <tr className="table-blue">
                            <th className="px-4 text-left py-2">Date</th>
                            <th className="px-4 py-2">Type</th>
                            <th className="px-4 py-2">Customer/Supplier</th>
                            <th className="px-4 py-2">Stock Movement</th>
                            <th className="px-4 py-2">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                        {[
                        { date: '2023-10-01', type: 'Purchase', customerSupplier: 'Supplier A', stockMovement: 100, balance: 100, price: '$150' },
                        { date: '2023-10-02', type: 'Sale', customerSupplier: 'Customer B', stockMovement: -20, balance: 80, price: '$162' },
                        { date: '2023-10-03', type: 'Stock Adjustment', customerSupplier: 'Warehouse', stockMovement: 10, balance: 90, price: '$160' }
                        ].reverse().map((entry, index) => (
                        <tr key={index}>
                          <td className="px-4 py-2">{entry.date}</td>
                          <td className="px-4 text-center py-2">{entry.type}</td>
                          <td className="px-4 text-center py-2">{entry.customerSupplier}</td>
                          <td className="px-4 text-center py-2">{entry.stockMovement > 0 ? `+${entry.stockMovement}` : entry.stockMovement}</td>
                          <td className="px-4 text-center py-2">{entry.price}</td>
                        </tr>
                        ))}
                        </tbody>
                        </table>
                      )}
                  </div>
                </div>
              )}

              {tab === "Stock Level Management" && 
                <div className="grid md:grid-cols-2 gap-4">
                  <div className="p-4">
                    <h3 className="font-semibold">Stock Level Management</h3>

                    <div className="form-container mt-4">
                      <h3 className="font-semibold">Do Not Reorder/Stock to Clear</h3>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={productData.REORDER === "0"}
                        onChange={(e) => setProductData({...productData, REORDER: e.target.checked ? "0" : "1"})}
                      />
                    </div>
                  </div>

                  <table className="table-auto w-full dashboard-card border-l border-gray-300">
                    <thead>
                      <tr className="table-blue">
                        <th className="px-4 py-2">Location</th>
                        <th className="px-4 py-2">Available Stock</th>
                      </tr>
                    </thead>
                    <tbody>
                      {["SHEFFIELD", "HULL", "BOURNEMOUTH"].map((warehouse, index) => (
                        <tr key={index}>
                          <td>{warehouse}</td>
                          <td>{productData[warehouse as keyof Product] as number}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              }

              {tab === "Terms" && <>
                <table className="table-auto w-full dashboard-card">
                  <thead>
                    <tr className="table-blue">
                      <th className="px-4 text-left py-2 w-auto">Terms Group</th>
                      <th className="px-4 py-2">Price</th>
                      <th className="px-4 py-2">Discount</th>
                      <th className="px-4 py-2">FIFO Cost</th>
                      <th className="px-4 py-2">GP %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {["Gold", "Silver", "Bronze"].map((term, index) => (
                      <tr key={index}>
                        <td>{term}</td>
                        <td>
                          <p className="text-center">
                            {(productData["LIST.PRICE"] * (1 - termsDiscounts[term] / 100)).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
                          </p>
                        </td>
                        <td>
                          <div className="flex border border-gray-400 rounded-md justify-end py-1 max-w-24 mx-auto">
                            <input 
                              type="number" 
                              className="input text-right max-w-14 outline-none pr-2" 
                              placeholder="Discount..." 
                              value={termsDiscounts[term]}
                              onChange={(e) => setTermsDiscounts({...termsDiscounts, [term]: Number(e.target.value)})}
                            />
                            <span className="pr-2">%</span>
                          </div>
                        </td>
                        <td>
                          <p className="text-center">
                            {productData["COST.PRICE"].toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })}
                          </p>
                        </td>
                        <td>
                          <p className="text-center">
                            {(((productData["LIST.PRICE"] * (1 - termsDiscounts[term] / 100)) - productData["COST.PRICE"]) / productData["COST.PRICE"] * 100).toFixed(2)}%
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>}
            </div>
          </div>
        :  <div className="flex flex-col justify-center items-center flex-grow">
            <i className="fa-sharp fa-solid fa-circle-exclamation text-4xl"></i>
            <h3 className="font-semibold text-lg md:text-2xl ml-2">Couldn't fetch data...</h3>
          </div>
      )}
    </div>
  );
}

export default ProductCard;