import { NonEmptyObject } from "../../types";
import { Tooltip } from 'react-tooltip'

type ExportCSVButtonProps = {
    dataExporting: NonEmptyObject[],
    fieldsToIgnore: string[],
    outputFileName: string
}

export const ExportCSVButton = ({dataExporting, fieldsToIgnore, outputFileName}: ExportCSVButtonProps) => {

    function exportDataAsCSV() {
        if (dataExporting.length === 0) return;

        console.log("Filtered and searched data:", dataExporting);

        let csvHeaders = [];
        let csvData = [];

        for (let i = 0; i < dataExporting.length; i++) {
            if (i === 0) {
                const item = dataExporting[i];
                const itemKeys = Object.keys(item).filter(value => !fieldsToIgnore.includes(value.toString()));
                csvHeaders.push(itemKeys);
            }

            const row = Object.values(dataExporting[i]);
            let values = [];
            for (let j = 0; j < row.length; j++) {
                if (typeof row[j] === 'object') {
                    console.log("Object values:", Object.values(row[j]));
                    values.push(Object.values(row[j])[0]);
                }
                else {
                    let columnHeader = csvHeaders[0][j];
                    if (!fieldsToIgnore.includes(columnHeader) && j < csvHeaders[0].length) values.push(row[j]);
                }
            }
            csvData.push(values);
        }

        console.log("CSV Headers:", csvHeaders);
        console.log("CSV Data:", csvData);
    
        const headers = Object.keys(dataExporting[0]).filter(value => !fieldsToIgnore.includes(value.toString())).join(',');
        const csv = [
        csvHeaders,
            ...csvData.map(row => row.join(','))
        ].join('\n');
    
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${outputFileName}.csv`;//`${source}${searchTerm ? `-${searchTerm}` : ""}.csv`;
        a.click();
    }

    return (
        <Tooltip content="Download product data as a .csv file.">
          <button 
            className="btn btn-secondary h-full"
            onClick={exportDataAsCSV}
          >
            <i className="fa-solid fa-download"></i><span className="hidden md:block">&nbsp;&nbsp;Export</span>
          </button>
        </Tooltip>
    );
}