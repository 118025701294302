import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { BasketItem } from '../../types';

import EcommerceNav from './nav';
import EcommerceFooter from './footer';

type ContextType = {
 basket: BasketItem[];
 setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>;
}

const ScreenContext = createContext<ContextType>({
  basket: [],
  setBasket: () => {},
});


export const EcommerceContext: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [basket, setBasket] = useState([] as BasketItem[]);

  return (
    <ScreenContext.Provider value={{ basket, setBasket }}>
      <EcommerceNav />
      {children}
      <EcommerceFooter />
    </ScreenContext.Provider>
  );
};

export const useEcommerceContext = () => useContext(ScreenContext);