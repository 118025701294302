import { ReactNode } from 'react';

interface PrimaryButtonProps {
  buttonText: ReactNode;
}

const PrimaryButton = ({ buttonText }: PrimaryButtonProps) => {
  return (
    <button className="bg-yellow-400 hover:bg-yellow-300 border border-yellow-500 px-4 py-2 rounded-md font-semibold">
      {buttonText}
    </button>
  )
}

export default PrimaryButton;