import React, { useEffect, useState, useRef } from 'react';

import { searchProducts } from "../api";

type Search = {
  initialData: any;
  setResults: (results: any) => void;
  searchFunction: (searchTerm: string) => Promise<any>;
  childQuery: string | null;
}

const SearchBar = ({ initialData, setResults, searchFunction, childQuery }: Search) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    console.log(childQuery);
    setSearchTerm(childQuery ?? '');
  }, [childQuery]);

  useEffect(() => {
    const executeSearch = async () => {
      const locallyFilteredItems = initialData?.filter((item: any) => {
        return Object.entries(item).some(([key, value]) => {
          if (key !== "PICT.FLAG") {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(searchTerm.toLowerCase());
            } else if (typeof value === 'object') {
              return value !== null && Object.values(value).some((nestedValue) => {
                return nestedValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
              });
            }
          }
          return false;
        });
      }) ?? [];

          // Clear the existing timeout if the user is still typing
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      let apiFilteredItems: any[] = [];

      // Set a new timeout to execute the search after a delay
      debounceTimeout.current = setTimeout(async () => {
        apiFilteredItems = 
        (searchTerm.length > 2)
          ? await searchFunction(searchTerm)
          : [];
      }, 500);

      // Create a combined array where items only appear once
      const combinedItems = Array.from(new Set([...locallyFilteredItems, ...apiFilteredItems]));
      setResults(combinedItems);
    }

    executeSearch();
  }, [searchTerm, initialData, setResults]);

  return (
    <div className="search-bar h-full dashboard-card bg-base-100 px-4 flex-grow">
      <i className="fa-solid fa-magnifying-glass"></i>
      <input
        id="modal-search-bar"
        className="h-full"
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
}

export default SearchBar;