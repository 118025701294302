import { useScreen } from '../../context';
import { Link } from 'react-router-dom';

const ConfigPage = () => {
  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("CONFIG");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  const configOptions = [
    {
      title: "Broadcast message",
      link: "/config/broadcast-message",
      description: "Broadcast an announcement to your users. You can choose wether this is a global or targeted message.",
      icon: "bullhorn"
    },
    {
      "title": "Allowable Couriers",
      "link": "",
      "description": "Enable couriers across the system and set your preferred courier and rates.",
      "icon": "truck"
    },
    {
      "title": "API Status of System",
      "link": "",
      "description": "View the status of the current APIs within your system and report any fault.",
      "icon": "server"
    },
    {
      "title": "Product Categories",
      "link": "",
      "description": "Set product categories across your system to enable better groupings.",
      "icon": "tags"
    },
    {
      "title": "Another Config Panel",
      "link": "",
      "description": "Add description here relevant to config item.",
      "icon": "eye"
    },
    {
      "title": "Another Config Panel",
      "link": "/config/another-panel-2",
      "description": "Add description here relevant to config item.",
      "icon": "cogs"
    },
    {
      "title": "Another Config Panel",
      "link": "",
      "description": "Add description here relevant to config item.",
      "icon": "chart-bar"
    }
  ];

  return (
    <div>
      <div className="topnav-title">
        <div>
          <h1 className="text-2xl md:text-4xl font-bold">System Config</h1>
          <h3>Configure your system settings here</h3>
        </div>
      </div>

      <div className="grid md:grid-cols-3 flex-grow gap-4 mt-4">
        {configOptions.map((child, index) => (
            <Link
            className="flex flex-col dashboard-card cursor-pointer p-4"
            key={index}
            to={child.link || "#"}
            >
              <h3 className="text-lg font-semibold">
                {child.title}
              </h3>
            
              <p className="text-sm mt-2">{child.description}</p>
            </Link>
        ))}
      </div>
    </div>
  )
}

export default ConfigPage;