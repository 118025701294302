type PageTabsProps = {
  tabs: string[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>> | ((tab: string) => void);
  card: boolean;
  theme?: "blue" | "green"
};

const PageTabs = ({ tabs, activeTab, setActiveTab, card, theme = "blue" }: PageTabsProps) => {
  const tabClass = (option: string) => {
    if (card) {
      if (activeTab === option) {
        return `px-4 btn-secondary${theme === "green" ? "-green" : ""}`;
      }
      return 'px-4 btn-ghost hover:btn-secondary';
    }
    if (activeTab === option) {
      return 'border-b-2 px-4 pb-1 border-[#3A5BFF]';
    }
    return 'border-b-2 px-4 pb-1 border-gray-200 hover:border-[#3A5BFF]';
  }

  return (
    <div className={`flex shrink-0 h-full ${card ? "dashboard-card" : ""} flex-wrap overflow-hidden`}>
      {!card && <div className="border-b-2 border-gray-200 w-6" />}
      {tabs.map((option, index) => (
        <button 
          key={index} 
          onClick={() => setActiveTab(option.replace(/\(.*?\)/g, '').trim())} 
          className={tabClass(option.replace(/\(.*?\)/g, '').trim())}
        >
          {option || "Unassigned"}
        </button>
      ))}
      {!card && <div className="border-b-2 border-gray-200 flex-grow" />}
    </div>
  )
}

export default PageTabs;