import React, { useState, useEffect } from "react";
import { Order } from "../../types";
import Pagination from "../pagination";
import { formatNumberAsPrice, getTotalStockForProduct } from "../../utils";
import { Link } from "react-router-dom";

import { fetchMetaDataForTopProducts, fetchProductsToClear } from "../../api";
import { Product } from "../../types";

type DataViews = 'Top Selling Products' | 'Stock to Clear';

const TopProducts = ({ ordersThisYear, fetching }: {ordersThisYear: Order[], fetching: boolean }) => {
  const [dataView, setDataView] = useState('Top Selling Products' as DataViews);
  const [showDropdown, setShowDropdown] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(8);
  const [topProducts, setTopProducts] = useState([] as any[]);
  const [topProductsMetaData, setTopProductsMetaData] = useState({} as any);
  const [productsToClear, setProductsToClear] = useState([] as Product[]);
  const [fetchingData, setFetchingData] = useState(true);

  const handleDataViewChange = (view: DataViews) => {
    setDataView(view);
    setShowDropdown(false);
  };

  const processOrdersIntoTopProducts = (orders: Order[], numberOfMonths: number) => {
    const now = new Date();
    const startDate = new Date(now.setMonth(now.getMonth() - numberOfMonths));

    const handleProduct = (productId: string, quantity: number, sales: number, acc: any) => {
      if (acc[productId]) {
        acc[productId].quantity += quantity;
        acc[productId].sales += sales;
      } else {
        acc[productId] = {
          id: productId,
          quantity: quantity,
          sales: sales
        };
      }
    }

    const topProducts = orders
      .filter(order => order.formattedDate >= startDate)
      .reduce((acc, order) => {
        if (typeof order["PRODUCT.CODE"] === 'string' && typeof order["ORDER.QUANTITY"] === 'string') {
          handleProduct(
            order["PRODUCT.CODE"], 
            parseFloat(order["ORDER.QUANTITY"]), 
            order["LINE.VALUE"] as number,
            acc
          );
        }
        else if (typeof order["PRODUCT.CODE"] === 'object' && typeof order["LINE.VALUE"] === 'object') {
          order["PRODUCT.CODE"].forEach((productId, index) => {
            handleProduct(
              productId, 
              parseFloat(order["ORDER.QUANTITY"][index]),
              parseFloat((order["LINE.VALUE"] as string[])[index]),
              acc
            );
          });
        }
        return acc;
      }, {} as { [key: string]: { id: string, name: string, quantity: number, sales: number } });

    return Object.values(topProducts)
      .filter(product => product["id"] !== "")
      .sort((a, b) => b.sales - a.sales)
      .slice(0, 25);
  }

  useEffect(() => {
    if (fetching) return;
    console.log(dataView);
    async function fetchData() {
      console.log('Fetching data...');
      setCurrentPage(1);
      if (dataView === 'Top Selling Products') {
        if (!topProductsMetaData.length || topProductsMetaData.length === 0) {
          console.log('Fetching top products metadata...');
          setFetchingData(true);
          let formattedtopProducts = processOrdersIntoTopProducts(ordersThisYear, numberOfMonths);
          const uniqueProductIds = formattedtopProducts.map(product => product.id);
          const metaData = await fetchMetaDataForTopProducts(uniqueProductIds);
          setTopProducts(formattedtopProducts);
          setTopProductsMetaData(metaData);
        }
        else {
          console.log('Using cached top products metadata...', topProductsMetaData.length);
          let formattedtopProducts = processOrdersIntoTopProducts(ordersThisYear, numberOfMonths);
          console.log(formattedtopProducts);
          const uniqueProductIds = formattedtopProducts.map(product => product.id);
          console.log(uniqueProductIds);
          setTopProducts(formattedtopProducts);
        }
      }
      else if (productsToClear.length === 0) {
        setFetchingData(true);
        const topProductsToClear = await fetchProductsToClear();
        console.log(topProductsToClear);
        setProductsToClear(topProductsToClear);
      }
      else console.log("Here for some reason", productsToClear);
      setFetchingData(false);
      //setTopProductsMetaData(metaData);
    }

    fetchData();
  }, [ordersThisYear, numberOfMonths, dataView, fetching, topProductsMetaData]);

  return (
    <div className="dashboard-card h-full flex flex-col justify-between">
      {dataView === 'Top Selling Products' && (
        <table className="table-auto w-full">
          <thead>
            <tr className="table-green">
              <th className="text-left-override">Top Selling Products Last {numberOfMonths > 1 ? `${numberOfMonths} Months` : 'Month'}</th>
              <th>Sales</th>
              <th>Quantity</th>
              <th>
                <div className="relative z-10">
                  <i 
                    className="cursor-pointer fa-regular fa-ellipsis-vertical" 
                    onClick={() => setShowDropdown(!showDropdown)}
                  ></i>
                  {showDropdown && (
                    <div className="absolute right-0 w-48 bg-white text-black border border-gray-200 rounded shadow-md z-50">
                      <div className="px-4 py-2">
                        <label htmlFor="months-slider" className="block text-sm">
                          Number of months
                        </label>
                        <input
                          id="months-slider"
                          type="range"
                          min="1"
                          max="12"
                          value={numberOfMonths}
                          onChange={(e) => setNumberOfMonths(parseInt(e.target.value))}
                          className="w-full mt-2"
                        />
                        <div className="flex justify-between text-xs text-gray-600 mt-1">
                          <span>1</span>
                          <span>12</span>
                        </div>
                      </div>
                      <ul className="">
                        <li className="cursor-pointer px-4 py-2 hover:bg-gray-100" onClick={() => handleDataViewChange('Stock to Clear' as DataViews)}>
                          View Stock to Clear
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>
          {fetchingData
            ? <td colSpan={5}>
                <div className="flex flex-col gap-4 h-48 w-full justify-center items-center">
                  <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-4xl"></i>
                  <h3 className="font-bold text-center">Fetching product data...</h3>
                </div>
              </td>
            : <tbody>
                {topProductsMetaData.length > 0 && topProducts
                  .slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage)
                  .map((product, index) => {
                    let productMetaData = topProductsMetaData.find((metaData: any) => metaData["PRODUCT.CODE"] === product.id);
                    if (productMetaData) return (
                    <tr key={index}>
                      <td>
                        <Link className="flex gap-4 items-center pr-2 hover:underline" to={`/product/${product["PRODUCT.CODE"]}`}>
                          <img 
                            src={typeof productMetaData["PICT.FLAG"] === 'string' ? productMetaData["PICT.FLAG"] : productMetaData["PICT.FLAG"][0]}
                            alt={productMetaData.DESCRIPTION} 
                            className="w-14 h-14 rounded-md" 
                          />
                          <div className="text-left-override">
                            <p className="font-semibold text-sm">{productMetaData.DESCRIPTION.length > 45 ? productMetaData.DESCRIPTION.slice(0, 45) + "..." : productMetaData.DESCRIPTION}</p>
                            <p className="text-xs">{productMetaData["PGROUP.DESC"]}</p>
                          </div>
                        </Link>
                      </td>
                      <td>£{formatNumberAsPrice(product.sales)}</td>
                      <td>{formatNumberAsPrice(product.quantity)}</td>
                    </tr>
                  )})}
              </tbody>
          }
        </table>
      )}

      {dataView === 'Stock to Clear' && (
        <table className="table-auto w-full">
          <thead>
            <tr className="table-green">
              <th className="text-left-override ml-2">Stock to Clear</th>
              <th>Stock Level</th>
              <th>Price</th>
              <th>GP</th>
              <th>
                <div className="relative z-10">
                  <i 
                    className="cursor-pointer fa-regular fa-ellipsis-vertical" 
                    onClick={() => setShowDropdown(!showDropdown)}
                  ></i>
                    {showDropdown && (
                      <div className="absolute right-0 w-48 bg-white text-black border border-gray-200 rounded shadow-md z-50">
                        <ul className="">
                          <li className="cursor-pointer px-4 py-2 hover:bg-gray-100" onClick={() => handleDataViewChange('Top Selling Products' as DataViews)}>
                            View Top Selling Products
                          </li>
                        </ul>
                      </div>
                    )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {productsToClear
              .filter(product => getTotalStockForProduct(product) > 0)
              .slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage)
              .map((product, index) => (
                <tr key={index}>
                  <td className="text-left-override ">
                    <Link className="font-semibold ml-2 text-sm hover:underline" to={`/product/${product["PRODUCT.CODE"]}?back=/`}>
                      {product["DESCRIPTION"]}
                    </Link>
                  </td>
                  <td><p className="text-sm px-1">
                    {getTotalStockForProduct(product).toLocaleString()}
                  </p></td>
                  <td><p className="text-sm px-1">{formatNumberAsPrice(product["LIST.PRICE"])}</p></td>
                  <td><p className="text-sm px-1">{formatNumberAsPrice(((product["LIST.PRICE"] - product["COST.PRICE"]) / product["COST.PRICE"]) * 100)}%</p></td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      <div className="px-4 pb-4">
        <Pagination 
          currentPage={currentPage}
          numberOfEntries={dataView === 'Top Selling Products' ? topProducts.length : productsToClear.length}
          setCurrentPage={setCurrentPage}
          itemsPerPage={productsPerPage}
          source="daive"
        />
      </div>
    </div>
  )

}

export default TopProducts;