import React, { useState, useEffect, useRef } from 'react';
import { useScreen } from '../context';

const ChatWidget = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { liveChatOpen, setLiveChatOpen } = useScreen();


  const handleClickOutside = (event: MouseEvent) => {
    // Add type check for event.target as well
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setLiveChatOpen(false);
    }
  };

  useEffect(() => {
    console.log('liveChatOpen:', liveChatOpen);

    if (liveChatOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [liveChatOpen]);
  
  return (
    <div 
      className="h-screen w-screen fixed top-0 bg-black bg-opacity-85 items-center justify-center"
      style={{zIndex: 9999, display: liveChatOpen ? 'flex' : 'none'}}
    >
      <div ref={modalRef} className="rounded-lg bg-[#FAFAFA] p-4 md:p-6 h-full flex-grow lg:max-w-[850px] md:max-h-[80vh] flex flex-col m-4 gap-4">
        <iframe
          className="w-full h-full"
          src="https://valory-h3o-beta.web.app"
          style={{border: 'none'}}
        ></iframe>
      </div>
    </div>
  );
}

export default ChatWidget;