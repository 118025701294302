import LabelledButton from "../components/labelledButton";
import VerticalTextInput from "../components/verticalTextInput";

const PurchasingTrailer = () => {

    // const billSettings = useEffect(() => {

    // });

    return (
        <main className="flex flex-col flex-grow justify-between gap-4">
            <nav className="flex justify-between items-center gap-4">
                <div className="search-bar px-4 h-12">
                    <i className="fa-solid fa-magnifying-glass text-[#858D9D]"></i>
                    <input type="text" placeholder="Search Existing Order" />
                </div>

                <button type="button" className="btn btn-primary h-full flex justify-center items-center gap-1 w-40" title="Search...">Search</button>
            </nav>

            <section className="flex flex-col dashboard-card bg-base-100 p-4 h-full">
                <div className="flex flex-row w-full">
                    <section className="flex flex-col lg:flex-row justify-evenly lg:w-auto flex-grow">
                        <div className="flex flex-col divide-solid divide-y-[3px] divide-[#ECECF2] flex-grow lg:mr-4">
                            <div className="flex flex-row flex-grow mb-3">
                                <div className="flex flex-col divide-y-2 divide-[#ECECF2] flex-grow gap-3">
                                    <VerticalTextInput label="PO number" placeholder="..." className="m-1"/>
                                    <VerticalTextInput label="Company name" className="m-1"/>
                                    <VerticalTextInput label="Business address" className="m-1"/>
                                </div>
                                <div className="flex flex-col divide-y-2 divide-[#ECECF2] flex-grow gap-3">
                                    <VerticalTextInput label="Template" placeholder="Select invoice template" className="m-1"/>
                                    <VerticalTextInput label="Date" placeholder="Select due date" type="date" className="m-1"/>
                                    {/* This empty div is needed for the under line of the above input.*/}
                                    <div className="mt-1"/>
                                </div>
                            </div>
                            <div className="flex flex-row flex-grow pt-3">
                                <div className="flex flex-col divide-y-2 divide-[#ECECF2] flex-grow gap-3">
                                    <VerticalTextInput label="Country" placeholder="Select country" className="m-1"/>
                                    <VerticalTextInput label="Phone number" type="tel" pattern="[0-9]{5}-[0-9]{6}" className="m-1"/>
                                    <VerticalTextInput label="Tax rate" className="m-1"/>
                                    <div className="mt-1"/>
                                </div>
                                <div className="flex flex-col divide-y-2 divide-[#ECECF2] flex-grow gap-3">
                                    <VerticalTextInput label="City" placeholder="Select city" className="m-1"/>
                                    <VerticalTextInput label="Email" type="email" className="m-1"/>
                                    {/* This empty div is needed for the under line of the above input.*/}
                                    <div className="mt-1"/>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col border-solid border-2 border-[#ECECF2] rounded-xl divide-y-2 divide-[#ECECF2] mt-4 mb-4 lg:mt-0 lg:mb-0 basis-1/3 gap-8">
                            {/* Use defaultValue prop to set the value that is pulled from config settings*/}
                            <div className="pl-2 md:pl-8"><VerticalTextInput label="Bill To" className="m-1" readonly={true} placeholder="Pulled from Config Settings" labelColourClass="text-blue-500"/></div>
                            <div className="pl-2 md:pl-8"><VerticalTextInput label="Delivery" className="m-1" readonly={true} placeholder="Pulled from Config Settings" labelColourClass="text-[#1C1D21]"/></div>
                            <div className="pl-2 md:pl-8"><VerticalTextInput label="Notes" className="m-1" placeholder="Add notes to order" multiline={true} labelColourClass="text-[#1C1D21]"/></div>
                        </div>
                    </section>
                </div>
                <nav className="flex flex-col-reverse lg:flex-row mt-auto">
                    <div className="flex justify-between lg:justify-normal flex-row mt-6 lg:mt-auto">
                        <button type="button" className="btn btn-primary w-40 flex justify-center mr-2" title="Save & Send">Save & Send</button>
                        <button type="button" className="btn bg-[#5E81F4]/10 text-[#5E81F4] w-32 flex justify-center" title="Cancel">Cancel</button> 
                    </div>
                    <div className="flex flex-col border-solid border-2 border-[#ECECF2] rounded-xl divide-y-2 divide-[#ECECF2] lg:ml-auto basis-1/3">
                        {/* These have onClick handlers */}
                        <LabelledButton buttonName="Save As a Draft" buttonLabel="Edit and send this invoice later"/>
                        <LabelledButton buttonName="Delete invoice" buttonLabel="Hide & disable current invoice"/>
                    </div>
                </nav>
            </section>
        </main>
    );
}

export default PurchasingTrailer;