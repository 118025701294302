import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useEcommerceContext } from './context';
import { fetchProduct } from '../../api';
import { Product } from '../../types';
import { fetchProductData, sortAlphabeticallyWithNonAlphaAtEnd } from '../../utils';

import { handleProductSelect, isProductInBasket, removeItemFromBasket } from '../../components/basketUtils';
import ProductFeature from './components/productFeature';

const EcommerceProductPage = () => {
  const { id: productId } = useParams<{ id: string }>();
  const { basket, setBasket } = useEcommerceContext();
  const [activeBasketItem, setActiveBasketItem] = useState("");
  useEffect(() => {
    console.log(basket, activeBasketItem)
  }, [basket, activeBasketItem]);

  const [fetchingData, setFetchingData] = useState(true);
  const [productData, setProductData] = useState({} as Product);
  const [activeImageUrl, setActiveImageUrl] = useState("");
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);

  useEffect(() => {
    if (!productId) return;

    const fetchData = async () => {
      try {
        const productDataResponse = await fetchProduct(productId);

        if (!productDataResponse || productDataResponse.length === 0) {
          console.error('There was a problem fetching product categories');
          return;
        }

        const { products } = await fetchProductData();
        setFilteredAndSearchedProducts(
          products.filter((product: Product) => product["PICT.FLAG"] !== "0" && product["PRODUCT.CODE"] !== productId)
        )

        console.log(productDataResponse);
        setProductData(productDataResponse[0]);
        setActiveImageUrl(productDataResponse[0]["PICT.FLAG"][0] || productData["PICT.FLAG"]);
        setFetchingData(false);
      } catch (error) {
        setFetchingData(false);
        console.error('There was a problem fetching accounts:', error);
      }
    };

    fetchData();
    //deleteLocalProducts();
  }, [productId]);

  const itemIsInBasket = isProductInBasket(productData['PRODUCT.CODE'], basket);

  const mouseEnter = (image: string) => {
    setActiveImageUrl(image);
  }

  const mouseLeave = () => {
    setActiveImageUrl(productData["PICT.FLAG"][0] || productData["PICT.FLAG"]);
  }

  const supplierImages = [
    { image: "aiko", link: "AIKO" },
    { image: "canadianSolar", link: "Canadian Solar" },
    { image: "duracell", link: "Duracell Energy" },
    { image: "ecoflow", link: "Ecobat" },
    { image: "enphase", link: "Enphase" },
    { image: "foxess", link: "Fox ESS" },
    { image: "givEnergy", link: "GivEnergy" },
    { image: "hyundai", link: "Hyundai" },
    { image: "JAsolar", link: "JA Solar" },
    { image: "jinko", link: "Jinko" },
    { image: "mountingSystems", link: "Mounting Systems" },
    { image: "myenergi", link: "myenergi" },
    { image: "pylonTech", link: "Puredrive" },
    { image: "solarEdge", link: "SolarEdge" },
    { image: "solax", link: "SolaX" },
    { image: "solis", link: "Solis" },
    { image: "sunSynk", link: "Sunsynk" },
    { image: "tigo", link: "Tigo" }
    ]

  return (
    <div className="flex flex-col flex-grow gap-4 bg-white">
      <div className="container mx-auto py-12 gap-4 md:gap-10">
        {fetchingData
          ? <div className="flex flex-col items-center justify-center gap-4">
              <h3 className="text-2xl font-bold">Fetching product data...</h3>
            </div>
          : <>
              <div className="flex gap-2 items-center font-semibold mb-4">
                <Link to="/ecommerce" className="text-sm ecommerce-link">Home</Link>
                <i className="fas fa-chevron-right text-gray-500"></i>
                <Link to="/ecommerce/products" className="text-sm ecommerce-link">Products</Link>
                <i className="fas fa-chevron-right text-gray-500"></i>
                <h3 className="text-sm text-ecommerce-green">{productData["DESCRIPTION"]}</h3>
              </div>

              <div className="w-full grid md:grid-cols-2 gap-6">
                <div>
                  {typeof productData["PICT.FLAG"] === "string"
                    ? <img src={productData["PICT.FLAG"] || "/product_placeholder.webp"} alt={productData["DESCRIPTION"]} className="w-full" />
                    : <div className="flex gap-4">
                        <div className="flex-col flex gap-4 min-w-36">
                          {productData["PICT.FLAG"] && (productData["PICT.FLAG"] as string[]).map((image: string, index: number) => (
                            <img 
                              key={index} 
                              src={image} 
                              alt="Product" 
                              className="w-36 object-cover rounded-md"
                              onMouseEnter={() => mouseEnter(image)}
                              onMouseLeave={mouseLeave}
                             />
                          ))}
                        </div>

                        <div className="flex-grow">
                          <img src={activeImageUrl} alt={productData["DESCRIPTION"]} className="flex-grow" />
                        </div>
                      </div>
                  }
                </div>

                <div>
                  <h1 className="text-xl font-bold">{productData["DESCRIPTION"]}</h1>

                  <h3 className="text-lg font-semibold mt-4">£{productData["LIST.PRICE"]} (inc VAT {(productData["LIST.PRICE"] * 1.2).toFixed(2)})</h3>
                  <p className="">Shipping calculated at checkout.</p>

                  <div className="flex items-center gap-4 mt-4">
                    <img 
                      src={`/supplierImages/${supplierImages.find((supplier) => supplier.link === productData["SUPPLIER"])?.image || "/product_placeholder"}.svg`} 
                      alt={productData["SUPPLIER"]} 
                      className="w-20"
                    />

                    <div>
                      <p className="text-sm">Supplied by:</p>
                      <h3 className="font-semibold text-xl">{productData["SUPPLIER"]}</h3>
                    </div>
                  </div>

                  <button 
                    className={`${itemIsInBasket ? "bg-black border border-black text-white" : " border border-gray-300 shadow-md"} mt-6 rounded-md p-2 px-3 w-full`}
                    onClick={() => {
                      if (!itemIsInBasket) {
                        handleProductSelect(
                          productData['PRODUCT.CODE'], 
                          [productData],
                          basket, 
                          setBasket, 
                          setActiveBasketItem, 
                          "sale"
                        );
                      }
                    }}
                  >
                    {itemIsInBasket 
                    ? <>Added to Basket&nbsp;&nbsp;<i className="fas fa-check-circle"></i></>
                    : "Add to Basket"}
                  </button>

                  <button className="bg-yellow-400 border border-yellow-600 rounded-md w-full p-2 px-3 mt-4">
                    Login to view trade prices&nbsp;<i className="fas fa-lock"></i>
                  </button>

                  <div className="rounded-lg p-4 mt-6 border border-gray-300 flex gap-4">
                    <div className="h-10 w-10 bg-ecommerce-green text-white rounded-full flex items-center justify-center">
                      <i className="fas fa-shopping-bag"></i>
                    </div>

                    <div>
                      <h3><span className="font-semibold">Pickup available at Sheffield Branch.</span> Usually ready in 24 hours.</h3>
                      <p className="hover:underline underline-offset-2 cursor-pointer text-muted">Check availability at other stores.</p>
                    </div>
                  </div>

                  <div className="mt-6">
                    <h3 className="font-semibold text-lg mb-4">Details</h3>
                    <div dangerouslySetInnerHTML={{ __html: productData["WDESCRIPTION"] }} />
                  </div>
                </div>
              </div>
            </>
        }
      </div>

      <div className="p-8 md:py-16">
        <h2 className="font-bold text-2xl md:text-3xl md:mr-24 mb-10 text-center">Related Products</h2>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-x-4 md:gap-x-8 gap-y-8 container mx-auto">
          {sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, "DESCRIPTION")
            .sort((a: Product, b: Product) => {
              if (a["Total Stock"] > 0 && b["Total Stock"] === 0) return -1;
              if (a["Total Stock"] === 0 && b["Total Stock"] > 0) return 1;
              return 0;
            })
            .splice(0, 4)
            .map((product: Product, rowIndex: number) => {
              return (
              <div className="flex items-center flex-col gap-2">
                <ProductFeature product={product} />
              </div>
            )}
          )}
        </div>
      </div>
    </div>
  );
}

export default EcommerceProductPage;