import React, { useContext, useEffect, useRef, useState } from "react";
import VerticalTextInput from "../../components/verticalTextInput";
import { PronounSelect } from "../../components/pronounSelect";
import {User, UserLock, UserOrderLimits, UserPermissions, UserRole} from '../../types';
import { Navigate } from "react-router-dom";
import { UserRoleSelect } from "../../components/userRoleSelect";
import { businessAreas, permissionsHierarchy, propToHumanReadable } from "../../dummyData";
import { UserPermissionCustomisation } from "../../components/userPermissionCustomisation";
import { Tooltip } from 'react-tooltip'
import { UIToggles } from "../../uitoggles";
import { createNewUser, fetchUserByKey, listRoles, listUsers, updateUser, attemptLogin, updateUserPassword } from "../../api";
import BackLink from "../../components/backLink";
import ModalWithChildren from "../../components/modalWithChildren";

import { useScreen } from "../../context";

type UserMaintenanceProps = {
    userId: string,
    closeCard: () => void
}

function getUserLocks(): UserLock[] {
    return [] as UserLock[]
}

function lockUser(lockingUserId: string, lockedByUserId: string, lockedForMilliseconds: number) {
    //Locking user needs to be enforced at server level too
    const lockedAt: Date = new Date();

    const lock: UserLock = {lockedUserId: lockingUserId, lockedByUserId: lockedByUserId, lockedAt: lockedAt, lockedForMilliseconds: lockedForMilliseconds}

    //TODO: Add to locks
}

export const UserMaintenance = ({userId, closeCard} : UserMaintenanceProps) => {
    //const currentUser = useContext();

    const [currentUserHasPermissionToEdit, setCurrentUserHasPermissionToEdit] = useState(false);
    const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState(false);
    const [editedUserIsLocked, setEditedUserIsLocked] = useState(false);
    const [userLockTimesOutInMillis, setUserLockTimesOut] = useState<number>(0)

    const [newUser, setNewUser] = useState<boolean>(!userId || userId === '');
    const [editedUserUID, setEditedUserUID] = useState<string>(userId);
    const [profileImage, setProfileImage] = useState<string>("tayah.jpg");
    const [username, setUsername] = useState<string>("");
    const [selectedRole, setSelectedRole] = useState<UserRole>({} as UserRole);
    const purchaseLimit = useRef<number>();
    const salesLimit = useRef<number>();
    const quoteLimit = useRef<number>();
    const invoiceLimit = useRef<number>();
    const [pronoun, setPronoun] = useState<string>('');
    const [userEmailAddress, setUserEmailAddress] = useState<string>('');
    const [userPassword, setUserPassword] = useState<string>('');
    const [userHasSuperPermissions, setUserHasSuperPermission] = useState<boolean>(false);
    const [permissionOverrides, setPermissionOverrides] = useState<UserPermissions>({} as UserPermissions);

    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');

    const [showOverrides, setShowOverrides] = useState(false);
    const [error, setError] = useState('');

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const roles = useRef<UserRole[]>([]);
    const users = useRef<User[]>([]);

    const profileUpload = useRef<HTMLInputElement>(null);
    
    const { addNotification } = useScreen(); 

    function superPermissionChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setUserHasSuperPermission(e.target.checked);
    }

    function selectPronoun(pronoun: string) {
        if (pronoun) setPronoun(pronoun);
    }

    function selectUserRole(selectedRole: UserRole) {
        setSelectedRole(selectedRole);
    }

    async function editProfileImage(e: React.ChangeEvent<HTMLInputElement>) {
        const file: File = (((e.target as HTMLInputElement).files) ?? [])[0];
        const newProfileImage: string = file.name;

        if (file) {
            //Upload

            //Get potentially modified image name
            

            setProfileImage(newProfileImage);
        }
    }

    function verifyOrderLimit(limit: number, limitName: string): string | undefined {
        //const limitNum = Number(limit);
        //if (isNaN(limitNum)) return `Limit ${limitName} was not a number!`;
        if (limit < 0) return `Limit ${limitName} is negative!`;
        if (limit % 1 !== 0) return `Limit ${limitName} is a fraction/decimal number!`;
        return undefined;
    }

    function areAllFieldsValid(): {success?: boolean, errorMessage?: string} {
        const errors: string[] = []

        //TODO: Prevent swears and other unappreciated language
        if (!username) errors.push("No username specified!");
        if (!userEmailAddress || !userEmailAddress.includes("@")) errors.push("No valid email specified!");
        //if (userEmailAddress && users.filter((value) => value.userId ))
        console.log(`Role includes ${selectedRole.userRoleId}, roles are: ${JSON.stringify(roles.current.map((value) => value.userRoleId))}`);
        if (!selectedRole || 
            !roles.current.map((value) => value.userRoleId).includes(selectedRole.userRoleId)) 
                errors.push(`Empty role or invalid role! Role ID is: ${selectedRole.userRoleId?.replace(' ', '#') ?? ""}!`);
        //Don't show error if pronouns aren't shown
        if (UIToggles.pronounsShown && !pronoun) errors.push("No pronouns specified!");
        
        const purchaseVerificationMsg = verifyOrderLimit(purchaseLimit.current ?? 0, "purchase");
        if (purchaseVerificationMsg) errors.push(`${purchaseVerificationMsg} Limit is ${purchaseLimit.current}!`);

        const salesVerificationMsg = verifyOrderLimit(salesLimit.current ?? 0, "sales");
        if (salesVerificationMsg) errors.push(`${salesVerificationMsg} Limit is ${salesLimit.current}!`);

        const quoteVerificationMsg = verifyOrderLimit(quoteLimit.current ?? 0, "quote");
        if (quoteVerificationMsg) errors.push(`${quoteVerificationMsg} Limit is ${quoteLimit.current}!`);

        const invoiceVerificationMsg = verifyOrderLimit(invoiceLimit.current ?? 0, "invoice");
        if (invoiceVerificationMsg) errors.push(`${invoiceVerificationMsg} Limit is ${invoiceLimit.current}!`);
        
        if (errors.length > 0) {
            let error = "";
            error += errors.map((value) => value).join("\n")
            console.log(error)
            return {success: false, errorMessage: error}
        }
        else return {success: true}
    }

    async function saveUserChanges() {
        //Update user object
        const validDetailsMessage = areAllFieldsValid();
        if (validDetailsMessage.success) {
            let user: User | undefined = users.current.find((value) => value.userId === userId);
            let creatingNewUser: boolean = false;

            if (!user || newUser) {
                console.log("Creating new user");
                creatingNewUser = true;
                user = {} as User;
                user.userId = editedUserUID;
            }

            user.username = username;
            user.password = userPassword;
            user.userEmailAddress = userEmailAddress;
            user.pronouns = pronoun;
            user.role = selectedRole;
            user.permissionOverrides = permissionOverrides;
            if (user.orderLimits) {
                console.log(JSON.stringify(user.orderLimits));
                user.orderLimits.invoice = invoiceLimit.current;
                user.orderLimits.purchase = purchaseLimit.current;
                user.orderLimits.quote = quoteLimit.current;
                user.orderLimits.sales = salesLimit.current;
                console.log(JSON.stringify(user.orderLimits));
            } else {
                user.orderLimits = {
                    invoice: invoiceLimit.current,
                    purchase: purchaseLimit.current,
                    quote: quoteLimit.current,
                    sales: salesLimit.current
                } as UserOrderLimits
            }
            user.isSuperUser = userHasSuperPermissions;

            setSaving(true);
            if (creatingNewUser) {
                await createNewUser(user, permissionsHierarchy);
                users.current.push(user);
                console.log("Created user " + userId);
            } else {
                await updateUser(user, permissionsHierarchy)
                users.current[users.current.indexOf(user)] = user;
                console.log("Updated user " + userId);
            }
            //Unlock user

            setSaving(false);
            if (closeCard) closeCard();
        } else {
            alert("Cannot save. " + validDetailsMessage.errorMessage);
        }
    }

    function resetUserPassword() {
        
    }

    async function updatePass() {
      try {
        console.log("Attempting login")
        const passwordIsCorrect = await attemptLogin(userEmailAddress, currentPassword);
        console.log(passwordIsCorrect);
        
        if (passwordIsCorrect.success) {
          const passwordUpdateAttempt = await updateUserPassword(userId, { PASSWORD: currentPassword }, { PASSWORD: newPassword });
          console.log(passwordUpdateAttempt);
          
        }
      } catch (error) {
        console.error(error);
      }
    }

    function sendWeeklyQuoteReport() {
        //Send to user email address
    }

    const LimitDefinition = (
            {
                limitLabel, 
                limitText, 
                defaultValue, 
                onChanged, 
                labelWidthClass
            } : {
                limitLabel: string, 
                limitText?: string, 
                defaultValue?: number, 
                onChanged?: (newValue: number) => void, 
                labelWidthClass?: string
            }) => {
        const [limit, setLimit] = useState<string>(defaultValue?.toString() ?? "");
        const id = "userMaintenanceLimitDefinition" + (limitLabel.split(' ').map((value) => value.at(0)?.toUpperCase() + value.slice(1)).join(''));

        function changeLimit(e: React.ChangeEvent<HTMLInputElement>) {
            setLimit(e.target.value);
            const newValue: number | undefined = Number(e.target.value);

            if ((newValue || newValue === 0) && !isNaN(newValue)) {
                if (onChanged) {
                    onChanged(newValue);
                }
            }
        }

        return (
            <div className="w-full flex flex-col md:flex-row">
                <label className={`font-medium ${labelWidthClass ?? "w-20"}`.trim()} htmlFor={id}>{limitLabel}</label>
                <input 
                    className="border-2 rounded border-gray-300 w-full md:w-80 lg:w-96"
                    id={id}
                    type="number" 
                    placeholder={limitText}
                    onChange={changeLimit}
                    value={limit}
                ></input>
            </div>
        )
    }

    const backButton = () => <BackLink onClick={() => closeCard()}/>

    useEffect(() => {
        //Query user admin status by sending a request via the API, don't store admin status in the user itself.
        //If not has permissions, set the current user has permission state to false
        //Current user will be communicated down by a context.
        //Note: Current user is not the user specified by userId.
        console.log("User id in user maintenance: ", userId);
        const fetchData = async () => {
            if (userId) {
                const user: User | undefined = await fetchUserByKey(userId);//allUsers().find((value) => value.userId === userId);
                console.log("User: ", user?.password);

                if (user) {
                    setNewUser(false);
                    setUsername(user.username);
                    setPronoun(user.pronouns ?? "");
                    setUserEmailAddress(user.userEmailAddress ?? "");
                    setSelectedRole(user.role);
                    setProfileImage(user.profileImage);
                    setUserHasSuperPermission(user.isSuperUser);
                    purchaseLimit.current = user.orderLimits.purchase;
                    invoiceLimit.current = user.orderLimits.invoice;
                    quoteLimit.current = user.orderLimits.quote;
                    salesLimit.current = user.orderLimits.sales;
                    setPermissionOverrides(user.permissionOverrides ?? {} as UserPermissions);

                    //Check for locks on editing the user
                    const locked: boolean = getUserLocks().map((lock) => lock.lockedUserId).includes(user.userId);
                    //setUserLockTimesOut(160000);

                    setEditedUserIsLocked(locked);
                } else {
                    setError("Could not find user of user ID " + userId);
                }
            }

            roles.current = await listRoles();
            users.current = await listUsers();

            setLoading(false);
        }

        fetchData();
        setCurrentUserHasPermissionToEdit(true);
    }, [userId])

    if (loading) return (
        <section className="dashboard-card p-4 flex flex-row">
            <span className="w-[95%]">Loading...</span>
            {backButton()}
        </section>
    );

    if (!currentUserHasPermissionToEdit) return (
        <section className="dashboard-card p-4 flex flex-row">
            <span className="w-[95%]">User has no permission to edit users.</span>
            {backButton()}
        </section>
        //<Navigate to={"/maintenance"}/>
    );

    if (editedUserIsLocked) return (
        <section className="dashboard-card p-4">
            <span className="w-[95%]">User {username} (User ID: {userId}) is locked for editing. This lock will expire in {Math.ceil(userLockTimesOutInMillis / 1000 / 60)} minutes, or when the locking user is done editing the user.</span>
            {backButton()}
        </section>
    )

    if (error) return (
        <section className="dashboard-card p-4 flex flex-row">
            <span className="w-[95%]">{error}</span>
            {backButton()}
        </section>
    )

    return <>
      <ModalWithChildren
      modalOpen={changePasswordModalOpen}
      setModalOpen={setChangePasswordModalOpen}
      children={
        <div className="p-4 md:p-6 max-w-[650px]">
          <h2 className="font-bold text-2xl text-center mb-6">Change User Password</h2>
          <p className="text-[#8181A5] text-center">To change {username}'s password, please provide the current password below, along with the new value you'd like to change it to: </p>
          
          <div className="mt-6">
            <p className="font-bold text-lg mb-1">Current Password:</p>
            <input 
              type="password" 
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="border-2 rounded border-gray-300 w-full text-base p-2 "
              placeholder={"Enter user's name..."}
            />
          </div>

          <div className="mt-4">
            <p className="font-bold text-lg mb-1">New Password:</p>
            <input 
              type="password" 
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="border-2 rounded border-gray-300 w-full text-base p-2 "
              placeholder={"Enter user's name..."}
            />
          </div>

          <button 
              className="btn btn-green py-2 px-4 font-semibold w-full rounded-md mt-6" 
              onClick={() => updatePass()}
            >
              <i className="fa-solid fa-floppy-disk mr-2"></i> Change Password
            </button>
            <button 
              className="btn btn-outline hover:bg-black hover:text-white py-2 px-4 font-semibold w-full rounded-md mt-2" 
              onClick={() => {
                setChangePasswordModalOpen(false)
                setNewPassword('');
                setCurrentPassword('');
              }}
            >
              Cancel
            </button>
        </div>
      }
    />

    <section className="dashboard-card p-4 md:p-6">
      {backButton()}

      <div className="flex items-center justify-between">
        <h3 className="font-bold text-2xl mt-2">User Maintenance</h3>
        {!newUser && <div className="relative">
          <div className="flex justify-center items-center py-2 cursor-pointer group border border-gray-300 px-4 rounded-lg">
            Quick Actions <i className="fas fa-chevron-down ml-2"></i>
          </div>

          <div className="absolute top-10 right-0 bg-white rounded-md shadow-md opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50 border border-gray-300 w-72"> 
            <button 
              className="w-full border-b border-gray-300 py-2 px-4 text-center hover:bg-gray-100" 
              onClick={() => setChangePasswordModalOpen(true)}
            >
              <i className="fa-solid fa-link mr-1"></i> Change User Password
            </button>
            
            <button 
              className="w-full py-2 px-4 text-center hover:bg-gray-100" 
              onClick={() => sendWeeklyQuoteReport()}>
                  <i className="fa-solid fa-link mr-1"></i> Send Weekly Quote Report
            </button>
          </div>
        </div>}
      </div>

      <div className="grid md:grid-cols-2 gap-4 md:gap-x-12 md:gap-y-8 mt-8">
        <div className="flex items-end gap-8">
          <div className="flex flex-col gap-2">
            <div className="w-16 h-16 md:h-32 md:w-32 lg:h-40 lg:w-40 rounded-[50%] mt-4 relative">
              <img 
                src={profileImage} 
                id="userMaintenanceUserProfileImage" 
                className="w-16 h-16 md:h-32 md:w-32 lg:h-40 lg:w-40 rounded-[50%] border border-gray-500" 
                alt="Large user profile"
              />

              <button className="btn-outline h-10 w-10 absolute bottom-0 right-3s bg-white rounded-full" onClick={() => profileUpload?.current?.click()}>
                <i className="fa-solid fa-pencil"></i>
              </button>
            </div>
    
            <input 
                hidden 
                type="file" 
                ref={profileUpload} 
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {await editProfileImage(e)}}
                accept=".png, .jpeg, .jpg, .gif, .webp"
            />
          </div>

          <div className="w-full pb-1">
            <p className="font-bold text-lg">Name</p>

            <p className="text-sm text-[#8181A5] mt-1">Your name will display within WareTech and be visible on any action completed. This is also the name that will appear on quotations and public facing documents.</p>

            <input 
              type="text" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              className="border-2 rounded border-gray-300 w-full text-base p-2 mt-2 "
              placeholder={"Enter user's name..."}
            />
          </div>
        </div>

        {newUser && <div />}

        <div className="h-full flex flex-col justify-end">
          <p className="font-bold text-lg">Email</p>

          <p className="text-sm text-[#8181A5] mt-1">This email address will be used for all system notifications and communications.</p>

          <input 
            type="text" 
            value={userEmailAddress} 
            onChange={(e) => setUserEmailAddress(e.target.value)} 
            className="border-2 rounded border-gray-300 w-full text-base p-2 mt-2"
            placeholder={"Enter user's email address..."}
          />
        </div>
        
        {newUser && 
          <div>
            <p className="font-bold text-lg">Password</p>

            <p className="text-sm text-[#8181A5] mt-1">Enter a new password for this user. This will be used to log in to WareTech.</p>

            <input 
              type="password" 
              className="border-2 rounded border-gray-300 w-full text-base p-2 mt-2"
              placeholder={"Enter user's password..."} 
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
            />
          </div>
        }

        <div>
          <p className="font-bold text-lg">Role</p>

          <p className="text-sm text-[#8181A5] mt-1">Select a role for this user. Roles determine what permissions the user has within WareTech.</p>

          <select
            value={selectedRole.userRoleId}
            onChange={(e) => setSelectedRole(roles.current.find((value) => value.userRoleId === e.target.value) ?? {} as UserRole)}
            className="border-2 rounded border-gray-300 w-full text-base p-2 mt-2"
          >
            <option value={""} disabled hidden></option>
            {roles.current.map((role) => (
              <option key={role.userRoleId} value={role.userRoleId}>{role.name}</option>
            ))}
          </select>
        </div>

        <div className="flex flex-col justify-end">
          <label className="font-bold mt-4" htmlFor="userMaintenancePermissionOverrides">
            User Permission Overrides: 
            <abbr id="userMaintenancePermissionOverrides" title={showOverrides ? "Hide overrides" : "Show overrides"}>
              <i 
                onClick={() => setShowOverrides(!showOverrides)} 
                className={`hover:cursor-pointer fa-solid ${showOverrides ? 'fa-eye-slash' : 'fa-eye'} ml-1 text-green`}
              />
            </abbr>
          </label>
          {showOverrides && 
              <UserPermissionCustomisation 
                  propToHumanReadable={propToHumanReadable} 
                  userPermissions={permissionOverrides}
                  isTriState={true}
                  toggledOnColour="#308e63"
              />
          }        
          <span className="text-sm text-[#8181A5]">Double click a switch to set a permission back to inheriting from its role.</span>
        </div>

        <div>
          <p className="font-bold text-lg">Maximum Purchase Order Value</p>

          <p className="text-sm text-[#8181A5] mt-1">Enter the maximum value the user can send on a Purchase Order.</p>

          <input 
            type="number" 
            value={purchaseLimit.current} 
            onChange={(e) => purchaseLimit.current = Number(e.target.value)} 
            className="border-2 rounded border-gray-300 w-full text-base p-2 mt-2"
            placeholder={"Enter maximum value..."}
          />
        </div>

        <div>
          <p className="font-bold text-lg">Maximum Sales Order Value</p>

          <p className="text-sm text-[#8181A5] mt-1">Enter the maximum value the user can send on a Sales Order.</p>

          <input 
            type="number" 
            value={salesLimit.current} 
            onChange={(e) => salesLimit.current = Number(e.target.value)} 
            className="border-2 rounded border-gray-300 w-full text-base p-2 mt-2"
            placeholder={"Enter maximum value..."}
          />
        </div>

        <div>
          <p className="font-bold text-lg">Maximum Quote Value</p>

          <p className="text-sm text-[#8181A5] mt-1">Enter the maximum value the user can send on a Quote.</p>

          <input 
            type="number" 
            value={quoteLimit.current} 
            onChange={(e) => quoteLimit.current = Number(e.target.value)} 
            className="border-2 rounded border-gray-300 w-full text-base p-2 mt-2"
            placeholder={"Enter maximum value..."}
          />
        </div>

        <div>
          <p className="font-bold text-lg">Maximum Invoice Value</p>

          <p className="text-sm text-[#8181A5] mt-1">Enter the maximum value the user can send on an Invoice.</p>

          <input 
            type="number" 
            value={invoiceLimit.current} 
            onChange={(e) => invoiceLimit.current = Number(e.target.value)} 
            className="border-2 rounded border-gray-300 w-full text-base p-2 mt-2"
            placeholder={"Enter maximum value..."}
          />
        </div>

        <div>
          <div className="flex flex-col md:flex-row">
            <label htmlFor="userMaintenanceUserIsSuperUser" className="mr-4 font-semibold">Does this user have Super User Permissions?</label>

            {/* On the original design, this was a textbox, but checkboxes tend to be more intuitive for yes/nos. */}
            <input id="userMaintenanceUserIsSuperUser" type="checkbox" onChange={superPermissionChanged} checked={userHasSuperPermissions}></input>
          </div>
          
          <span className="text-sm text-[#8181A5]">This permission supersedes all allocated permissions, and grants access to all areas of the system.</span>
        </div>
      </div>

      
        <div className="flex flex-col divide-y-2 gap-1">
            <div className="flex flex-col md:flex-row-reverse">
                <div className="flex flex-col ml-4 content-center">
                    
                </div>
                <div className="flex flex-col gap-2 w-full">
                    {UIToggles.pronounsShown && 
                    <>
                        <label htmlFor="userMaintenancePronounSelect" className="font-bold text-sm mt-2">Pronouns</label>
                        <PronounSelect onPronounSelected={selectPronoun} className="w-full md:w-80 lg:w-96" defaultValue={pronoun} id="userMaintenancePronounSelect"/>
                    </>}



                    <button 
                      className="btn-primary bg-green py-2 px-4 font-semibold w-full max-w-72 mt-6 rounded-md" 
                      onClick={async () => await saveUserChanges()}
                      disabled={saving}
                    >
                        <i className="fa-solid fa-floppy-disk mr-2"></i>
                        {saving ? "Saving..." : (newUser ? "Create User" : "Save")}
                    </button>
                </div>
            </div>
        </div>

    </section>
  </>;
}