import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { handleProductSelect, isProductInBasket, removeItemFromBasket } from '../../../components/basketUtils';
import { useEcommerceContext } from '../context';
import { Product } from '../../../types';

interface ProductFeatureProps {
  product: Product;
}

const ProductFeature = ({ product }: ProductFeatureProps) => {
  const { basket, setBasket } = useEcommerceContext();
  const [activeBasketItem, setActiveBasketItem] = useState("");
  const itemIsInBasket = isProductInBasket(product['PRODUCT.CODE'], basket);

  return <>
    <Link to={`/ecommerce/product/${product['PRODUCT.CODE']}`} className="flex flex-col h-full">
      <img src={typeof product["PICT.FLAG"] === "string" ? product["PICT.FLAG"] || "/product_placeholder.webp" : product["PICT.FLAG"][0] || "/product_placeholder.webp"} alt={product.DESCRIPTION} className="h-48 md:h-44 lg:h-60 w-auto rounded-md mx-auto" />

      <div className="min-h-[73px]">
        <p className="font-semibold">{product.DESCRIPTION}</p>
      </div>
      <p className="text-lg">£{product["LIST.PRICE"]} <span className="text-sm">(inc VAT £{(product["LIST.PRICE"] * 1.2).toFixed(2)})</span></p>
    </Link>

    <button 
      className={`${itemIsInBasket ? "bg-black border border-black text-white" : "bg-yellow-400 hover:bg-yellow-300 border border-yellow-500"} rounded-md p-2 px-3 w-full font-semibold`}
      onClick={(e) => {
        e.stopPropagation();
        if (!itemIsInBasket) {
          handleProductSelect(
            product['PRODUCT.CODE'], 
            [product],
            basket, 
            setBasket, 
            setActiveBasketItem, 
            "sale"
          );
        }
      }}
    >
      {itemIsInBasket ? <>Added to Basket&nbsp;&nbsp;<i className="fas fa-check-circle"></i></> : "Add to Basket"}
    </button>
  </>;
}

export default ProductFeature;