import React, { useState, useEffect } from 'react';
import { useEcommerceContext } from './context';
import { Link } from 'react-router-dom';

import { calculateSalesOrderBasketTotals, changeProductQuantity } from '../../components/basketUtils';
import PrimaryButton from './components/primaryButton';

const EcommerceNav = () => {
  const { basket, setBasket } = useEcommerceContext();
  const [basketPreviewOpen, setBasketPreviewOpen] = useState(false);
  const [orderTotals, setOrderTotals] = useState({subtotal: 0 });
  
  useEffect(() => {
    const { subtotal } = calculateSalesOrderBasketTotals(basket);
    setOrderTotals({subtotal});
  }, [basket]);

  const dropdownItem = (icon: string, title: string, subtitle: string, link: string, ) => {
    return (
      <Link className="flex p-2 hover:bg-gray-100 bg-opacity-40 rounded cursor-pointer gap-3 ecommerce-nav-child" to={link}>
        <h3><i className={`fas fa-${icon}`}></i></h3>
        <div>
          <h3 className="font-bold">{title}</h3>
          <p className="text-sm">{subtitle}</p>
        </div>
      </Link>
    )
  }

  const productsItem = (title: string, links: string[]) => (
    <div className="flex flex-col">
      <h3 className="font-bold mb-4">{title}</h3>
      {links.map((link, index) => (
        <Link
          key={index}
          className="text-sm text-gray-700 ecommerce-link mb-1"
          to="#"
        >
          {link}
        </Link>
      ))}
    </div>
  );
  return (
    <>
      <div className="border-b border-gray-200 py-4 bg-white sticky top-0 z-50">
        <div className="container mx-auto flex items-center gap-6">
          <Link to="/ecommerce" className="mr-4">
            <img src="/hdm-logo.webp" alt="Ecommerce" className="h-14" />
          </Link>

          <div className="relative group">
            <h3 className="text-sm py-2 cursor-pointer font-bold">
              Products&nbsp;&nbsp;<i className="fa-solid fa-chevron-down"></i>
            </h3>

            <div className="fixed pt-[26px] left-0 opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 w-full"
            > 
              <div className="container mx-auto bg-white rounded-b-md shadow-lg border border-gray-300 grid md:grid-cols-4 p-8 gap-6"> 
                {productsItem('Solar Panels', [
                  'All Solar Panels',
                  'All-Black Solar Panels',
                  'Black-Framed Solar Panels',
                  'Silver-Framed Solar Panels',
                ])}
                {productsItem('Power Inverters', [
                  'All Inverters',
                  'Hybrid Inverters',
                  'String Inverters',
                  'Microinverters',
                  'AC-Coupled Inverters',
                ])}
                {productsItem('Battery Storage', [
                  'All Batteries',
                  'Low-Voltage Batteries',
                  'High-Voltage Batteries',
                  'DC-Coupled Batteries',
                  'Battery Management Systems',
                ])}
                {productsItem('Battery and Inverter Kits', [
                  'All Battery and Inverter Kits',
                  'All-In One Inverters',
                  'Battery and Inverter Bundles',
                ])}
                {productsItem('Solar Panel Mountings', [
                  'All Solar Panel Mountings',
                  'On-Roof Tile Mountings',
                  'In-Roof Tile Mountings',
                  'Flat-Roof Tile Mountings',
                  'Metal-Roof Tile Mountings',
                  'Ground Mountings',
                  'Bird Protection',
                ])}
                {productsItem('Cables and Accessories', [
                  'All Cables and Accessories',
                  'Cables',
                  'Battery Accessories',
                  'Solar Accessories',
                  'Meters',
                  'Isolators',
                  'Electrical Accessories',
                ])}
                {productsItem('Portable Solar Products', [
                  'All Portable Solar Products',
                  'Portable Solar Panels',
                  'MPPT Charge Controllers',
                  'Portable Inverters',
                  'Portable Batteries',
                  'Accessories',
                ])}
                {productsItem('All Products', [
                  'Special Offers',
                  'View All Products',
                ])}
              </div>
            </div>
          </div>

          <div className="relative">
            <h3 className="text-sm py-2 cursor-pointer group font-bold">
              Solar PV Design&nbsp;&nbsp;<i className="fa-solid fa-chevron-down"></i>
            </h3>

            <div className="absolute pt-[26px] opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50  md:w-[650px] left-[-250px]">
              <div className="bg-white rounded-md shadow-lg border border-gray-300 grid md:grid-cols-2 p-2">
                {dropdownItem('screwdriver-wrench', 'Installation Support', 'Expert guidance to ensure a smooth and efficient solar panel installation', '/ecommerce/installation/support')}

                {dropdownItem('screwdriver-wrench', 'Custom Design Services', 'Tailored solar solutions designed to meet your specific energy needs', '/ecommerce/design/services')}
              </div>
            </div>
          </div>

          <Link to="#" className="font-bold text-sm">Installer Locator</Link>

          <div className="relative">
            <h3 className="text-sm py-2 cursor-pointer group font-bold">
              Resources&nbsp;&nbsp;<i className="fa-solid fa-chevron-down"></i>
            </h3>

            <div className="absolute pt-[26px] opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50  md:w-[650px] left-[-250px]">
              <div className="bg-white rounded-md shadow-lg border border-gray-300 grid md:grid-cols-2 p-2">
                {dropdownItem('info-circle', 'About HDM', 'Discover our mission, values and commitments', '/ecommerce/about')}
                {dropdownItem('users', 'Meet the Team', 'Get to know the professionals behind our solar success', '/ecommerce/team')}
                {dropdownItem('newspaper', 'News', 'The latest developments in the solar industry and company updates', '/ecommerce/news')}
                {dropdownItem('blog', 'Blog', 'Insights, tips and advice on solar energy solutions and innovations', '/ecommerce/blog')}
                {dropdownItem('briefcase', 'Trade Careers', 'Explore exciting career opportunities within the solar industry', '/ecommerce/faqs')}
              </div>
            </div>
          </div>

          <Link to="#" className="font-bold text-sm">Contact</Link>

          <div className="ml-auto flex items-center gap-2 font-bold">
            <i className="fa-solid fa-phone"></i>
            <p>Call us: 0800&nbsp;0016&nbsp;802</p>
          </div>
          <i className="fa-solid fa-search"></i>
          <i className="fa-solid fa-user"></i>
          <div className="relative cursor-pointer" onClick={() => setBasketPreviewOpen(true)}>
            <i className="fa-solid fa-shopping-cart"></i>
            {basket.length > 0 && (
              <div className="absolute -top-3 -right-4 border border-black text-black bg-white font-extrabold text-sm px-1 rounded-full">
                {basket.length}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Basket Preview */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-end transition-opacity duration-300 ${
          basketPreviewOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}
        style={{ marginTop: '89px', height: 'calc(100% - 89px)' }}
      >
        <div
          className={`bg-white max-w-[450px] min-w-[450px] transform transition-transform duration-300 ${
            basketPreviewOpen ? 'translate-x-0' : 'translate-x-full'
          } flex flex-col`}
        >
          <div className="flex justify-between items-center p-4">
            <h2 className="font-bold text-2xl">Basket</h2>
            <i className="fa-solid fa-times cursor-pointer" onClick={() => setBasketPreviewOpen(false)}></i>
          </div>

          {basket.length === 0 
            ? <div className="w-full p-4 flex flex-col items-center">
                <p className="text-center mt-8">Your basket is empty...</p>
                <div onClick={() => setBasketPreviewOpen(false)} className="mt-4 cursor-pointer">
                  <PrimaryButton 
                    buttonText={<><i className="fa-solid fa-arrow-left" />&nbsp;&nbsp;Continue Shopping</>}
                   />
                </div>
              </div>
            : <>
              <div className="mt-4 p-4">
                {basket.map((item, index) => (
                  <div key={index} className="flex items-center gap-4 mb-8">
                    <Link to={`/ecommerce/product/${item.product["PRODUCT.CODE"]}`} className="c">
                      <img src={typeof item.product["PICT.FLAG"] === "string" ? item.product["PICT.FLAG"] || "/product_placeholder.webp" : item.product["PICT.FLAG"][0] || "/product_placeholder.webp"} alt={item.product.DESCRIPTION} className="min-w-24 max-w-24" />
                    </Link>
                    <div>
                      <Link to={`/ecommerce/product/${item.product["PRODUCT.CODE"]}`}>
                        <p className="font-bold">{item.product.DESCRIPTION}</p>
                      </Link>
                      <div className="flex items-center gap-4 mt-4">
                        <p className="min-w-[85px]">£{item.basketPrice}</p>
                        <div className="flex justify-between items-center gap-4 border border-gray-300 rounded-lg">
                          <button
                            onClick={() => changeProductQuantity("decrease", item.product["PRODUCT.CODE"], basket, setBasket)}
                            disabled={item.quantity === 1}
                            className="border-r border-gray-300 px-2 text-xl"
                          >
                            -
                          </button>
                          <p>{item.quantity || 1}</p>
                          <button 
                            onClick={() => changeProductQuantity("increase", item.product["PRODUCT.CODE"], basket, setBasket)}
                            className="border-l border-gray-300 px-2 text-xl"
                          >
                            +
                          </button>
                        </div>

                        <p 
                          className="hover:underline underline-offset-2 cursor-pointer"
                          onClick={() => {
                            setBasket(basket.filter((basketItem) => basketItem.product["PRODUCT.CODE"] !== item.product["PRODUCT.CODE"]));
                          }}
                        >
                          Remove
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="px-4 pt-4 pb-6 border-t border-gray-300 mt-auto flex flex-col">
                <h3 className="mb-8">Taxes and shipping calculated at checkout.</h3>

                <Link 
                  className="mt-4 bg-black rounded-lg text-white p-2 mt-auto text-center" to="/ecommerce/checkout"
                  onClick={() => setBasketPreviewOpen(false)}
                >
                  Checkout&nbsp;-&nbsp;{orderTotals.subtotal > 0 && `£${orderTotals.subtotal}`}
                </Link>

                <button
                  className="mt-2 border border-black rounded-lg p-2"
                  onClick={() => {
                    setBasket([]);
                    setBasketPreviewOpen(false);
                  }}
                >
                  Clear Basket
                </button>
              </div>
            </>
          }
        </div>
      </div>
    </>
  );
};

export default EcommerceNav;