import React, { useState, useEffect, Key } from 'react';
import { useParams } from 'react-router-dom';
import { useScreen } from '../context';

import { fetchSupplier, createSupplier, updateSupplier } from '../api';

import CustomerAndSupplierNotes from '../components/widgets/newNoteModal';
import SimpleMetricCard from '../components/widgets/simpleMetricCard';
import BackButton from '../components/backLink';
import ProductsSearchModal from '../components/productsSearchModal';

import { ContactData, Supplier, Product } from '../types';
import ContactsGrid from '../components/contactsList';
import { contactsData } from '../dummyData';
import TimelineUserComment from '../components/timeline/userComment';
import HistoryTimeline from '../components/timeline/timeline';

const SuppliersCard = () => {
  const { id: supplierId } = useParams<{ id: string }>();
  const newSupplier = supplierId === "new";

  const tabOptions = ["Overview", "Financials", "Contacts", "Products & Terms"];
  const [tab, setTab] = useState(tabOptions[0]);
  const [fetchingData, setFetchingData] = useState(true);

  const { setScreenId, setPageHasDirtyForm, addNotification } = useScreen();
  setScreenId(`PO- ${supplierId}`)

  const [originalData, setOriginalData] = useState({} as Supplier);
  const [supplierData, setSupplierData] = useState({} as Supplier);
  const [productTerms, setProductTerms] = useState([] as { groupName: string,  gold: number, silver: number, bronze: number, products: Product[] }[]);
  const [selectedTermsGroup, setSelectedTermsGroup] = useState("" as string);
  const [newTermsGroupName, setNewTermsGroupName] = useState("" as string);
  const [productSearchModalOpen, setProductSearchModalOpen] = useState(false);

  const [contacts, setContacts] = useState<ContactData[]>(contactsData);

  useEffect(() => {
    if (newSupplier || !supplierId) {
      setFetchingData(false);
      return
    }
    
    const fetchData = async () => {
      try {
        const data = await fetchSupplier(supplierId);
        if (!data.length) {
          addNotification("Supplier not found.", "error");
          return;
        }

        setSupplierData(data[0]);
        setOriginalData(data[0]);
        console.log(data);
      } catch (error) {
        console.error('There was a problem fetching accounts:', error);
      }
      setFetchingData(false);
    };

    fetchData();
  }, [supplierId]);

  const inputVariable = (label: string, valueKey: keyof Supplier) => {
    return <>
      <div className="label">{label}</div>
      <input 
        type="text" 
        className="input outline-none" 
        placeholder={`${label}...`} 
        value={supplierData[valueKey]} 
        onChange={(e) => {
          setPageHasDirtyForm(true);
          setSupplierData({ ...supplierData, [valueKey]: e.target.value })
        }}
      />
    </>
  }

  const attemptSave = async () => {
    try {
      let createResponse = await createSupplier(supplierData);
      console.log(createResponse);
      
      if (!createResponse) {
        addNotification("There was a problem saving the supplier. Please try again.", "error");
        return;
      }
      setOriginalData(supplierData);
      setPageHasDirtyForm(false);
      addNotification("Supplier created successfully.", "success");
    } catch (error) {
      addNotification("There was a problem saving the supplier. Please try again.", "error");
      console.error('There was a problem saving the supplier:', error);
    }
  }

  const attemptUpdate = async () => {
    try {
      if (!supplierId) return;

      let originalSupplierPayload = {} as any;
      let updatedSupplierPayload = {} as any;

      for (let key in originalData) {
        if (originalData[key as keyof Supplier] !== supplierData[key as keyof Supplier]) {
          originalSupplierPayload[key] = originalData[key as keyof Supplier];
          updatedSupplierPayload[key] = supplierData[key as keyof Supplier];
        }
      }

      console.log(originalSupplierPayload, updatedSupplierPayload);

      if (!Object.keys(originalSupplierPayload) || Object.keys(originalSupplierPayload).length === 0) {
        addNotification("No changes detected.", "error");
        return;
      }

      let updateResponse = await updateSupplier(supplierId, originalSupplierPayload, updatedSupplierPayload);
      console.log(updateResponse);

      if (!updateResponse) {
        addNotification("There was a problem updating the supplier. Please try again.", "error");
        return;
      }

      setOriginalData(supplierData);
      setPageHasDirtyForm(false);
      addNotification("Supplier updated successfully.", "success");
    } catch (err: any) {
      console.log(err)
      addNotification(err.message, "error");
    }
  }

  if (fetchingData) {
    return (
      <div className="dashboard-card w-full h-full p-4 flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center flex-grow">
          <i className="fa-sharp fa-circle-notch fa-spin text-4xl"></i>
          <h3 className="font-semibold text-lg md:text-2xl ml-2">Fetching data...</h3>
        </div>
      </div>
    );
  }

  return (
  <div className="flex flex-col items-start gap-4">
    {productSearchModalOpen && 
      <ProductsSearchModal 
        basket={[]}
        setBasket={() => {}}
        setActiveBasketItem={() => {}}
        activeBasketItem={null}
        modalIsOpen={productSearchModalOpen}
        setModalOpen={setProductSearchModalOpen}
        setRelatedProducts={(products) => {
          const newProductTerms = [...productTerms];
          const group = newProductTerms.find(group => group.groupName === selectedTermsGroup);
          if (group) {
            group.products = products as Product[];
            setProductTerms(newProductTerms);
          }
        }}
        relatedProducts={selectedTermsGroup === "" ? [] : productTerms.find(group => group.groupName === selectedTermsGroup)?.products}
        screen="related"
        supplier={supplierData ? supplierData.NAME : ""}
      />
        }

        <div className="flex items-center justify-center gap-4 w-full">
      <div>
        <BackButton />

        <h2 className="font-semibold text-2xl">
          {newSupplier 
        ? "New Supplier"
        : supplierData.NAME
          }
        </h2>
      </div>
      

      {newSupplier 
        ? <>
        <button
          className="btn btn-secondary ml-auto"
          onClick={() => {
            window.location.href = "/maintenance";
          }}
        >
          Discard
        </button>

        <button
          className="btn btn-primary"
          onClick={attemptSave}
        >
          Create Supplier
        </button>
          </>
        : <button
        className="btn btn-primary ml-auto"
        onClick={ attemptUpdate}
        disabled={fetchingData || (JSON.stringify(originalData) === JSON.stringify(supplierData))}
          >
        Save
          </button>
      }
        </div>

    <div className="grid md:grid-cols-2 w-full gap-4">
      <SimpleMetricCard
        identifier='Total'
        metric='Orders'
        count={0}
        change={null}
        period='year'
        icon='cart-shopping'
        fetching={fetchingData}
        theme='blue'
      />

      <SimpleMetricCard
        identifier='Total'
        metric='Spend'
        count={0}
        change={null}
        period='year'
        icon='cash-register'
        fetching={fetchingData}
        theme='blue'
      />
    </div>

    <div className="grid md:grid-cols-3 w-full gap-4">
      <div className="col-span-2 dashboard-card">
        <div className="flex px-4 pt-3 bg-blue text-white gap-2 rounded-t-md">
          {tabOptions.map((option, index) => (
            <button 
              key={index} 
              className={`px-2 pb-1 font-bold border-b-4 text-white ${tab === option ? 'border-white' : 'border-primary'}`} 
              onClick={() => setTab(option)}
            >
              {option}
            </button>
          ))}
        </div>

        {fetchingData 
          ? <div className="flex flex-col justify-center items-center flex-grow py-12">
              <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-4xl"></i>
            </div>
          : <>
              {tab === "Overview" && <div className="form-container p-4 w-full">
                <div className="label">Supplier Code</div>
                <input 
                  type="text" 
                  className="input outline-none" 
                  placeholder="Supplier Code..." 
                  value={supplierId}
                  disabled={true}
                />

                {inputVariable("Supplier Name", "NAME")}

                <div className="label">Address</div>
                <textarea 
                  className="input outline-none p-4 flex-grow" 
                  placeholder="Address..." 
                  rows={4}
                  value={typeof supplierData.ADDRESS === "string" 
                    ? supplierData.ADDRESS 
                    : supplierData.ADDRESS !== undefined
                      ? supplierData.ADDRESS.join('\n')
                      : undefined
                  }
                  onChange={(e) => {
                    setPageHasDirtyForm(true);
                    setSupplierData({ ...supplierData, ADDRESS: e.target.value.split('\n') })
                  }}
                />

                {inputVariable("Postcode", "SUPP.POSTCODE")}
                {inputVariable("VAT Number", "VAT")}
                {inputVariable("A/C Number", "OUR.ACCOUNT")}
                {inputVariable("Currency", "CURRENCY")}
                {inputVariable("Lead Time", "LEAD")}
                {inputVariable("Phone", "PHONE")}
                {inputVariable("Email", "PURCH.EMAIL")}
                {inputVariable("Contact", "CONTACT")}
              </div>}

              {tab === "Financials" && <div className="form-container p-4 w-full">
                  {inputVariable("Bank Name", "BEN.NAME")}
                  {inputVariable("Bank Account", "BEN.ACCOUNT")}
                  {inputVariable("Bank Ref", "BEN.REF")}
                  {inputVariable("Bank Type", "BEN.TYPE")}
                  {inputVariable("Settlement Terms", "SETTLEMENT")}
                  {inputVariable("Payment Terms", "SUPP.TERMS")}
              </div>}

              {tab === "Contacts" && <ContactsGrid contacts={contacts} setContacts={setContacts}/>}

              {(tab === "Products & Terms") && 
                <>{selectedTermsGroup === ""
                  ? <div className="flex flex-col gap-4 w-full p-4">
                      <h3 className="font-semibold text-2xl">Terms Groups</h3>

                      {productTerms.length > 0 
                        ? productTerms.map((group, index) => (
                            <div 
                              key={index} 
                              className="dashboard-card border border-gray-300 p-4 hover:bg-gray-100 cursor-pointer"
                              onClick={() => setSelectedTermsGroup(group.groupName)}
                            >
                              <h2 className="text-lg font-semibold">{group.groupName}</h2>
                              <p className="text-sm">{group.products.length} items</p>
                            </div>
                        ))
                        : <h2>This supplier has no terms groups, create your first below:</h2>
                      }

                      <h3 className="font-semibold text-lg mt-4">Create New Terms Group</h3>
                      <div className="flex items-center gap-4 w-full">
                        <input
                          type="text"
                          className="input outline-none border border-gray-300 py-3 px-2 flex-grow h-full w-"
                          placeholder="New Terms Group..."
                          value={newTermsGroupName}
                          onChange={(e) => setNewTermsGroupName(e.target.value)}
                        />
                        <button 
                          className="btn btn-primary"
                          onClick={() => {
                            setProductTerms([...productTerms, { groupName: newTermsGroupName, gold: 0, silver: 0, bronze: 0, products: [] }]);
                            setSelectedTermsGroup(newTermsGroupName);
                            setNewTermsGroupName("");
                          }}
                          disabled={newTermsGroupName === ""}
                        >
                          Create Group
                        </button>
                      </div>
                    </div>
                  : <div className="p-4">
                      <p 
                        className="text-sm cursor-pointer hover:underline"
                        onClick={() => setSelectedTermsGroup("")}
                      >
                        <i className="fas fa-arrow-left"></i>&nbsp;
                        Back to Terms Groups
                      </p>
                      <h3 className="font-semibold uppercase text-lg mt-2">{selectedTermsGroup}</h3>

                      <div className="grid md:grid-cols-3 gap-4 my-8">
                        <div>
                          <div className="flex items-center gap-2 font-semibold">
                            <i className="fa-regular fa-medal text-yellow-600 text-2xl"></i>
                            Gold Discount:
                          </div>

                          <div className="flex items-center border border-gray-300 rounded-lg mt-2">
                            <div className="px-3 py-2 border-r border-gray-300">
                              %
                            </div>
                            <input 
                              type="number"
                              className="input outline-none border-none h-full px-2"
                              value={productTerms.find(group => group.groupName === selectedTermsGroup)?.gold}
                              onChange={(e) => {
                                let newProductTerms = [...productTerms];
                                newProductTerms.find(group => group.groupName === selectedTermsGroup)!.gold = parseFloat(e.target.value);
                                setProductTerms(newProductTerms);
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="flex items-center gap-2 font-semibold">
                            <i className="fa-regular fa-medal text-gray-600 text-2xl"></i>
                            Silver Discount:
                          </div>

                          <div className="flex items-center border border-gray-300 rounded-lg mt-2">
                            <div className="px-3 py-2 border-r border-gray-300">
                              %
                            </div>
                            <input 
                              type="number"
                              className="input outline-none border-none h-full px-2"
                              style={{ width: "calc(100% - 2rem)" }}
                              value={productTerms.find(group => group.groupName === selectedTermsGroup)?.silver}
                              onChange={(e) => {
                                let newProductTerms = [...productTerms];
                                newProductTerms.find(group => group.groupName === selectedTermsGroup)!.silver = parseInt(e.target.value);
                                setProductTerms(newProductTerms);
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="flex items-center gap-2 font-semibold">
                            <i className="fa-regular fa-medal text-orange-800 text-2xl"></i>
                            Bronze Discount:
                          </div>

                          <div className="flex items-center border border-gray-300 rounded-lg mt-2">
                            <div className="px-3 py-2 border-r border-gray-300">
                              %
                            </div>
                            <input 
                              type="number"
                              className="input outline-none border-none h-full px-2"
                              style={{ width: "calc(100% - 2rem)" }}
                              value={productTerms.find(group => group.groupName === selectedTermsGroup)?.bronze}
                              onChange={(e) => {
                                let newProductTerms = [...productTerms];
                                newProductTerms.find(group => group.groupName === selectedTermsGroup)!.bronze = parseInt(e.target.value);
                                setProductTerms(newProductTerms);
                              }}
                            /> 
                          </div>
                        </div>
                      </div>

                      <h3 className="font-semibold">Products in this group</h3>
                      
                      <div className="flex flex-col gap-4 mt-4">
                        {productTerms.find(group => group.groupName === selectedTermsGroup)?.products.map((product, index) => (
                          <div key={index} className="flex items-center gap-4 p-4 dashboard-card">
                            <div>
                              <h3 className="text-gray-500 text-sm">{product["PRODUCT.CODE"]}</h3>
                              <h3 className="font-semibold text-sm">{product.DESCRIPTION}</h3>
                            </div>
                          </div>
                        ))}

                        <button
                          className="btn btn-primary mt-4"
                          onClick={() => setProductSearchModalOpen(true)}
                        >
                          Manage Products
                        </button>
                      </div>
                    </div>
                }</>
              }
            </>
        }
      </div>

      <div className="flex flex-col gap-4">
        <div className="dashboard-card">
          <div className="card-header bg-blue">
            <i className="fas fa-sticky-note"></i>
            <h3 className="font-semibold uppercase text-sm">Supplier Notes</h3>
          </div>
          
          <CustomerAndSupplierNotes
            note={supplierData.COMMENTS}
            setNote={(note: string[]) => {
              setPageHasDirtyForm(true);
              setSupplierData({ ...supplierData, COMMENTS: note })
            }}
            theme='blue'
          />
        </div>

        <div className="dashboard-card">
          <div className="card-header bg-blue">
            <i className="fas fa-history"></i>
            <h3 className="font-semibold uppercase text-sm">Activity History</h3>
          </div>
          
          <div className="p-4">
            {/* <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created supplier record.</p> */}
            <HistoryTimeline screenId={''} dataId={''} theme={'blue'}/>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default SuppliersCard;