import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useScreen } from '../context';
import { Logout } from '../auth';
import LinkWithDirtyProtection from './linkWrapper';

const Sidenav = () => {
  const [open, setOpen] = useState(false);
  const { screenId, navCollapsed, toggleNavCollapsed, setLiveChatOpen } = useScreen();

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <div 
        className={`sidenav sticky md:fixed border-r border-gray-300 flex-grow pb-2 text-white flex flex-col top-0 md:h-screen w-screen bg-[#1A1A1A] bg-opacity-90 z-50`}
        style={{ width: navCollapsed ? '80px' : '200px' }}
      >
        <div className="flex items-center justify-center border-b border-gray-300 md:min-h-[100px]">
          <img src="/hdm-logo.webp" className="h-12" alt="logo" />
        </div>

        {/*
        <div className={`flex ${navCollapsed ? "justify-center" : "justify-between ml-2"}`}>
          <button
            onClick={() => {
              setOpen(!open)
              toggleNavCollapsed()
            }}
          >
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        */}

        <div className={`${open ? "flex flex-col" : "hidden md:flex flex-col h-full"}`} data-testid="sidenav-main-contents">

          <div className="flex flex-col mt-4">
          {[
              { title: 'Dashboard', link: '/', icon: 'home' },
              //{ title: 'Surge', link: '/surge', icon: 'chart-line' },
              { title: 'Maintenance', link: '/maintenance', icon: 'wrench' },
              { title: 'Products', link: '/products', icon: 'box' }, 
              { title: 'Purchasing', link: '/purchasing', icon: 'cart-shopping' },
              { title: 'Sales', link: '/sales', icon: 'basket-shopping' },
              { title: 'Reporting', link: '/reporting', icon: 'file-chart-column' },
              { title: 'System Config', link: '/config', icon: 'cog' },
              { title: 'Users & Roles', link: '/users', icon: 'user-group-crown'  }
            ].map((child, index) => (
              <LinkWithDirtyProtection
                to={child.link}
                className={`flex items-center py-2 pl-2 mx-3 cursor-pointer ${currentPath === child.link ? "btn-secondary" : "hover:bg-white hover:text-black"} ${navCollapsed ? "justify-center" : ""}`}
                key={index}
              >
                <div className="flex justify-center w-6">
                  <i className={`fa-solid fa-${child.icon} ${navCollapsed ? "text-lg" : ""}`}></i>
                </div>

                <p className={`${navCollapsed ? "hidden" : ""}`}>&nbsp;&nbsp;{child.title}</p>
              </LinkWithDirtyProtection>
            ))}
          </div>

          <div
            className={`flex gap-4 items-center py-2 pl-6 cursor-pointer bg-opacity-10 mt-auto ${navCollapsed ? "justify-center" : ""} hover:bg-white hover:text-black`}
            onClick={() => setLiveChatOpen(true)}
          >
            <p>
              <i className={`fa-solid fa-message-bot ${navCollapsed ? "text-lg" : ""}`}></i>
              <span className={`${navCollapsed ? "hidden" : ""}`}>&nbsp;&nbsp;AI Support</span>
            </p>
          </div>

          <div className={`h-[1px] w-full bg-white bg-opacity-60 my-2 ${navCollapsed ? "hidden" : "block"}`} />

          <p className="text-center text-[9px] text-muted my-2 uppercase">Screen {screenId}</p>
          
          <div className={`flex border-t border-gray-300 items-center shrink-0 ${navCollapsed ? "justify-center mt-2" : "gap-4 px-4 pt-4 pb-2"}`}>
            <img src="/tayah.jpg" className="rounded-full h-10 w-10" alt="profile" />
            
            <div className={`${navCollapsed ? "hidden" : "block"}`}>
              <p>Ethan</p>
              <p className="text-muted text-xs">H3O</p>
            </div>
            

            <button onClick={() => Logout()} className={`${navCollapsed ? "hidden" : "ml-auto"}`}><i className="fa-solid fa-arrow-right-from-bracket"></i></button>
          </div>
        </div>
      </div>

      <div style={{ minWidth: navCollapsed ? '85px' : '200px' }} />
    </>
  );
}

export default Sidenav;