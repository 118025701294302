import React, { useState, useEffect } from 'react';
import { Login } from '../auth';
import { attemptLogin } from '../api';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [attemptingSignIn, setAttemptingSignIn] = useState(false);

  console.log("??????")

  const handleLogin = async () => {
    try {
      console.log("HERE")
      setAttemptingSignIn(true);
      setError('');
      
      if (!email || !password) {
        setError('Email and password are required.');
        setAttemptingSignIn(false);
        return;
      }

      console.log("Reaching here!")
      let loginAttempt = await attemptLogin(email, password);
      console.log("Login attempt response:", loginAttempt);

      if (!loginAttempt.success) {
        setError(loginAttempt.message);
        setAttemptingSignIn(false);
        return;
      }
      
      Login();
      setAttemptingSignIn(false);

      // Send user to home page
      window.location.href = '/';
    }
    catch (e) {
      console.error(e);
      setAttemptingSignIn(false);
      setError('An error occurred while attempting to sign in. Please try again.');
    }
  }
  return (
    <div className="h-screen w-screen grid md:grid-cols-2 bg-white">
      <img src="/login-hero.jpeg" alt="login" className="object-cover w-full h-full" />

      <div className="flex flex-col gap-2 justify-center items-center p-4 md:p-12">

        <img src="/hdm-logo.webp" alt="logo" className="h-20" />

        <h3 className="text-2xl font-bold text-gray-800 mt-6  text-center">Sign In</h3>
        <p className="text-gray-600  text-center">Please enter your email and password below.</p>

        <div className="md:max-w-md mt-12">
          <input className="w-full p-2 border border-gray-200 rounded-md" type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />

          <input className="w-full p-2 border border-gray-200 rounded-md my-4" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />

          <button 
            onClick={handleLogin}
            className="w-full bg-blue text-white p-2 rounded-md font-semibold"
            disabled={attemptingSignIn}
          >
            {attemptingSignIn ? <i className="fas fa-spinner fa-spin mr-2"></i> : <>Sign In</>}
          </button>

          {error && <p className="text-red-600 mt-4">{error}</p>}
        </div>

        <p className="text-gray-600 mt-4">Don't have an account? <a href="#" className="text-red-600">Sign Up</a></p>

      </div>
    </div>
  );
}

export default LoginPage;