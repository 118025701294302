import { useEffect } from 'react';
import { fetchProducts, fetchRoleByKey } from './api';
import { PermissionHierarchy, Product, Sort, TriState, User, UserPermissions, UserRole } from './types';

export const ucFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type KeyPressAction = () => void;

export const useKeyPress = (targetKey: string, action: KeyPressAction): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      console.log(event.key);
      if (event.key === targetKey) {
        event.preventDefault(); // Prevent default browser behavior if needed
        action(); // Execute the provided callback function
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [targetKey, action]);
};

export const fetchProductData = async () => {
  const data: Product[] = await fetchProducts();

  const products = data.filter((product: Product) => !product["PRODUCT.CODE"].includes("(") && !product["PRODUCT.CODE"].includes(")"));

  const productCategories: string[] = [];

  products.forEach((product: Product) => {
    if (!product["PRODUCT.CODE"] || product["PRODUCT.CODE"] === "") {
      console.log("Product code missing for product:", product);
    }

    product["Total Stock"] = (
      product["BOURNEMOUTH"] && product["BOURNEMOUTH"] !== "" ? parseInt(product["BOURNEMOUTH"]) : 0
      ) + (
      product["SHEFFIELD"] && product["SHEFFIELD"] !== "" ? parseInt(product["SHEFFIELD"]) : 0
      ) + (
      product["HULL"] && product["HULL"] !== "" ? parseInt(product["HULL"]) : 0
    );

    if (!productCategories.includes(product["PGROUP.DESC"])) productCategories.push(product["PGROUP.DESC"]);
  });

  return { products, productCategories }
}

export const subtractPermissions = (permissions: UserPermissions, subtracting: UserPermissions): UserPermissions => {
  for (const permKey in permissions) {
    if (permissions[permKey] !== undefined && subtracting[permKey] === permissions[permKey]) {
      //Already exists and matches so subtract by undefining this as nothing is being overriden.
      permissions[permKey as keyof UserRole] = undefined;
    }
  }

  return permissions;
}

export const combinePermissions = (permissions?: UserPermissions, override?: UserPermissions): UserPermissions => {
  if (!override) return permissions ?? {} as UserPermissions;
  if (!permissions) return override;
  let newPerms = {} as UserPermissions;
  const permsKeys: (keyof UserPermissions)[] = Object.keys(permissions);
  const addingKeys: (keyof UserPermissions)[] = Object.keys(override);
  const totalPermsKeys = permsKeys.concat(addingKeys.filter((perm) => !permsKeys.includes(perm)));

  for (const permKey of totalPermsKeys ) {
    const perm = permissions[permKey]
    const overridePerm = override[permKey]

    if (perm === undefined) newPerms[permKey] = overridePerm;
    else if (overridePerm === undefined) newPerms[permKey] = perm
    //Prefer override
    else newPerms[permKey] = override[permKey];
  }

  return newPerms;
}

export const unpackD3PermissionsToPermissionObj = (permissionNodes: string[], permissionValues: string[], role?: UserRole): UserPermissions => {
  //We don't care about the structure but need to unpack it anyways
  let permissions: UserPermissions = {} as UserPermissions
  const valueMapping: {[key: string]: TriState} = {"Y": true, "N": false, "U": undefined}

  if (!permissionNodes) {
    const error: string = "Permission nodes not provided!";
    console.error(error);
    return {} as UserPermissions;
  }

  if (permissionNodes.length !== permissionValues.length) {
    const error = "Permission nodes and permission values do not match!";
    console.error(error);
    throw new Error(error);
  }

  for (let i = 0; i < permissionNodes.length; i++) {
    //The last permission at the end of the node will be the actual permission name.
    const permValue = permissionValues[i];
    const permission = permissionNodes[i].split('*').reverse()[0];
    let value: TriState = Object.keys(valueMapping).includes(permValue) ? valueMapping[permValue] : undefined;
    permissions[permission] = value;
  }
  
  //If a role has been provided, subtract that role's permissions from this one to get the user overrides.
  if (role) permissions = subtractPermissions(permissions, role.permissions);

  return permissions;
}

export const triStateToD3 = (triState: TriState) => {
  return triState ? "Y" : (triState === undefined ? "U" : "N");
}

function recurseUserPermissionsToD3(permissionsHierarchy: PermissionHierarchy, currentD3Path: string, permissionNodes: string[], userPermissions?: UserPermissions, permissionValues?: string[]) {
  for (const key of Object.keys(permissionsHierarchy)) {
    const pathAddition = (currentD3Path ? "*" : "") + key;
    permissionNodes.push(currentD3Path + pathAddition);
    if (userPermissions && permissionValues) permissionValues.push(triStateToD3(userPermissions[key]))
      
    if (Object.keys(permissionsHierarchy[key]).length !== 0) {
      recurseUserPermissionsToD3(permissionsHierarchy[key], currentD3Path + pathAddition, permissionNodes, userPermissions, permissionValues);
    }
  }
}

export const packPermissionsObjToD3Permissions = (permissionsTree: PermissionHierarchy, permissionsObj?: UserPermissions) => {
  if (!permissionsTree || Object.keys(permissionsTree).length < 1) return {permissionNodes: [], permissionValues: []}
  let permissionNodes: string[] = [];
  let permissionValues: string[] = [];
  recurseUserPermissionsToD3(permissionsTree, "", permissionNodes, permissionsObj, permissionsObj ? permissionValues : undefined);

  return {permissionNodes: permissionNodes, permissionValues: permissionValues};
}

export const convertD3RoleToWTRole = (d3Data: any): UserRole => {
  let role: UserRole = {} as UserRole;

  role.userRoleId = d3Data["KEY"];
  role.name = d3Data["NAME"];
  role.description = d3Data["DESCRIPTION"];
  role.businessArea = d3Data["AREA"];
  role.permissions = unpackD3PermissionsToPermissionObj(d3Data["PERMISSION.NODE"], d3Data["PERMISSION.VALUE"]);

  return role;
}

export const convertD3UserToWTUser = async (d3Data: any): Promise<User> => {
  let user: User = {} as User;

  user.userId = d3Data["KEY"];
  user.username = d3Data["NAME"];
  user.userEmailAddress = d3Data["EMAIL"];
  user.orderLimits = {
    invoice: d3Data["INVOICE.LIMIT"],
    quote: d3Data["QUOTE.LIMIT"],
    sales: d3Data["SALES.ORDER.LIMIT"],
    purchase: d3Data["PURCHASE.ORDER.LIMIT"]
  };
  user.pronouns = d3Data["PRONOUNS"];
  user.profileImage = d3Data["PROFILE.IMAGE"];
  user.isSuperUser = d3Data["SUPER.USER"] === "Y";

  const matchingRoleData: UserRole | undefined = await fetchRoleByKey(d3Data["ROLE"]);

  if (matchingRoleData) {
    user.role = matchingRoleData;
    user.permissionOverrides = unpackD3PermissionsToPermissionObj(d3Data["PERMISSION.NODE"], d3Data["PERMISSION.VALUE"], user.role);
  }

  return user;
}


export const sortAlphabeticallyWithNonAlphaAtEnd = (object: any[], sortKey: string) => {
  return object.sort((a: any, b: any) => {
    const regex = /^[a-zA-Z]/;

    const aValue = a[sortKey] as unknown as string;
    const bValue = b[sortKey] as unknown as string;

    const aStartsWithLetter = regex.test(aValue);
    const bStartsWithLetter = regex.test(bValue);

    if (aStartsWithLetter && !bStartsWithLetter) {
      return -1; // a comes before b
    } else if (!aStartsWithLetter && bStartsWithLetter) {
      return 1; // b comes before a
    } else {
      return aValue.localeCompare(bValue);
    }
  });
};

export const clamp = (min: number, max: number, clamping: number) => clamping < min ? min : clamping > max ? max : clamping;

export const formatNumber = (number: number) => {
  if (!number) return 0;
  if (number > 1000000) {
    return `${(number / 1000000).toFixed(1)}M`;
  }
  if (number > 1000) {
    return `${(number / 1000).toFixed(0)}K`;
  }
  return number.toLocaleString();
}

export const getDateInD3Format = (date: Date) => {
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short', timeZone: 'UTC' });
  const year = String(date.getUTCFullYear());
  
  console.log("Output date:", `${day} ${month} ${year}`);
  return `${day} ${month} ${year}`;
}

export const unwrapD3DateToISO = (d3Date: string) => {
  const date = new Date(`${d3Date} UTC`);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const convertDateObjToInputTagReadable = (date: Date, includeTime: boolean = false) => {
  //Year month day part from Claude AI
  // Get year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Combine the values into a YYYY-MM-DD string
  let dateTime = `${year}-${month}-${day}`;
  if (!includeTime) return dateTime;

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const milliseconds = date.getMilliseconds().toString().padEnd(3, "0");

  return `${dateTime} ${hours}:${minutes}:${seconds}.${milliseconds}`
}

export const getDateFromD3DateAndTime = (dateString: string, timeString: string) => {
  // Convert "02 Aug 24" to a Date object
  const [day, month, year] = dateString.split(' ');

  // Add the time to the date (in format "HH:MM:SS")
  const [hours, minutes, seconds] = timeString.split(':');
  const date = new Date(`${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`);
  return date;
};

export const getTimeInD3Format = (date: Date) => {
  return date.toTimeString().split(' ')[0];
}

export const formatChangedDataForWrite = (OriginalValues: any, newValues: any) => {
  let originalPayload = {} as any;
  let updatedPayload = {} as any;

  for (let key in OriginalValues) {
    if (OriginalValues[key] !== newValues[key]) {
      originalPayload[key] = OriginalValues[key];
      updatedPayload[key] = newValues[key];
    }
  }


  if (!Object.keys(originalPayload) || Object.keys(originalPayload).length === 0) {
    console.log("No changes detected");
    return null;
  }

  return { originalPayload, updatedPayload };
}

export const formatNumberAsPrice = (number: number) => {
  return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export const getTotalStockForProduct = (product: Product) => {
  let totalStock = 0;
  let bournemouthStock = product["BOURNEMOUTH"] && product["BOURNEMOUTH"] !== "" ? parseInt(product["BOURNEMOUTH"]) : 0;
  let sheffieldStock = product["SHEFFIELD"] && product["SHEFFIELD"] !== "" ? parseInt(product["SHEFFIELD"]) : 0;
  let hullStock = product["HULL"] && product["HULL"] !== "" ? parseInt(product["HULL"]) : 0;

  return totalStock += bournemouthStock + sheffieldStock + hullStock;

}

export const isPhoneNumber = (phoneNo: string): boolean => {
  //https://stackoverflow.com/a/16699507 , modified by me to account for 3 digit country codes
  //Matches an optional country code ( (\+\d{1,3}\s)? ) followed by a set of 3 digits ( \d{3} ) encase in optional brackets ( \(?\d{3}\)? ), a set of 3 digits again, and a set of 4 digits ( \d{4} ), each with an optional space, . or dash between ( [\s.-] )
  //TODO: do in a way that doesn't involve Regex because it's icky and yucky, I'm just in a hurry right now.
  //const regex = new RegExp(/^(\+\d{1,3}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
  if (phoneNo.length > 21 || phoneNo.length < 11) return false;
  for (const phoneNoChar of phoneNo) {
    if (!'1234567890+(). '.includes(phoneNoChar)) return false;
  }
  return true;
  //return regex.test(phoneNo);
}

export const onSameDay = (date1: Date, date2: Date): boolean => { 
  return (
          date1.getFullYear() === date2.getFullYear() && 
          date1.getMonth() === date2.getMonth() && 
          date1.getDate() === date2.getDate()
         )
}


export function getSortByKey<T>(sorts: Sort<T>[], key: keyof T): Sort<T> | undefined {
  return sorts.filter((value) => value.key === key)[0];
}

export function setSortOn<T>(sorts: Sort<T>[], key: keyof T, asc?: boolean, subKey?: string): Sort<T>[] {
  //Cycle from true, false, undefined if undefined. Default to true if no existing sort exists (coalesce with false so it is inverted into true).
  let finalAsc: boolean | undefined = true;
  const existingValue: Sort<T> | undefined =  getSortByKey(sorts, key);
  const existingAsc: boolean | undefined = existingValue?.asc;
  if (asc !== undefined) finalAsc = asc;
  else if (existingAsc === false) return unSortOn(sorts, key);
  else finalAsc = !existingValue;

  //Create new arrays because React
  if (sorts.map((value) => value.key).includes(key)) return [...sorts.filter((value) => value.key !== key), {key: key, asc: finalAsc, subKey: existingValue?.subKey}];
  else sorts.push({key: key, asc: finalAsc, subKey: subKey});

  return [...sorts];
}

export function unSortOn<T>(sorts: Sort<T>[], key: keyof T): Sort<T>[] {
  return [...sorts.filter((value) => value.key !== key)];
}

function traverseObj<T>(obj: T, keyPath: string): any {
  const splitPath: string[] = keyPath.split('.');
  let currObj: any = obj;

  for (let i = 0; i < splitPath.length; i++) {
    currObj = currObj[splitPath[i]];
  }

  return currObj;
}

export function sort<T>(objSorting: T[], sorts: Sort<T>[]): T[] {
  if (sorts.length < 1) return objSorting;
  
  //Get copy of objSorting as the result
  let result: T[] = Array.from(objSorting)

  result.sort((a: T, b: T): number => {
    let sortNum = 0;
    for (const sortObj of sorts) {
      //If there is a subkey defined, traverse the object by that subkey to get the correct value.
      const aValue: any = sortObj.subKey ? traverseObj<T>(a, (sortObj.key as string) + '.' + sortObj.subKey) : a[sortObj.key];
      const bValue: any = sortObj.subKey ? traverseObj<T>(b, (sortObj.key as string) + '.' + sortObj.subKey) : b[sortObj.key];

      if (typeof aValue === "number") sortNum = (bValue as number) - (aValue as number);
      else if (aValue instanceof Date) sortNum = (bValue as Date).valueOf() - (aValue as Date).valueOf()
      else sortNum = (bValue as string).localeCompare((aValue as string));

      if (sortObj.asc) sortNum = -sortNum;
      //If a sort is equal, continue loop onto the next sort object to further narrow down the sort
      if (sortNum !== 0) break;
      //Otherwise, break and return the correct sort number (negative if left should be before right, vice versa)
      //If last sort is equal, then return the sort number for equal (0)
      //TODO: Don't forget to check the typeof their object. Treat null/falseys as always first/last depending on ascending or descending - a really big negative order number
    }

    return sortNum;
  })

  return result;
}