interface LoadingStateProps {
  whatAreYouLoading: string // Will be used "Loading [whatAreYouLoading]..."
}

const LoadingState = ({ whatAreYouLoading }: LoadingStateProps) => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full h-full rounded-md p-4">
      <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl md:text-4xl"></i>
      <h3 className="font-bold text-center">Loading {whatAreYouLoading}...</h3>
    </div>
  );
}

export default LoadingState;