import React, { useEffect, useState } from 'react';
import { useScreen } from '../context';
import { Link, useLocation } from 'react-router-dom';

import LightMetricCard from '../components/widgets/lightMetricCard';
import Pagination from '../components/pagination';
import SearchBar from '../components/searchBar';
import { Tooltip } from 'react-tooltip'
import PageTabs from '../components/pageTabs';

import { listPurchaseOrders, searchProducts } from '../api';

import { PurchaseOrder } from '../types';
import LinkWithDirtyProtection from '../components/linkWrapper';

import { getDateFromD3DateAndTime } from '../utils';

const EqsiPage = () => {
  const tabs = ["All", "Draft", "Ordered", "Partial Received", "Fully Received", "Paid"];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(queryParams.get('tab') || tabs[0]);
  
  const [fetching, setFetching] = useState(false);
  const [orderData, setOrderData] = useState([] as any[]);
  const [filteredAndSearchedOrders, setFilteredAndSearchedOrders] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("PURCHASING");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  useEffect(() => {
    async function fetchOrderData() {
      setFetching(true);
      const orders = await listPurchaseOrders();

      for (let order of orders) {
        order.formattedDate = getDateFromD3DateAndTime(order["ORDER.DATE"], order["ORDER.TIME"]);
      }
      console.log(orders);
      setOrderData(orders);
      setFilteredAndSearchedOrders(orders);
      setFetching(false);
    }

    fetchOrderData();
  }, []);

  const statusKeys: {[key: string]: string} = {
    "Draft": "DR",
    "Ordered": "OR",
    "Partial Received": "PR",
    "Fully Received": "FR",
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('tab', activeTab);
    window.history.replaceState({}, '', `${location.pathname}?${queryParams}`);
  }, [activeTab]);

  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="topnav-title">
        <div>
          <h1 className="text-2xl md:text-4xl font-bold">Purchasing</h1>
          <h3>Order and manage stock for your branch</h3>
        </div>

        <Tooltip id="my-tooltip" place='top-start' />
        <LinkWithDirtyProtection to="/purchasing/po/NEW" className='h-full ml-auto'>
          <button 
            className="btn btn-primary h-full"
            data-tooltip-id="my-tooltip" 
            data-tooltip-content={`Create a new Purchase Order.`}
          >
            <i className="fas fa-plus mr-3"/>
            Start Purchase
          </button>
        </LinkWithDirtyProtection>
      </div>

      <div className="grid md:grid-cols-4 gap-4">
        <LightMetricCard title="Total Quotes" count={4690} icon="square-quote" />
        <LightMetricCard title="Sales Orders" count={281} icon="bag-shopping"  />
        <LightMetricCard title="Converted Quotes" count={14} icon="box-open-full" />
        <LightMetricCard title="Completed" count={4690} icon="box-check"  />
      </div>

      <div className="flex items-center flex-wrap justify-between h-[50px] gap-4">
        <PageTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} card={true} />

        <SearchBar initialData={orderData} setResults={setFilteredAndSearchedOrders} searchFunction={searchProducts} childQuery='' />
      </div>

      {fetching 
        ? <div 
          className="flex justify-center items-center flex-col gap-4"
          style={{height: "calc(100vh - 200px)"}}
        >
          <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl md:text-4xl"></i>
          <h2 className="text-2xl">Fetching purchasing data...</h2>
        </div>

        : <div className="flex flex-col gap-4 dashboard-card">
            <div>
              <table className="table-auto w-full">
                <thead>
                  <tr className="table-blue">
                    <th className="text-left px-1 text-sm">Supplier</th>
                    <th className="text-left px-1 text-sm">Order ID</th>
                    <th className="text-left px-1 text-sm">Date</th>
                    <th className="text-left px-1 text-sm">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAndSearchedOrders
                    .sort((a: any, b: any) => a.formattedDate > b.formattedDate ? -1 : 1)
                    .filter((purchaseOrder: PurchaseOrder) => activeTab === "All" ? true : purchaseOrder["PO.STATUS"] === statusKeys[activeTab])
                    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((purchaseOrder: PurchaseOrder, rowIndex: number) => (
                    <tr key={rowIndex} className="cursor-pointer hover:bg-gray-100">
                      <td 
                        className="text-sm cursor-pointer"
                        onClick={() => { let link = document.getElementById(`${rowIndex}-link`); if (link) link.click();}}
                      >
                        {purchaseOrder["SU.NAME"]}
                      </td>
                      <td 
                        className="text-sm cursor-pointer"
                        onClick={() => { let link = document.getElementById(`${rowIndex}-link`); if (link) link.click();}}
                      >
                        <Link id={`${rowIndex}-link`} className="text-sm text-[#3A5BFF]" to={`/purchasing/po/${purchaseOrder.KEY}`}>
                          {purchaseOrder.KEY ? purchaseOrder.KEY.split("*")[1] : "N/A"}
                        </Link>
                      </td>
                      <td 
                        className="text-sm cursor-pointer"
                        onClick={() => { let link = document.getElementById(`${rowIndex}-link`); if (link) link.click();}}
                      >
                        <p>{purchaseOrder["ORDER.DATE"] + " " + purchaseOrder["ORDER.TIME"].slice(0,5)}</p>
                      </td>
                      <td 
                        className="text-sm cursor-pointer"
                        onClick={() => { let link = document.getElementById(`${rowIndex}-link`); if (link) link.click();}}
                      >
                        <p>£{purchaseOrder["ORDER.VAL"]?.toLocaleString()}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
            <div className="px-4 pb-4">
              <Pagination 
                numberOfEntries={orderData.filter((purchaseOrder: PurchaseOrder) => activeTab === "All" ? true : purchaseOrder["PO.STATUS"] === statusKeys[activeTab]).length} 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                source="daive"
              />
            </div>
          </div>
      }
    </div>
  );
}

export default EqsiPage;