import React, { useState, useEffect } from 'react';
import { CustomerLocation } from '../../types';
import { fetchCustomerLocations } from '../../api';

import ModalWithChildren from '../modalWithChildren';

type Props = {
  address: string | string[] | undefined;
  setAddress: (address: string[]) => void;
  saveNewLocation: boolean;
  setSaveNewLocation: React.Dispatch<React.SetStateAction<boolean>>;
  theme: "blue" | "green";
  customerId?: string;
  supplierId?: string;
};

const OrderAddressCard: React.FC<Props> = ({ address, setAddress, theme, customerId, supplierId, saveNewLocation, setSaveNewLocation  }) => {
  const [addressContent, setAddressContent] = useState(address as string[] | string | undefined);
  const [editState, setEditState] = useState(false);

  const [newAddressName, setNewAddressName] = useState("");
  const [customerLocations, setCustomerLocations] = useState<CustomerLocation[]>([]);
  const [creatingNewAddress, setCreatingNewAddress] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);

  useEffect(() => {
    setAddressContent(address);
  }, [address]);

  useEffect(() => {
    const fetch = async () => {
      if (customerId) {
        const locations = await fetchCustomerLocations(customerId);
        setCustomerLocations(locations.filter((location: CustomerLocation) => location["D.ADDRESS"] !== undefined && location["D.ADDRESS"] !== ""));
      }
    }

    fetch();
  }, [customerId]);

  const handleSave = () => {
    if (!addressContent || typeof addressContent === undefined) return;

    console.log(addressContent);
    setAddress(addressContent as string[]);
    setEditState(false);
  };

  return (
    <div className="dashboard-card">
      <div className={`card-header bg-${theme === "blue" ? "blue" : "green"} text-white`}>
        <i className="fas fa-map-marker-alt text-white"></i>
        <h3 className="font-semibold uppercase text-sm">Delivery Location</h3>
      </div>

      <div className="p-4">
        {addressModalOpen 
          ? <ModalWithChildren
            children={
              <div className="p-4">
                {creatingNewAddress 
                  ? <>
                      <h3 className="text-lg font-semibold mb-2 text-center">Create New Delivery Address</h3>

                      <label htmlFor="note-input" className="text-sm">Name:</label>
                      <input
                        type="text"
                        className="input w-full p-2 border border-gray-300 mb-4 focus:outline-none"
                        placeholder="Enter a name for this address..."
                        value={newAddressName}
                        onChange={(e) => setNewAddressName(e.target.value)}
                      />

                      <label htmlFor="note-input" className="text-sm">Address:</label>
                      <textarea
                        value={typeof addressContent === "string" 
                          ? addressContent 
                          : addressContent !== undefined
                            ? (addressContent as string[]).join('\n')
                            : undefined}
                        id="note-input"
                        rows={8}
                        className="input w-full p-2 border border-gray-300 mb-4 focus:outline-none"
                        onChange={(e) => setAddressContent(e.target.value.split('\n'))}
                        placeholder="Enter your notes here..."
                      />
                      <button 
                        className={`btn btn-${theme === "blue" ? "primary" : "green"} w-full`}
                        onClick={() => setAddressModalOpen(false)}
                      >
                        Continue
                      </button>

                      <button
                        className="btn border border-gray-300 hover:bg-black hover:text-white w-full mt-4"
                        onClick={() => {
                          setCreatingNewAddress(false)
                          setAddressContent("")
                          setNewAddressName("")
                        }}
                      >
                        Back
                      </button>
                    </>
                  : <>
                      <h3 className="text-lg font-semibold mb-2 text-center">Select A Delivery Location</h3>
                      <p className="text-center mt-6">You can select existing addresses for this {theme === "green" ? "customer" : "supplier"} or add a new one:</p>

                      <button 
                        className={`btn btn-${theme === "blue" ? "primary" : "green"} w-full mt-2`} 
                        onClick={() => {
                          setCreatingNewAddress(true)
                          setAddressContent("")
                          setNewAddressName("")
                        }}
                      >
                        <i className="fas fa-plus mr-2"></i>
                        Add New Address
                      </button>

                      {customerId 
                        ? customerLocations.length > 0 
                            ? <div className="flex flex-col gap-2 mt-4">
                                {customerLocations.map((location, index) => (
                                  <div 
                                    key={index} 
                                    className="dashboard-card border border-gray-300 p-4 hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>  {
                                      setAddress(
                                      typeof location["D.ADDRESS"] === "string"
                                        ? [location["D.ADDRESS"]]
                                        : location["D.ADDRESS"]
                                      )
                                      setAddressModalOpen(false)
                                    }}
                                  >
                                    <h2 className="text-lg font-semibold">{location["D.NAME"]}</h2>
                              
                                    {typeof location["D.ADDRESS"] === "string" 
                                      ? <p>{location["D.ADDRESS"]}</p>
                                      : location["D.ADDRESS"] !== undefined
                                        ? location["D.ADDRESS"].map((line: string) => <p>{line}</p>)
                                        : null
                                    }
                                  </div>
                                ))}
                              </div>
                            : <p className="text-center mt-4">No addresses found for this customer.</p>
                        : <>
                          </>
                      }
                    </>
                }
              </div>
            }
            modalOpen={addressModalOpen}
            setModalOpen={setAddressModalOpen}
          />
          : addressContent === "" || !addressContent
            ? <button 
                className={`btn btn-${theme === "blue" ? "primary" : "green"} w-full disabled:opacity-50`}
                onClick={() => setAddressModalOpen(true)}
                disabled={!customerId && !supplierId}
              >
                Select Delivery Location
              </button>
            : <>
              <textarea
                value={typeof addressContent === "string" 
                  ? addressContent 
                  : addressContent !== undefined
                    ? addressContent.join('\n')
                    : undefined}
                id="note-input"
                rows={8}
                className="w-full py-2 px-3 border border-gray-300 focus:outline-none rounded-lg"
                onChange={(e) => setAddressContent(e.target.value.split('\n'))}
                placeholder="Enter your notes here..."
                disabled={!editState}
              />

              {creatingNewAddress && 
                <div className="flex gap-2 items-center mt-2 mb-4">
                  <div
                    className={`w-6 h-6 flex items-center justify-center rounded-lg cursor-pointer ${
                      saveNewLocation 
                        ? theme === "blue" ? "bg-primary" : "bg-green" 
                        : "bg-black"
                    }`}
                    onClick={() => setSaveNewLocation(!saveNewLocation)}
                  >
                    <i className={`fas fa-${saveNewLocation ? "check" : "times"} text-white`}></i>
                  </div>
                  <label className="text-sm font-semibold">Save Address for later</label>
                </div>
              }
              
              <button
                className={`btn w-full ${theme === "blue" ? "btn-primary" : "btn-green"}`}
                onClick={() => setAddressModalOpen(true)}
              >
                Change
              </button>
            </>
        }
      </div>
    </div>
  );
};

export default OrderAddressCard;