import { BasketItem, Product } from '../types';

export const handleGPupdate = (
  e: React.ChangeEvent<HTMLInputElement>,
  index: number,
  basket: BasketItem[],
  setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>
) => {
  const updatedBasket = [...basket];
  let userEnteredGp = parseFloat(e.currentTarget.value);
  let item = updatedBasket[index];

  let gpForCalc = parseFloat(e.currentTarget.value) || 0;
  
  let resultingBasketPrice = item.product["COST.PRICE"] + (item.product["COST.PRICE"] * (gpForCalc / 100))
  let resultingBasketDiscount = ((item.product["LIST.PRICE"] - resultingBasketPrice) / item.product["LIST.PRICE"]) * 100;

  let itemInBasket = updatedBasket[index];

  itemInBasket.discount = resultingBasketDiscount
  itemInBasket.discountString = resultingBasketDiscount.toFixed(2);
  itemInBasket.basketPrice = parseFloat(resultingBasketPrice.toFixed(2));
  itemInBasket.basketPriceString = resultingBasketPrice.toFixed(2);
  itemInBasket.gp = userEnteredGp || 0;

  setBasket(updatedBasket);
};

export const handleDiscountUpdate = (
  e: React.ChangeEvent<HTMLInputElement>,
  index: number,
  basket: BasketItem[],
  setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>
) => {
  const updatedBasket = [...basket];
  let userEnteredDiscount = parseFloat(e.currentTarget.value)
  let item = updatedBasket[index];
  
  let resultingBasketPrice = item.product["LIST.PRICE"] - (item.product["LIST.PRICE"] * ((parseFloat(e.currentTarget.value) || 0) / 100));
  let resultingBasketGp = ((resultingBasketPrice - item.product["COST.PRICE"]) / item.product["COST.PRICE"]) * 100;

  let itemInBasket = updatedBasket[index];

  itemInBasket.discount = userEnteredDiscount || 0;
  itemInBasket.basketPrice = parseFloat(resultingBasketPrice.toFixed(2));
  itemInBasket.basketPriceString = resultingBasketPrice.toFixed(2);
  itemInBasket.gp = resultingBasketGp;
  itemInBasket.gpString = resultingBasketGp.toFixed(2);

  setBasket(updatedBasket);
}

export const handlePriceUpdate = (
  price: number,
  index: number,
  basket: BasketItem[],
  setBasket: React.Dispatch<React.SetStateAction<BasketItem[]>>
) => {
  const updatedBasket = [...basket];
  let userEnteredPrice = price;
  let item = updatedBasket[index];
  
  let resultingBasketDiscount = ((item.product["LIST.PRICE"] - (userEnteredPrice || item.product["LIST.PRICE"])) / item.product["LIST.PRICE"]) * 100;
  let resultingBasketGp = (((userEnteredPrice || item.product["LIST.PRICE"]) - item.product["COST.PRICE"]) / item.product["COST.PRICE"]) * 100;

  let itemInBasket = updatedBasket[index];

  itemInBasket.discount = parseFloat(resultingBasketDiscount.toFixed(2));
  itemInBasket.discountString = resultingBasketDiscount.toFixed(2);
  updatedBasket[index].basketPrice = userEnteredPrice || 0;
  updatedBasket[index].gp = parseFloat(resultingBasketGp.toFixed(2));
  updatedBasket[index].gpString = resultingBasketGp.toFixed(2);

  setBasket(updatedBasket);
}

export const calculateSalesOrderBasketTotals = (
  basket: BasketItem[]
) => {
  console.log(basket);
  let subtotal = 0;
  let cost = 0
  let vat = 0;

  basket.forEach((item) => {
    if (!item.quantity) return;
    let basketPriceToTwoDecimalPlaces = parseFloat(item.basketPrice.toFixed(2));
    subtotal += basketPriceToTwoDecimalPlaces * item.quantity;
    cost += item.product["COST.PRICE"] * item.quantity;
    item.product["VAT.CODE"] === "1" && (vat += (basketPriceToTwoDecimalPlaces * item.quantity) * 0.2);
  });

  let profit = subtotal - cost;
  let grossProfit = (profit / cost) * 100;

  console.log(subtotal, cost, profit, grossProfit);
  return { subtotal, profit, grossProfit, vat };
}

export const getExtension = (item: BasketItem) => {
  let itemWithVat = item.product["VAT.CODE"] === "1" ? item.basketPrice * 1.2 : item.basketPrice;
  let twoDecimalPlacePrice = parseFloat(itemWithVat.toFixed(2));
  return twoDecimalPlacePrice * (item.quantity || 0);
}

export const calculatePurchaseOrderBasketTotals = (basket: BasketItem[]) => {
  let totalQuoted = 0;
  let vat = 0;

  basket.forEach((item: BasketItem) => {
    console.log(item)
    if (!item.quantity) return;
    
    let resultingPriceToTwoDecimalPlaces = parseFloat(item.basketPrice.toFixed(2))
    totalQuoted += getExtension(item);

    item.product["VAT.CODE"] === "1" && (vat += (resultingPriceToTwoDecimalPlaces * item.quantity * 0.2));
  });

  console.log(totalQuoted);
  return { totalQuoted, vat};
}

export const isProductInBasket = (id: string, basket: BasketItem[]) => {
  return basket.some((item) => item.product["PRODUCT.CODE"] === id);
}

export const handleProductSelect = (
  id: string,
  productData: Product[],
  basket: BasketItem[],
  setBasket: (newBasket: BasketItem[]) => void,
  setActiveBasketItem: (productCode: string) => void,
  screen: "purchase" | "sale" | "related"
) => {
  console.log(id, productData, basket);
  const latestProductData = [...productData];
  const product = latestProductData.find((product) => product["PRODUCT.CODE"] === id);
  if (!product) {
    let specialProductData = {
      "COST.PRICE": 0,
      "DESCRIPTION": id,
      "PICT.FLAG": "",
      "PGROUP.DESC": "",
      "PRODUCT.CODE": "",
      "LIST.PRICE": 0,
      "SUPPLIER": "",
      "Supplier's pc": "",
      "BOURNEMOUTH": "",
      "SHEFFIELD": "",
      "HULL": "",
      "Total Stock": 0,
      REORDER:"",
      UNIT:"",
      "VAT.CODE": "",
      WEIGHTVALUE: 0,
      LENGTH: 0,
      WIDTH: 0,
      HEIGHT: 0,
      WDESCRIPTION: "Special Item",
      "SUPPLIER.PC": ""
    }
    setBasket([...basket, { product: specialProductData, quantity: 1, basketPrice: 0, basketPriceString: "0.00", discount: 0, discountString: "0.00", per: 0 }]);
    return;
  }

  console.log(product);

  const itemIndex = basket.findIndex((item) => item.product["PRODUCT.CODE"] === id);
  if (itemIndex > -1) {
    changeProductQuantity("increase", id, basket, setBasket);
  } else {
    if (screen === "sale") {
      let basketPrice = product["postTermsPrice"] || product["LIST.PRICE"];
      let basketPriceString = basketPrice.toFixed(2);

      let gp = ((basketPrice - product["COST.PRICE"]) / product["COST.PRICE"]) * 100;
      let gpString = gp.toFixed(2);

      let discount = product["Terms Discount"] ? product["Terms Discount"] * 100 : 0;
      let discountString = discount.toFixed(2);

      setBasket([...basket, { product, quantity: null, basketPrice, basketPriceString, discount, discountString, per: parseFloat(product["UNIT"]), gp, gpString }]);
    }
    else {
      let basketPrice = product["COST.PRICE"];
      let basketPriceString = basketPrice.toFixed(2);
      setBasket([...basket, { product, quantity: null, basketPrice, basketPriceString, discount: 0, per: parseFloat(product["UNIT"]) }]);
    }
    setActiveBasketItem(id);
  }
};

export const removeItemFromBasket = (
  id: string,
  basket: BasketItem[],
  setBasket: (newBasket: BasketItem[]) => void,
  setActiveBasketItem: (productCode: string) => void,
  activeBasketItem: string | null
) => {
  const updatedBasket = [...basket];
  const indexOfActiveItem = basket.findIndex((item) => item.product["PRODUCT.CODE"] === activeBasketItem);
  const indexOfItem = basket.findIndex((item) => item.product["PRODUCT.CODE"] === id);

  console.log(indexOfItem, indexOfActiveItem, basket.length - 1);

  if (indexOfItem === indexOfActiveItem && basket.length > 1) {
    indexOfActiveItem === basket.length - 1
      ? setActiveBasketItem(basket[indexOfItem - 1].product["PRODUCT.CODE"])
      : setActiveBasketItem(basket[indexOfItem + 1].product["PRODUCT.CODE"])
  }
  console.log(indexOfItem, basket[indexOfItem + 1]);
  updatedBasket.splice(basket.findIndex((item) => item.product["PRODUCT.CODE"] === id), 1);
  setBasket(updatedBasket);
}

export const changeProductQuantity = (
  change: "increase" | "decrease" | number,
  id: string,
  basket: BasketItem[],
  setBasket: (newBasket: BasketItem[]) => void,
) => {
  const updatedBasket = [...basket];
  let itemInBasket = updatedBasket.find((item) => item.product["PRODUCT.CODE"] === id);
  if (!itemInBasket) return;

  if (itemInBasket.quantity === null) {
    typeof change === "number"
      ? itemInBasket.quantity = change
      : itemInBasket.quantity = 1;
  }
  else {
    if (change === "increase") itemInBasket.quantity += 1;
    else if (change === "decrease") itemInBasket.quantity -= 1;
    else itemInBasket.quantity = change;
  }

  setBasket(updatedBasket);
}

export const formatBasketForD3 = async (basket: BasketItem[]) => {
  console.log(basket);
  let productCodes = [] as string[];
  let productQuantities = [] as number[];
  let productPrices = [] as string[];
  let lineValues = [] as number[];
  let orderVal = 0;

  basket.forEach((item) => {
    if (!item.quantity) return;

    productCodes.push(item.product["PRODUCT.CODE"]);
    productQuantities.push(item.quantity);
    productPrices.push(item.basketPrice.toFixed(2));
    lineValues.push(item.basketPrice * item.quantity);
    orderVal += item.basketPrice * item.quantity;
  });

  return { productCodes, productQuantities, productPrices, lineValues };
}

export const valueIsNullOrZero = (value: string | null) => {
  return value === null || value === "0" || value === "" || value === "0.00" || value === "0.0";
}