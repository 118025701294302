import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useScreen } from '../context';

import { fetchCustomer, updateCustomer, fetchDashboardData, fetchProductCategories, fetchCustomerLocations } from '../api';
import { ContactData, Customer, Order, CustomerLocation } from '../types';

import BackButton from '../components/backLink';

import ModalWithChildren from '../components/modalWithChildren';
import CustomerAndSupplierNotes from '../components/widgets/newNoteModal';
import SimpleMetricCard from '../components/widgets/simpleMetricCard';
import { groupOrders } from './dashboard/utils';

import { getDateInD3Format, unwrapD3DateToISO, formatChangedDataForWrite } from '../utils';
import { contactsData } from '../dummyData';
import ContactsGrid from '../components/contactsList';
import HistoryTimeline from '../components/timeline/timeline';

const CustomersCard = () => {
  const { id: customerId } = useParams<{ id: string }>();
  const newCustomer = customerId === "new";
  
  const tabOptions = ["Overview", "Financials", "Customer Terms", "Contacts", "Delivery Locations"];
  
  const [tab, setTab] = useState(tabOptions[0]);
  const [originalData, setOriginalData] = useState({} as Customer);
  const [customerData, setCustomerData] = useState({} as Customer);
  const [fetchingData, setFetchingData] = useState(true);
  const [categoryTerms, setCategoryTerms] = useState<{ [key: string]: string }>({});
  const [customerTerms, setCustomerTerms] = useState("" as "" | "Bronze" | "Silver" | "Gold");

  const supplierTermsDummyData = [
    { 
      supplierName: "Canadian Solar", 
      termsGroups: [
        { 
          groupName: "Batteries", 
          products: 15,
          discountGroup: "Gold",
          gold: 15,
          silver: 10,
          bronze: 5,
        },
        { 
          groupName: "Inverters", 
          products: 25,
          discountGroup: "Bronze",
          gold: 25,
          silver: 20,
          bronze: 15,
        },
        { 
          groupName: "Panels",
          products: 10, 
          discountGroup: "",
          gold: 5,
          silver: 0,
          bronze: 0,
        }
      ]
    },
    { 
      supplierName: "SolarEdge", 
      termsGroups: [
        { 
          groupName: "Batteries", 
          products: 10,
          discountGroup: "",
          gold: 20,
          silver: 15,
          bronze: 10,
        },
        { 
          groupName: "Inverters", 
          products: 23,
          discountGroup: "Gold",
          gold: 25,
          silver: 20,
          bronze: 15,
        },
        { 
          groupName: "Panels", 
          products: 8,
          discountGroup: "Silver",
          gold: 5,
          silver: 0,
          bronze: 0,
        }
      ]
    },
    { 
      supplierName: "LG Chem", 
      termsGroups: [
        { 
          groupName: "Batteries", 
          products: 5,
          discountGroup: "Gold",
          gold: 15,
          silver: 10,
          bronze: 5,
        },
      ]
    },
  ]
  const [supplierTerms, setSupplierTerms] = useState(supplierTermsDummyData);
  const [activeSupplier, setActiveSupplier] = useState("");

  const [ytdSpend, setYtdSpend] = useState(0);
  const [ytdOrders, setYtdOrders] = useState(0);

  const [contacts, setContacts] = useState<ContactData[]>(contactsData);
  const [customerStopModalOpen, setCustomerStopModalOpen] = useState(false);

  const [customerLocations, setCustomerLocations] = useState([] as CustomerLocation[]);

  const { setScreenId, setPageHasDirtyForm, addNotification } = useScreen();
  setScreenId(`CUSTOMER- ${customerId}`);

  useEffect(() => {
    console.log("Fetching customer data...");
    if (!customerId) return;

    const fetchData = async () => {
      try {
        const data = await fetchCustomer(customerId);
        console.log(data);

        if (data) {
          setOriginalData(data);
          setCustomerData(data);
        }

        let customerLocations = await fetchCustomerLocations(customerId);
        setCustomerLocations(customerLocations);
        console.log(customerLocations);

        let categories = await fetchProductCategories();
        if (categories) {
          const initialTerms = categories
            .filter((category) => category !== "")
            .reduce((acc, category) => ({ ...acc, [category]: "" }), {});
        
          setCategoryTerms(initialTerms);
        }

        console.log(categories);
      } catch (err) {
        console.error('There was a problem fetching accounts:', err);
        addNotification("There was a problem fetching the customer. Please try again.", "error");
      }
      setFetchingData(false);

      let orderData = await fetchDashboardData(customerId) as Order[];
      console.log(orderData);
      const grouppedOrders = groupOrders(orderData);
      setYtdOrders(grouppedOrders.ordersThisYear.length);
      setYtdSpend(grouppedOrders.ordersThisYear.reduce((acc, order) => acc + order['ORDER.VAL'], 0));
    };

    fetchData();
  }, [customerId]);

  console.log("Hello?")

  const inputVariable = (label: string, valueKey: keyof Customer) => {
    return <>
      <div className="label">{label}</div>
      <input 
        type="text" 
        className="input outline-none" 
        placeholder={`${label}...`} 
        value={customerData[valueKey]} 
        onChange={(e) => {
          setPageHasDirtyForm(true);
          setCustomerData({ ...customerData, [valueKey]: e.target.value })
        }}
      />
    </>
  }

  const updateValue = (key: keyof Customer, value: string) => {
    setPageHasDirtyForm(true);
    setCustomerData({ ...customerData, [key]: value });
  }

  const updateCustomerData = async () => {
    if (!customerId) return;

    try {
      console.log("Updating customer data...");

      let formattedValues = formatChangedDataForWrite(originalData, customerData);
      if (!formattedValues) {
        addNotification("No changes detected.", "error");
        return;
      }

      const updateResponse = await updateCustomer(customerId, formattedValues.originalPayload, formattedValues.updatedPayload);
      console.log(updateResponse);
      
      if (!updateResponse) {
        addNotification("There was a problem updating the customer. Please try again.", "error");
        return;
      }

      setOriginalData(customerData);
      setPageHasDirtyForm(false);
      addNotification("Customer updated successfully.", "success");
    } catch (err: any) {
      console.log(err)
      addNotification(err.message, "error");
      console.error('There was a problem updating customer:', err);
    }
  }

  const attemptSave = async () => {
    console.log("attempting to save");
  }
  

  return (
    <div className="flex flex-col items-start gap-4">
      {customerStopModalOpen &&
        <ModalWithChildren
          children={
            <div className="flex flex-col gap-2 items-center">
              <h2 className="font-semibold text-2xl">Customer on Stop</h2>
              <p className="mt-4 max-w-[500px] text-center">Putting this Customer on Stop will mean they can no longer purchase any products at all locations, including the Website.<br /><br />Confirm this is the action you want.</p>
              <button
                className="btn btn-negative w-full mt-4"
                onClick={() => {
                  updateValue("CREDIT.STATUS", "STOP");
                  setCustomerStopModalOpen(false);
                }}
              >
                Confirm
              </button>
              <button
                className="btn btn-secondary w-full"
                onClick={() => setCustomerStopModalOpen(false)}
              >
                Cancel
              </button>

            </div>}
          modalOpen={customerStopModalOpen}
          setModalOpen={setCustomerStopModalOpen}
        />
      }

      <div className="flex items-center justify-center gap-4 w-full">
        <div>
          <BackButton />
          <h2 className="font-semibold text-2xl">
            {newCustomer 
              ? "New Customer"
              : customerData.NAME
            }
          </h2>
        </div>

        {newCustomer 
          ? <>
              <button
                className="btn btn-secondary ml-auto"
                onClick={() => {
                  window.location.href = "/maintenance";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-green"
                onClick={attemptSave}
              >
                Save
              </button>
            </>
          : <button
              className="btn btn-green ml-auto disabled:opacity-50"
              onClick={updateCustomerData}
              disabled={fetchingData || !customerId || (JSON.stringify(originalData) === JSON.stringify(customerData))}
            >
              Save
            </button>
        }
      </div>

      <div className="grid md:grid-cols-2 w-full gap-4">
        <SimpleMetricCard
          identifier='Total'
          metric='Orders'
          count={ytdOrders}
          change={null}
          period='year'
          icon='cart-shopping'
          fetching={fetchingData}
          theme='green'
        />

        <SimpleMetricCard
          identifier='Total'
          metric='Spend'
          count={ytdSpend}
          change={null}
          period='year'
          icon='cash-register'
          fetching={fetchingData}
          theme='green'
        />
      </div>

      <div className="grid md:grid-cols-3 w-full gap-4">
        <div className="col-span-2">
          <div className="dashboard-card">
            <div className="flex px-4 pt-3  gap-2 items-center bg-green rounded-t-lg">
              {/* <PageTabs tabs={tabOptions}  /> */}
              {tabOptions.map((option, index) => (
                <button 
                  key={index} 
                  className={`px-2 pb-1 font-bold border-b-4 text-white ${tab === option ? 'border-white' : 'border-transparent'}`} 
                  onClick={() => setTab(option)}
                >
                  {option}
                </button>
              ))}
            </div>

            {fetchingData 
              ? <div className="flex flex-col justify-center items-center flex-grow py-12">
                  <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-4xl"></i>
                </div>
              : <div className="p-4">
                  {tab === "Overview" && <>
                    <h3 className="font-semibold mt-4">Primary Details</h3>

                    <div className="form-container mt-4">
                      <div className="label">Account Number</div>
                      <p className="text-sm pl-3">{customerId}</p>

                      {inputVariable("Customer name", "NAME")}
                      
                      <div className="label">Address</div>
                      <textarea 
                        className="input outline-none" 
                        placeholder="Address..." 
                        rows={4}
                        value={typeof customerData.ADDRESS === "string" 
                          ? customerData.ADDRESS 
                          : customerData.ADDRESS !== undefined
                            ? customerData.ADDRESS.join('\n')
                            : undefined
                        }
                        onChange={(e) => {
                          setPageHasDirtyForm(true);
                          setCustomerData({ ...customerData, ADDRESS: e.target.value.split('\n') })
                        }}
                      />

                      {inputVariable("Postcode", "POSTCODE")}
                      {inputVariable("VAT Type", "VAT.TYPE")}
                      {inputVariable("Phone", "PHONE")}
                      {inputVariable("Account Holding Location", "AREA")}
                      {inputVariable("External Sales Rep", "REP")}
                    </div>
                  </>}

                  {tab === "Financials" && <>
                    <h3 className="font-semibold mt-4">Financial Details</h3>

                    <div className="form-container mt-4">
                      <div className="label">Credit Limit</div>
                      <div className="flex rounded border border-gray-300 bg-white items-center justify-start text-[12px]">
                        <div className="border-gray-300 border-r px-1 h-full flex items-center">
                          <p>£</p>
                        </div>
                        <div className="px-1 flex-grow">
                          <input 
                            type="number" 
                            className="w-full outline-none py-[3px]" 
                            placeholder={`Customer's credit limit...`}
                            value={customerData["CREDIT.LIMIT"]}
                            onChange={(e) => {
                              setPageHasDirtyForm(true);
                              setCustomerData({ ...customerData, "CREDIT.LIMIT": parseFloat(e.target.value) })
                            }}
                          />
                        </div>
                      </div>

                      <label className="label">Credit Limit Review Date</label>
                      <input 
                        type="date" 
                        className="input outline-none" 
                        value={customerData["REVIEW.DATE"] ? unwrapD3DateToISO(customerData["REVIEW.DATE"]) : ""}
                        onChange={(e) => {
                          setPageHasDirtyForm(true);
                          setCustomerData({ ...customerData, "REVIEW.DATE": getDateInD3Format(new Date(e.target.value)) })
                        }}
                      />

                      {inputVariable("Payment Terms", "TRADING.TERMS")}
                      {inputVariable("Customer on Stop", "CREDIT.STATUS")}

                      <div className="label">Debt Insured</div>
                      <div className="flex rounded border border-gray-300 bg-white items-center justify-start text-[12px]">
                        <div className="border-gray-300 border-r px-1 h-full flex items-center">
                          <p>£</p>
                        </div>
                        <div className="px-1 flex-grow">
                          <input 
                            type="number" 
                            className="w-full outline-none py-[3px]" 
                            placeholder={`Customer's insured debt...`}
                            value={customerData["DEBT.INSURED"]}
                            onChange={(e) => {
                              setPageHasDirtyForm(true);
                              setCustomerData({ ...customerData, "DEBT.INSURED": e.target.value })
                            }}
                          />
                        </div>
                      </div>
  

                      <div className="label">Customer on Stop</div>
                      <button 
                        className={`btn btn-${customerData["CREDIT.STATUS"] === "STOP" ? "green" : "negative"} w-full`}
                        onClick={() => {
                          if (customerData["CREDIT.STATUS"] === "STOP") {
                            updateValue("CREDIT.STATUS", "OK");
                          }
                          else {
                            setCustomerStopModalOpen(true);
                          }
                        }}
                      >
                        {customerData["CREDIT.STATUS"] === "STOP" ? "Remove Stop" : "Put on Stop"}
                      </button>
                    </div>
                  </>}

                  {tab === "Contacts" && <ContactsGrid contacts={contacts} setContacts={setContacts} theme="green"/>}

                  {tab === "Customer Terms" && <>
                    <h3 className="font-semibold mt-4">Customer Terms</h3>
                    <div className="form-container mt-4">
                      <label className="label">Main Terms Group</label>
                      <select
                        className="input outline-none border border-gray-300 w-full rounded-md p-1"
                        value={customerTerms}
                        onChange={(e) => {
                          setPageHasDirtyForm(true);
                          setCustomerTerms(e.target.value as "" | "Bronze" | "Silver" | "Gold");
                        }}
                      >
                        <option disabled hidden value="">
                          No terms set...
                        </option>
                        <option value="Bronze">Bronze</option>
                        <option value="Silver">Silver</option>
                        <option value="Gold">Gold</option>
                      </select>
                    </div>

                    <h3 className="font-semibold mt-8">Category Terms</h3>

                    <div className="grid w-full md:grid-cols-2 gap-4 mt-4">
                    {Object.keys(categoryTerms).map((category, index) => (
                      <div key={index}>
                        <p>{category}</p>
                        <select
                          className="input outline-none border border-gray-300 w-full rounded-md p-1"
                          value={categoryTerms[category]}
                          onChange={(e) => {
                            setPageHasDirtyForm(true);
                            setCategoryTerms((prevTerms) => ({
                              ...prevTerms,
                              [category]: e.target.value,
                            }));
                          }}
                        >
                          <option disabled hidden value="">
                            No terms set...
                          </option>
                          <option value="Bronze">Bronze</option>
                          <option value="Silver">Silver</option>
                          <option value="Gold">Gold</option>
                        </select>
                      </div>
                    ))}
                    </div>

                    <h3 className="font-semibold mt-8">Supplier Terms</h3>

                    {activeSupplier === ""
                      ? <div className="flex flex-col gap-4 mt-4">
                          {supplierTerms.map((supplier, index) => (
                            <div 
                              key={index} 
                              className="dashboard-card border border-gray-300 p-4 hover:bg-gray-100 cursor-pointer"
                              onClick={() => setActiveSupplier(supplier.supplierName)}
                            >
                              <h2 className="text-lg font-semibold">{supplier.supplierName}</h2>
                              <p className="text-sm">{supplier.termsGroups.length} terms groups</p>
                            </div>
                          ))}
                        </div>
                      : <>
                          <p 
                            className="text-sm hover:underline mt-4 cursor-pointer"
                            onClick={() => setActiveSupplier("")}
                          >
                            <i className="fas fa-arrow-left mr-1"></i>
                            Back to all suppliers
                          </p>
                          <h2 className="font-semibold text-xl mb-4">{activeSupplier} Terms Groups</h2>
                          <div className="flex flex-col gap-4">
                            {supplierTerms.find((supplier) => supplier.supplierName === activeSupplier)?.termsGroups.map((termsGroup, index) => (
                              <div key={index} className="dashboard-card p-4 flex gap-4 items-center">
                                <div className="shrink-0">
                                  <h2 className="text-lg font-semibold">{termsGroup.groupName}</h2>
                                  <p>{termsGroup.products} products</p>
                                </div>
                                <div className="flex items-center border border-gray-300 px-2 rounded-lg ml-auto w-[175px]">
                                  {termsGroup.discountGroup !== "" && <i className={`
                                      fa-regular fa-medal 
                                      ${termsGroup.discountGroup === "Gold" 
                                        ? "text-yellow-600" 
                                        : termsGroup.discountGroup === "Silver" 
                                          ? "text-gray-300" 
                                          : "text-orange-600"
                                      }
                                      text-xl
                                    `}></i>
                                  }
                                  <select
                                    className="input outline-none w-full p-1 py-3 font-semibold cursor-pointer"
                                    value={termsGroup.discountGroup}
                                    onChange={(e) => {
                                      setPageHasDirtyForm(true);
                                      setSupplierTerms((prevTerms) => {
                                        const newTerms = [...prevTerms];
                                        const supplierIndex = newTerms.findIndex((supplier) => supplier.supplierName === activeSupplier);
                                        const termsGroupIndex = newTerms[supplierIndex].termsGroups.findIndex((group) => group.groupName === termsGroup.groupName);

                                        newTerms[supplierIndex].termsGroups[termsGroupIndex].discountGroup = e.target.value;
                                        return newTerms;
                                      });
                                    }}
                                  >
                                    <option value="">
                                      No terms set...
                                    </option>
                                    <option value="Bronze">Bronze ({termsGroup.bronze}%)</option>
                                    <option value="Silver">Silver ({termsGroup.silver}%)</option>
                                    <option value="Gold">Gold ({termsGroup.gold}%)</option>
                                  </select>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                    }
                  </>}

                  {tab === "Delivery Locations" && <>
                    <h3 className="font-semibold mt-4">Delivery Locations ({customerLocations.length})</h3>
                    <div className="grid md:grid-cols-2 gap-4 mt-4">
                      {customerLocations.map((location, index) => (
                        <div key={index} className="dashboard-card p-4">
                          <h2 className="text-lg font-semibold">{location["D.NAME"]}</h2>
                          
                          {typeof location["D.ADDRESS"] === "string" 
                            ? <p>{location["D.ADDRESS"]}</p>
                            : location["D.ADDRESS"] !== undefined
                              ? location["D.ADDRESS"].map((line: string) => <p>{line}</p>)
                              : null
                          }
                        </div>
                      ))}
                    </div>
                  </>}
                </div>
            }
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="dashboard-card">
            <div className="card-header bg-green">
              <i className="fas fa-sticky-note"></i>
              <h3 className="font-semibold uppercase text-sm">Customer Notes</h3>
            </div>

            <CustomerAndSupplierNotes
              note={customerData['CC.COMMENTS']}
              setNote={(note: string[]) => setCustomerData({ ...customerData, "CC.COMMENTS": note }) }
              theme='green'
            />
          </div>

          <div className="dashboard-card">
            <div className="card-header bg-green">
              <i className="fas fa-history"></i>
              <h3 className="font-semibold uppercase text-sm">Activity History</h3>
            </div>
            
            <div className="p-4">
              {/* <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created customer record.</p> */}
              <HistoryTimeline screenId={''} dataId={''} theme={'green'}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomersCard;