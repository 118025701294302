import { useEffect, useState } from "react";
import { ContactData } from "../types";
import { convertDateObjToInputTagReadable, isPhoneNumber } from "../utils";

type ContactEditorProps = {
    contactData: ContactData,
    closeTab: () => void,
    deleteContact: () => void,
    theme?: "green" | "blue",
    newContact?: boolean
}

const ContactEditor = ({contactData, closeTab, deleteContact, theme = "blue", newContact = false}: ContactEditorProps) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [telephone, setTelephone] = useState('');
    const [dateAdded, setDateAdded] = useState<Date>(new Date());
    const [lastAmended, setLastAmended] = useState<Date>(new Date());
    const [notes, setNotes] = useState('');
    const [showDelete, setShowDelete] = useState(true)

    useEffect(() => {
        setName(contactData.name);
        setEmail(contactData.email);
        setAddress(contactData.address);
        setTelephone(contactData.telephone);
        setDateAdded(contactData.dateAdded);
        setLastAmended(contactData.lastAmended);
        setNotes(contactData.notes ?? '');
        setShowDelete(!newContact);
    }, [contactData, newContact])

    function validateData(): {success: boolean, errorMessage?: string} {
        const errorMessages: string[] = []
        if (!name || name.length < 1) errorMessages.push("No name added!");
        if (email && (!email.includes('@'))) errorMessages.push("Invalid email!");
        if (telephone && !isPhoneNumber(telephone)) errorMessages.push("Invalid phone number!");

        return {success: errorMessages.length < 1, errorMessage: errorMessages.join('\n')}
    }

    function save() {
        const dataValidation = validateData();
        
        if (dataValidation.success) {
            if (!(email || address || telephone) && !window.confirm("No contact details have been specified. Are you sure you want to save?")) return;
            contactData.name = name;
            contactData.email = email;
            contactData.address = address;
            contactData.telephone = telephone;
            if (newContact) {
                contactData.dateAdded = dateAdded;
                contactData.lastAmended = lastAmended;
            } else {
                contactData.lastAmended = new Date();
            }
            contactData.notes = notes;
            closeTab();
        } else {
            alert("Cannot save: \n" + dataValidation.errorMessage);
        }
    }

    function cancel() {
        let doCancel: boolean = true;
        if (
            name !== contactData.name || 
            email !== contactData.email ||
            address !== contactData.address ||
            telephone !== contactData.telephone ||
            notes !== contactData.notes) {
            window.confirm("You still have unsaved changes. Are you sure you want to cancel?");
        }

        if (doCancel) {
            closeTab();

            if (newContact) {
                deleteContact();
            }
        }
    }

    const textInput = (id: string, value: string, onChange: (e: React.ChangeEvent) => void, type: string = "text", multiline: boolean = false, notesSection: boolean = false, disabled: boolean = false) => 
        multiline ? 
        <textarea
            id={id}
            value={value}
            onChange={onChange}
            className={"w-full text-sm outline-none py-[3px] border-[1px] border-solid border-[#D1D5DC] px-[7px] rounded-[3px] resize-y" + (notesSection ? " h-36" : " h-20")}
            disabled={disabled}
        />
            :
        <input 
            type={type} 
            id={id} 
            value={value} 
            onChange={onChange} 
            className="w-full text-sm outline-none py-[3px] border-[1px] border-solid border-[#D1D5DC] px-[7px] rounded-[3px] h-8"
            disabled={disabled}
        />

    return (
        <section className='flex flex-col gap-4 w-full p-4'>
            <h3 className="font-extrabold">Contact Details:</h3>
            <div className="flex flex-row divide-x-2 divide-slate-100 gap-8">
                <div className="grid grid-cols-[auto_1fr] gap-[20px] col-auto auto-rows-min overflow-y-auto min-h-0 h-64">
                    <label htmlFor="contactsTabNameInput" className="text-[#858D9D] pr-[10px] text-sm w-20">Name<abbr title="Required field" >*</abbr></label>
                    {textInput("contactsTabNameInput", name, (e: React.ChangeEvent) => setName((e.target as HTMLInputElement).value))}

                    <label htmlFor="contactsTabEmailInput" className="text-[#858D9D] pr-[10px] text-sm w-20">Email</label>
                    {textInput("contactsTabEmailInput", email, (e: React.ChangeEvent) => setEmail((e.target as HTMLInputElement).value), "email")}

                    <label htmlFor="contactsTabAddressInput" className="text-[#858D9D] pr-[10px] text-sm w-20">Address</label>
                    {textInput("contactsTabAddressInput", address, (e: React.ChangeEvent) => setAddress((e.target as HTMLTextAreaElement).value), "", true)}

                    <label htmlFor="contactsTabTelephoneInput" className="text-[#858D9D] pr-[10px] text-sm w-20">Telephone</label>
                    {textInput("contactsTabTelephoneInput", telephone, (e: React.ChangeEvent) => setTelephone((e.target as HTMLInputElement).value), "tel")}
                </div>
                <div className="grid grid-cols-[auto_1fr] gap-[20px] col-auto auto-rows-min pl-8 overflow-y-auto min-h-0 h-64">
                    <label htmlFor="contactsTabDateAddedInput" className="text-[#858D9D] pr-[10px] text-sm w-28">Date Added</label>
                    {textInput("contactsTabDateAddedInput", convertDateObjToInputTagReadable(dateAdded), (e: React.ChangeEvent) => setDateAdded(new Date((e.target as HTMLInputElement).value)), "date", false, false, true)}

                    <label htmlFor="contactsTabLastAmendedInput" className="text-[#858D9D] pr-[10px] text-sm w-28">Last Amended</label>
                    {textInput("contactsTabLastAmendedInput", convertDateObjToInputTagReadable(lastAmended, true), (e: React.ChangeEvent) => setLastAmended(new Date((e.target as HTMLInputElement).value)), "datetime-local", false, false, true)}

                    <label htmlFor="contactsTabNotesInput" className="text-[#858D9D] pr-[10px] text-sm w-20">Notes</label>
                    {textInput("contactsTabNotesInput", notes, (e: React.ChangeEvent) => setNotes((e.target as HTMLInputElement).value), "", true, true)}
                </div>
            </div>
            <div className="flex flex-row gap-2 w-full justify-between bg-white border-t pt-2">
                <div className="flex flex-row gap-2 items-start">
                    <button className={`btn btn-primary ${theme === 'green' ? 'btn-green' : ''}`} onClick={save}>Save</button>
                    <button className={`btn btn-secondary${theme === 'green' ? '-green' : ''}`} onClick={cancel}>Cancel</button>
                </div>
                {showDelete && 
                <div className="flex flex-row items-end">
                    <button className="btn bg-red-400 text-white" onClick={() => {
                        if (window.confirm("Are you sure you want to delete this contact?")) {
                            closeTab(); 
                            deleteContact();
                        }
                    }}>Delete</button>
                </div>
                }
            </div>
        </section>
    )
  }

  export default ContactEditor;