import React, { useState } from 'react';
import { sortAlphabeticallyWithNonAlphaAtEnd } from '../utils';

type SortConfigTypes = {
  defaultSortKey: string,
  data: { [key: string]: any }[]
  sortConfig: {
    key: string,
    direction: string
  }
}

export const sortData = ({ defaultSortKey, data, sortConfig }: SortConfigTypes) => {
  console.log(defaultSortKey, data, sortConfig);
  if (!data || data.length === 0) {
    return [];
  }
  if (sortConfig.key === defaultSortKey && typeof data[0][defaultSortKey] === 'string') {
    return sortConfig.direction === 'desc'
      ? sortAlphabeticallyWithNonAlphaAtEnd(data, defaultSortKey)
      : sortAlphabeticallyWithNonAlphaAtEnd(data, defaultSortKey).reverse();
  }
  else {
    return [...data].sort((a, b) => {
      if (sortConfig.direction === 'asc') {
        if (typeof a[sortConfig.key] === 'number') {
          return a[sortConfig.key] - b[sortConfig.key];
        }
        return a[sortConfig.key].localeCompare(b[sortConfig.key]);
      } else {
        if (typeof a[sortConfig.key] === 'number') {
          return b[sortConfig.key] - a[sortConfig.key];
        }
        return b[sortConfig.key].localeCompare(a[sortConfig.key]);
      }
    });
  }
}