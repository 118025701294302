import { useId } from "react";
import { AvailableThemes } from "../types";
import { clamp } from "../utils";

type EmailAttachmentProps = {
    fileName: string, 
    fileSizeInBytes: number, 
    contentId: string, 
    thumbnail?: string, 
    isFirstComment?: boolean,
    theme?: AvailableThemes,
    uploadingPercentage?: number
}


const EmailAttachment = ({fileName, fileSizeInBytes, contentId, thumbnail, isFirstComment = false, theme = "green", uploadingPercentage}: EmailAttachmentProps) => {
    const attachmentDownloadRoute = "nyi/attachments/";
    const fileSizeUnits: {short: string, full: string}[] = [
        {short: "B", full: "Byte"}, 
        {short: "KB", full: "Kilobyte"}, 
        {short: "MB", full: "Megabyte"}, 
        {short: "GB", full: "Gigabyte"}, 
        //Preparation for a zip bomb I guess? I mean the system is cooked, it might as well look good while it's falling down. You never know...
        {short: "TB", full: "Terabyte"}, 
        {short: "PB", full: "Petabyte"}, 
        {short: "EB", full: "Exabyte"}, 
        {short: "ZB", full: "Zettabyte"}, 
        {short: "YB", full: "Yottabyte"}
    ];
    const fileId = useId();
    const progBarClasses: {[K in AvailableThemes]: string} = {
        "blue": "[&::-webkit-progress-value]:bg-blue-500",
        "green": "[&::-webkit-progress-value]:bg-green-500"
    }


    function genFileSize() {
        let divisionsDone = 0;

        while ((fileSizeInBytes / (Math.pow(1000, divisionsDone))) > 1 && divisionsDone < fileSizeUnits.length) {
            ++divisionsDone;
        }

        if (divisionsDone > 0) --divisionsDone;
        let fileSize: number | string = (fileSizeInBytes / Math.pow(1000, divisionsDone));

        if (divisionsDone > 0) fileSize = fileSize.toFixed(2);

        const pluralS = fileSizeInBytes > 1 ? 's' : ''
        const allBytes = `${fileSizeInBytes.toLocaleString()} byte${pluralS}`

        return <abbr 
                    className="decoration-gray-300 text-xs text-gray-500" 
                    title={`${fileSize} ${fileSizeUnits[divisionsDone].full}${pluralS}${divisionsDone > 0 ? ` (${allBytes})` : ''}`}
                >
                    {fileSize + (fileSizeUnits[divisionsDone].short)}
                </abbr>;

    }

    function getDownloadLinkForContentId(): string {
        return attachmentDownloadRoute + contentId;
    }

    const finishedUploading: boolean = (uploadingPercentage ?? 0) >= 100;
    const uploading: boolean = (uploadingPercentage ?? -1) >= 0;

    return (
        <a href={!uploading || finishedUploading ? getDownloadLinkForContentId() : undefined}>
            <div className="flex flex-row h-20 w-48 border border-solid rounded-[5px] bg-white place-items-center">
                {(!uploading || finishedUploading) && <>
                    {isFirstComment && thumbnail ? <img id={`file-attachment-${fileId}`} src={thumbnail} alt={fileName} height={64} width={64} className="h-16 w-16 ml-2"/> : <i className="fa-solid fa-paperclip text-3xl m-5 place-content-center place-self-center"/>}
                    <div className="flex flex-col px-2 py-1 w-32 h-16 gap-1">
                        <label htmlFor={isFirstComment && thumbnail ? `file-attachment-${fileId}` : undefined} className="truncate hover:cursor-pointer" title={fileName}>{fileName.slice(0, fileName.lastIndexOf('.'))}</label>
                        <div className="flex flex-row w-28 place-items-center gap-2">
                            <span className={`rounded-[5px] font-semibold text-sm p-1 bg-${theme} text-white`}>{fileName.split('.').reverse()[0].toUpperCase().slice(0, 4)}</span>
                            {genFileSize()}
                        </div>
                    </div>
                </>}
                {uploading && !finishedUploading &&
                    <div className="flex flex-col mx-2 w-full">
                        <span className="text-gray-500 truncate w-4/5">Uploading: {fileName}</span>
                        <div className="flex flex-row w-4/5 place-items-center gap-1">
                            <progress max={100} value={clamp(0, 100, uploadingPercentage!)} className={`w-full animate-pulse ${progBarClasses[theme]}`}/>
                            <span>{uploadingPercentage}%</span>
                        </div>
                    </div>
                }
            </div>
        </a>
    )
}

export default EmailAttachment;