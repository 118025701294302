import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useScreen } from '../context';

interface LinkWithPromptProps {
  to: string;
  children: React.ReactNode;
  className?: string;
}

const LinkWithDirtyProtection: React.FC<LinkWithPromptProps> = ({ to, children, className }) => {
  const navigate = useNavigate();
  const { pageHasDirtyForm, setPageHasDirtyForm } = useScreen();


  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    console.log('pageHasDirtyForm:', pageHasDirtyForm);
    if (pageHasDirtyForm) {
      event.preventDefault();
      const confirmLeave = window.confirm('You have unsaved changes. Are you sure you want to leave this page?');
      if (confirmLeave) {
        setPageHasDirtyForm(false);
        navigate(to);
      }
    }
  };

  return (
    <Link to={to} onClick={handleClick} className={className}>
      {children}
    </Link>
  );
};

export default LinkWithDirtyProtection;