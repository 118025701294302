import React, { useEffect, useState } from 'react';

import { Supplier, Customer } from '../types';
import SearchBar from './searchBar';
import Pagination from './pagination';
import ModalWithChildren from './modalWithChildren';

import { listSuppliers, searchSuppliers, listCustomers, searchCustomers } from '../api';

import LoadingState from './loadingState';

type ProductModalProps = {
  setData: React.Dispatch<React.SetStateAction<null | any>>;
  modalIsOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataSource: "Suppliers" | "Customers";
};

const SuppliersAndCustomersSearchModal = ({ setData, modalIsOpen, setModalOpen, dataSource }: ProductModalProps) => {
  const [fetching, setFetching] = useState(true);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState([] as any[]);
  const [modalData, setModalData] = useState([] as Supplier[] | Customer[]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  useEffect(() => {
    const fetchData = async () => {
      if (dataSource === "Customers") {
        try {
          const customers = await listCustomers();
          console.log(customers);
          for (let customer of customers) {
            if (customer["VAT.TYPE"] !== "") {
              console.log(customer);
            }
          }
          let validCustomers = customers.filter((customer: any) => customer["NAME"] !== null);
          setModalData(validCustomers);
          setFilteredAndSearchedData(validCustomers);
        } catch (error) {
          console.error('There was a problem fetching customers:', error);
        }
      } else {
        try {
          const suppliers = await listSuppliers();
          let validSuppliers = suppliers.filter((supplier: any) => supplier["NAME"] !== null);
          setModalData(validSuppliers);
          setFilteredAndSearchedData(validSuppliers);
        } catch (error) {
          console.error('There was a problem fetching suppliers:', error);
        }
      }
      setFetching(false);
    };

    fetchData();
  }, [dataSource]);

  useEffect(() => {
    console.log(filteredAndSearchedData)
    if (filteredAndSearchedData.length === 1) {
      setData(filteredAndSearchedData[0])
      setModalOpen(false)
    }
  }, [filteredAndSearchedData]);

  return (
    <ModalWithChildren modalOpen={modalIsOpen} setModalOpen={setModalOpen}>
      {fetching 
        ? <LoadingState whatAreYouLoading={dataSource === "Customers" ? "Customers" : "Suppliers"} />
        : modalData.length > 0 
            ?<div className="flex flex-col flex-grow gap-4 p-4">
              <div className="h-[50px] w-full">
                <SearchBar initialData={modalData} setResults={setFilteredAndSearchedData} searchFunction={searchSuppliers} childQuery='' />
              </div>

              <div className="flex flex-col gap-4 dashboard-card bg-base-100 rounded-lg overflow-hidden">
                <div style={{maxHeight: "525px"}} className="overflow-y-scroll">
                  <table className="table-auto w-full">
                    <thead className="sticky top-0 bg-white border-b-2">
                      <tr className={`table-${dataSource === "Customers" ? "green" : "blue"}`}>
                        <th className="text-left pl-4 pt-4 text-sm">{dataSource === "Customers" ? "Customers" : "Suppliers"}</th>
                        <th className="text-left pt-4 text-sm">ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredAndSearchedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((dataEntry: Supplier | Customer, rowIndex: number) => (
                        <tr 
                          key={rowIndex} 
                          onClick={() => {
                            console.log(dataEntry)
                            setData(dataEntry)
                            setModalOpen(false)
                          }} 
                          className="cursor-pointer hover:bg-gray-100"
                        >
                          <td>
                            <div className="flex gap-4 items-center pr-2 hover:underline pl-4">
                              <p className="font-semibold text-sm">{dataEntry.NAME}</p>
                            </div>
                          </td>
                          <td>
                            <div className="flex gap-4 items-center pr-2 hover:underline">
                              <p className="font-semibold text-sm">{dataEntry["ACCNO"]}</p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                
                <div className="px-4 pb-4">
                  <Pagination 
                    numberOfEntries={modalData.length} 
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    source="daive"
                  />
                </div>
              </div>
            </div>
          : <>
              <p className="text-center">No {dataSource === "Customers" ? "customers" : "suppliers"} found.</p>
              <p 
                className="text-center font-semibold"
              >
                Click here to try again.
              </p>
            </>
      }
    </ModalWithChildren>
  );
}

export default SuppliersAndCustomersSearchModal;