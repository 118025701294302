import React, { useEffect, useState, useContext } from 'react';
import { useEcommerceContext } from './context';
import { Link } from 'react-router-dom';

import { handleProductSelect, isProductInBasket, removeItemFromBasket } from '../../components/basketUtils';

import Pagination from '../../components/pagination';

import { fetchProductData, sortAlphabeticallyWithNonAlphaAtEnd } from "../../utils";
import { Product } from '../../types';
import ProductFeature from './components/productFeature';

const SalesProductsPage = () => {
  const { basket, setBasket } = useEcommerceContext();
  const [activeBasketItem, setActiveBasketItem] = useState("");
  useEffect(() => {
    console.log(basket, activeBasketItem)
  }, [basket, activeBasketItem]);

  const [fetching, setFetching] = useState(true);
  const [productData, setProductData] = useState<Product[]>([]);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [productCategory, setProductCategory] = useState("Solar Panels");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;

  const searchParams = new URLSearchParams(window.location.search);

  const existingCategoryFilters = searchParams.get("category")?.split(",") || [];
  const [filterCategories, setFilterCategories] = useState([
    { title: "Solar Panels", enabled: existingCategoryFilters.includes("Solar Panels"), category: "Solar Panels" },
    { title: "Power Inverters", enabled: existingCategoryFilters.includes("Power Inverters"), category: "Power Inverters" },
    { title: "Battery Storage", enabled: existingCategoryFilters.includes("Battery Storage"), category: "Batteries" },
    { title: "EV Chargers", enabled: existingCategoryFilters.includes("EV Chargers"), category: "Vehicle Battery Chargers" },
    { title: "Portable Solar Products", enabled: existingCategoryFilters.includes("Portable Solar Products"), category: "Portable Solar Products" },
    { title: "Cables & Accessories", enabled: existingCategoryFilters.includes("Cables & Accessories"), category: "Cables & Accessories" },
    { title: "Solar Panel Mountings", enabled: existingCategoryFilters.includes("Solar Panel Mountings"), category: "Solar Panel Mountings" },
  ]);

  const existingBrandFilters = searchParams.get("brand")?.split(",") || [];
  const [filterBrands, setFilterBrands] = useState([
    { title: "Duracell Energy", enabled: existingBrandFilters.includes("Duracell Energy"), brand: "Duracell Energy" },
    { title: "EcoFlow", enabled: existingBrandFilters.includes("EcoFlow"), brand: "EcoFlow" },
    { title: "Enphase", enabled: existingBrandFilters.includes("Enphase"), brand: "Enphase" },
    { title: "Fox ESS", enabled: existingBrandFilters.includes("Fox ESS"), brand: "Fox ESS" },
    { title: "GivEnergy", enabled: existingBrandFilters.includes("GivEnergy"), brand: "GivEnergy" },
    { title: "Growatt", enabled: existingBrandFilters.includes("Growatt"), brand: "Growatt" },
    { title: "myenergi", enabled: existingBrandFilters.includes("myenergi"), brand: "myenergi" },
    { title: "SolarEdge", enabled: existingBrandFilters.includes("SolarEdge"), brand: "SolarEdge" },
    { title: "SolaX", enabled: existingBrandFilters.includes("SolaX"), brand: "SolaX" },
    { title: "Solis", enabled: existingBrandFilters.includes("Solis"), brand: "Solis" },
    { title: "Sunsynk", enabled: existingBrandFilters.includes("Sunsynk"), brand: "Sunsynk" },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchProductData();
        setProductData(products);
        setFilteredAndSearchedProducts(products);

        const uniqueSuppliers = Array.from(new Set(products.map(product => product["SUPPLIER"])));
        const uniqueProdGroups = Array.from(new Set(products.map(product => product["PGROUP.DESC"])));

        console.log('Unique Suppliers:', uniqueSuppliers);
        console.log('Unique Product Groups:', uniqueProdGroups);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredAndSearchedProducts(productData
      .filter((product: Product) => {
        const categoryFilterApplied = filterCategories.some(category => category.enabled);
        const brandFilterApplied = filterBrands.some(brand => brand.enabled);

        const categoryMatch = categoryFilterApplied
          ? filterCategories.some(category => category.enabled && category.category === product['PGROUP.DESC'])
          : true;

        const brandMatch = brandFilterApplied
          ? filterBrands.some(brand => brand.enabled && brand.brand === product['SUPPLIER'])
          : true;

        return categoryMatch && brandMatch
      })
    );
  }, [filterCategories, filterBrands, productData]);

  return (
    <div className="flex flex-col flex-grow gap-4 bg-white">
      <div className="container mx-auto py-12 flex gap-4 md:gap-10">
        <div className="shrink-0">
          <div className="shrink-0 sticky top-40 md:min-w-[200px]">
            <h2 className="text-2xl font-bold">Filters</h2>

            <div className="mt-8 border-t border-gray-400 pt-2">
              <h3 className="text-lg font-semibold pl-1">Categories</h3>
            </div>
            <div className="mt-4 flex flex-col gap-1 pl-1">
              {filterCategories.map((category, index) => (
                <div key={category.title} className="flex items-center gap-2">
                  <input 
                    type="checkbox"
                    className="checkbox-ecommerce"
                    id={category.title}
                    checked={category.enabled}
                    onChange={(e) => {
                      setFilterCategories(prev => {
                        const newFilters = [...prev];
                        newFilters[index].enabled = e.target.checked;
                        return newFilters;
                      });
                    }}
                  />
                  <label htmlFor={category.title}>{category.title}</label>
                </div>
              ))}
            </div>

            <div className="mt-4 border-t border-[#E9EAEB] pt-2 flex justify-between text-sm items-center px-1">
              <h3 className="font-semibold pl-1">Brands</h3>
              <i className="fa-solid fa-chevron-down text-muted"></i>
            </div>
            <div className="mt-4 flex flex-col gap-1 pl-2">
              {filterBrands.map((brand, index) => (
                <div key={brand.title} className="flex items-center gap-2">
                  <input 
                    type="checkbox"
                    className="checkbox-ecommerce"
                    id={brand.title}
                    checked={brand.enabled}
                    onChange={(e) => {
                      setFilterBrands(prev => {
                        const newFilters = [...prev];
                        newFilters[index].enabled = e.target.checked;
                        return newFilters;
                      });
                    }}
                  />
                  <label htmlFor={brand.title}>{brand.title}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex-grow">
          {fetching 
            ? <div className="flex justify-center items-center h-full">
                <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl"></i>
              </div>
            : <>
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-x-4 md:gap-x-8 gap-y-12">
                  {sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, "DESCRIPTION")
                    .sort((a: Product, b: Product) => {
                      if (a["Total Stock"] > 0 && b["Total Stock"] === 0) return -1;
                      if (a["Total Stock"] === 0 && b["Total Stock"] > 0) return 1;
                      return 0;
                    })
                    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                    .map((product: Product, rowIndex: number) => {
                      return <div className="flex flex-col">
                        <ProductFeature product={product} />
                      </div>
                    }
                  )}
                </div>

                <Pagination 
                  numberOfEntries={filteredAndSearchedProducts.length}
                  currentPage={currentPage} 
                  setCurrentPage={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                  source="ecommerce"
                />
              </>

          }
        </div>
      </div>
    </div>
  );
}

export default SalesProductsPage;