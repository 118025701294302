import MetricChangeHandler from "./metricChangeHandler";
import { formatNumber } from "../../utils";

type SimpleMetricCardProps = {
  identifier: string;
  count: number;
  change: number | null;
  period: string;
  metric: string;
  icon: string;
  fetching: boolean;
  theme?: string;
}

const SimpleMetricCard = ({ identifier, count, change, period, metric, icon, fetching, theme = "blue" }: SimpleMetricCardProps) => {

  const includeCurrencySymbol = metric === "Sales" || metric === "Profit" || metric === "Spend";

  return (
    <div className="dashboard-card">
      <div className={`card-header bg-${theme}`}>
        <h2 className="font-semibold">{identifier}&nbsp;{metric}&nbsp;this&nbsp;{period}</h2>
        <i className="cursor-pointer fa-regular fa-ellipsis-vertical ml-auto" ></i>
      </div>
      
      <div className="h-full my-4 w-full text-center">
        {fetching
          ? <div className="flex flex-col gap-4 items-center justify-center w-full h-28 rounded-md p-4">
              <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl md:text-4xl"></i>
              <h3 className="font-bold text-center">Fetching sales data...</h3>
            </div>
          : <>
              <h3 className="font-bold text-xl md:text-5xl mt-6 mb-1">
                {includeCurrencySymbol && "£"}
                {formatNumber(count)}
              </h3>
      
              
              {change && change !== 0 &&
                <p className="text-muted text-sm">
                  {fetching
                    ? <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF]"></i>
                    : <MetricChangeHandler metric={metric} metricChange={change || 0} giveTextColor={true} />
                  }
                  &nbsp;{(!change || change < 0) ? "less" : "more"} than this time last {period}.
                </p>
              }
            </>
        }
      </div>
    </div>
  )

}

export default SimpleMetricCard;