import React, { useState, useEffect } from 'react';
import { useScreen } from '../context';
import { listSuppliers, searchSuppliers, listCustomers, searchCustomers } from '../api';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import PageTabs from '../components/pageTabs';
import Pagination from '../components/pagination';
import SearchBar from '../components/searchBar';
import LightMetricCard from '../components/widgets/lightMetricCard';

import { formatNumber } from '../utils';
import { sortData } from '../components/tableSorting';
import { Tooltip } from 'react-tooltip'

import { Customer, Supplier } from '../types';
import { Resizable } from 're-resizable';

const MaintenancePage = () => {
  const tabOptions = ["Suppliers", "Customers"];
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || tabOptions[0];

  const [tab, setTab] = useState(initialTab);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState([] as any[]);
  const [customerData, setCustomerData] = useState([] as Customer[]);
  const [supplierData, setSupplierData] = useState([] as Supplier[]);
  const [fetching, setFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("MAINTENANCE");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  useEffect(() => {
    const fetchData = async () => {
      try {

        let validCustomers = customerData.filter((customer: any) => customer["NAME"] !== null);

        if (customerData.length === 0 ) {
          const customers = await listCustomers();
          validCustomers = customers.filter((customer: any) => customer["NAME"] !== null);
          setCustomerData(validCustomers);
        }

        let validSuppliers = supplierData.filter((supplier: any) => supplier["NAME"] !== null && typeof supplier["NAME"] === "string");

        if (supplierData.length === 0) {
          const suppliers = await listSuppliers();
          validSuppliers = suppliers.filter((supplier: any) => supplier["NAME"] !== null && typeof supplier["NAME"] === "string");
          setSupplierData(validSuppliers);
        }

      
        if (tab === "Customers") {
          setFilteredAndSearchedData(validCustomers);
        } else {
          setFilteredAndSearchedData(validSuppliers);
        }
      } catch (error) {
        console.error('There was a problem fetching suppliers:', error);
      }
      setFetching(false);
    };

    fetchData();
  }, [tab]);

  const updateTab = (tabString: string) => {
    if (tab === tabString) return
    
    setSortConfig({ key: defaultSortKey, direction: 'desc' });
    setFetching(true);
    setFilteredAndSearchedData([]);
    setTab(tabString);
    navigate(`?tab=${tabString}`);
  };

  const defaultSortKey = "NAME";
  const [sortConfig, setSortConfig] = useState({ key: defaultSortKey, direction: 'desc' });
  const handleSort = (key: string) => {
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      setSortConfig({ key, direction: 'desc' });
    } else {
      setSortConfig({ key, direction: 'asc' });
    }
  };
  const sortedData = sortData({ defaultSortKey, data: filteredAndSearchedData, sortConfig });

  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="topnav-title">
        <div>
          <h1 className="text-2xl md:text-4xl font-bold">Maintenance</h1>
          <h3>Manage your customers and suppliers</h3>
        </div>

        <Link
            to={tab === "Customers" ? "/customer/new" : "/supplier/new"}
            className={`btn btn-${tab === "Customers" ? "green" : "primary"} h-full ml-auto`}
          >
            <i className="fa-solid fa-plus mr-3"></i>
            {tab === "Customers" ? "Customer" : "Supplier"}
          </Link>
      </div>

      <div className="grid md:grid-cols-2 gap-4">
        <LightMetricCard title="Customers" count={customerData.length} icon="store" theme={tab === "Customers" ? "green" : "blue"} />
        <LightMetricCard title="Suppliers" count={supplierData.length} icon="warehouse" theme={tab === "Customers" ? "green" : "blue"} />
      </div>

      <div className="flex justify-between items-center flex-wrap h-[50px] gap-4">
        <PageTabs tabs={tabOptions} activeTab={tab} setActiveTab={updateTab} card={true} theme={tab === "Customers" ? "green" : "blue"} />
        <SearchBar
          childQuery=''
          initialData={tab === "Customers" ? customerData : supplierData}
          setResults={setFilteredAndSearchedData}
          searchFunction={tab === "Customers" ? searchCustomers : searchSuppliers}
        />
      </div>

      <div className="flex flex-col gap-4 dashboard-card rounded-lg overflow-hidden">
        {fetching 
          ? <div 
              className="flex justify-center items-center flex-col gap-4"
              style={{height: "calc(100vh - 200px)"}}
            >
              <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl md:text-4xl"></i>
              <h2 className="text-2xl">Fetching maintenance data...</h2>
            </div>
          : <>
              <div>
                <table className="table-auto w-full">
                  <thead>
                    <tr className={`table-${tab === "Customers" ? "green" : "blue"}`}>
                      {(tab === "Customers" 
                      ? [
                          { name: "Name", key: "NAME" },
                          { name: "Account Number", key: "ACCNO" },
                          { name: "Contact Name", key: "CONTACT" },
                          { name: "Contact Details", key: "EMAIL" },
                          { name: "YTD Spend", key: "YTD" },
                          { name: "Terms Group", key: "TERMS" }
                        ]
                      : [
                        { name: "Name", key: "NAME" },
                        { name: "Supplier Code", key: "ACCNO" },
                        { name: "Contact Name", key: "CONTACT" },
                        { name: "Contact Details", key: "PURCH.EMAIL" },
                        { name: "YTD Spend", key: "SUPP.YTD" }
                      ]).map((header, index) => {
                        if (header.name === "YTD Spend") {
                          return (
                            <th
                              key={index}
                              className={`text-left px-1 py-2 text-sm cursor-pointer ${index !== 4 ? `border-r border-${tab === "Customers" ? "green-500" : "primary"}` : 'text-center'}`}
                              onClick={() => handleSort(header.key)}
                            >
                              <Resizable 
                                key={index} 
                                enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                                minWidth={125} 
                                className="flex items-center justify-center gap-2 shrink-0 w-full"
                              >
                                <Tooltip id="my-tooltip" place='top-end' />
                                  <p 
                                    className="shrink-0" 
                                    data-tooltip-id="my-tooltip" 
                                    data-tooltip-content={`The total amount  ${tab === "Customers" ? "this customer has spent with you" : "you've spent with this supplier"}  this year.`}
                                  >
                                    {header.name}
                                  </p>
                                  {sortConfig.key === header.key && (
                                    <p><i className={`fa-solid fa-caret-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i></p>
                                  )}
                              </Resizable>
                            </th>
                          )
                        }
                        else return (
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSort(header.key)}
                          >
                              <Resizable 
                                key={index} 
                                enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                                minWidth={125} 
                                className={`flex items-center gap-2 shrink-0 w-full ${index !== 0 && "justify-center"}`}
                              >
                                <p className="shrink-0">{header.name}</p>
                                {sortConfig.key === header.key && (
                                  <p><i className={`fa-solid fa-caret-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i></p>
                                )}
                            </Resizable>
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData
                      .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                      .map((dataEntry: any, rowIndex: number) => {
                        const itemLink =
                          tab === "Customers"
                            ? `/customer/${dataEntry["ACCNO"]}`
                            : `/supplier/${dataEntry["ACCNO"]}`;
                        if (tab === "Suppliers") {
                          if (dataEntry["PURCH.EMAIL"] && dataEntry["PURCH.EMAIL"].includes(",")) {
                            dataEntry["PURCH.EMAIL"] = dataEntry["PURCH.EMAIL"].split(",")[0];
                          }
                        }
                        return (
                          <tr key={rowIndex}>
                            <td>
                              <Link className="text-left-override hover:underline" to={itemLink}>
                                <p className="font-semibold text-sm">{dataEntry.NAME}</p>
                              </Link>
                            </td>
                            <td>
                              <Link className="hover:underline" to={itemLink}>
                                <p className="font-semibold text-sm">{dataEntry["ACCNO"]}</p>
                              </Link>
                            </td>
                            <td>
                              <p className="font-semibold text-sm pl-3">{dataEntry.CONTACT}</p>
                            </td>
                            <td>
                              <p className="font-semibold text-sm pl-3">
                                {tab === "Customers" ? dataEntry["EMAIL"] : dataEntry["PURCH.EMAIL"]}
                              </p>
                              <p className="font-semibold text-xs pl-3">{dataEntry["PHONE"]}</p>
                            </td>
                            <td>
                              <p className="font-semibold pl-5 text-sm">
                                £{formatNumber(tab === "Customers" ? dataEntry["YTD"] : dataEntry["SUPP.YTD"])}
                              </p>
                            </td>
                            {tab === "Customers" && (
                              <td>
                                
                              </td>    
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <div className="px-4 pb-4">
                <Pagination
                  numberOfEntries={sortedData.length}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                  source="daive"
                />
              </div>
          </>
        }
      </div>
    </div>
  );
};

export default MaintenancePage;