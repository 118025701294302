const DoubleMetricCard = () => {
  return (
    <div className="dashboard-card flex-col">
      <div className="card-header bg-green mb-2">
        <h2 className="font-semibold">Your weekly performance</h2>
        <i className="cursor-pointer fa-regular fa-ellipsis-vertical ml-auto" ></i>
      </div>

      <div className="grid grid-cols-3 gap-4 text-center my-auto p-4">
        <div>
          <h3 className="font-bold text-xl md:text-4xl">12</h3>
          <p className="text-muted">Orders</p>
        </div>

        <div>
          <h3 className="font-bold text-xl md:text-4xl">15,121</h3>
          <p className="text-muted">Sales</p>
        </div>

        <div>
          <h3 className="font-bold text-xl md:text-4xl">281</h3>
          <p className="text-muted">Branches</p>
        </div>
      </div>
    </div>
  )

}

export default DoubleMetricCard;