import { useRef, useState } from "react"
import { ContactData, Sort } from "../types"
import { getSortByKey, setSortOn, sort } from "../utils"
import ContactEditor from "./contactEditor"
import SearchBar from "./searchBar"
import { SortColumnArrow } from "./widgets/sortColumnArrow"
import ModalWithChildren from "./modalWithChildren"

type ContactsGridProps = {
    contacts: ContactData[],
    setContacts: React.Dispatch<React.SetStateAction<ContactData[]>>,
    theme?: "green" | "blue",
    asGrid?: boolean
}

const ContactsGrid = ({contacts, setContacts, theme = "blue", asGrid = false}: ContactsGridProps) => {
    //-1 when tab not open
    const [currentContactIndex, setCurrentContactIndex] = useState(-1);
    const [contactSorts, setContactSorts] = useState<Sort<ContactData>[]>([]);
    const [searchedContacts, setSearchedContacts] = useState<ContactData[]>();
    const newContact = useRef(false);

    function noContacts(): boolean {
        return (!contacts || contacts.length === 0 || (searchedContacts && searchedContacts.length === 0)) ?? false;
    }

    const deleteButton = (index: number) => <button className="btn hover:bg-red-400 hover:text-white border border-gray-300 hover:border-red-400" onClick={() => {
        if (window.confirm("Are you sure you want to delete this contact?")) {
            contacts.splice(index, 1);
            setContacts([...contacts]);
        }
        }}>Delete</button>
    
    const sortButton = (buttonName: string, sortKey: keyof ContactData) => <button className={`btn btn-primary${theme === 'green' ? ' btn-green' : ''}`} onClick={() => setContactSorts(setSortOn(contactSorts, sortKey))}>{buttonName}<SortColumnArrow ascending={getSortByKey(contactSorts, sortKey)?.asc}/></button> 

    return (
        <>
            <div className="flex flex-col">
              <div className="w-full h-[50px]">
                <SearchBar initialData={contacts} setResults={setSearchedContacts} searchFunction={(searchTerm: string): Promise<any> => {
                    //VERY TEMPORARY. Replace with backend API call later.
                    return (async () => 
                        contacts.filter((contact) => {
                            for (const prop in contact) {
                                const key: keyof ContactData = prop as keyof ContactData;
                                if (key && contact[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())) return true
                            }
                            return false
                        })
                    )()
                }} childQuery={null} />
              </div>
                {!asGrid && <>
                    <div className="flex flex-row items-center gap-2 p-4">
                        <span className="font-extrabold">Sort by:</span>
                        {sortButton("Name", 'name')}
                        {sortButton("Email", 'email')}
                        {sortButton("Telephone", 'telephone')}
                    </div>
                    {contacts.length > 0 && 
                        <div className="max-h-80 overflow-y-auto flex flex-col gap-4">
                            {
                                sort(searchedContacts ? searchedContacts : contacts, contactSorts).map((contact, index) => 
                                    <div key={index} className="dashboard-card border border-gray-300 p-4 hover:bg-gray-100 cursor-pointer flex items-center">
                                        <div className="w-full" onClick={() => setCurrentContactIndex(index)}>
                                            <h3 className="font-semibold text-sm mb-1">{contact.name}</h3>
                                            {contact.email && <h3 className="text-gray-500 text-sm"><span className="font-bold">Email</span>: {contact.email}</h3>}
                                            {contact.telephone && <h3 className="text-gray-500 text-sm"><span className="font-bold">Tel</span>: {contact.telephone}</h3>}
                                        </div>
                                        <div className="flex flex-row items-end">
                                            {deleteButton(index)}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    }
                    {noContacts() && <span>No contacts found. {(!searchedContacts || searchedContacts.length < 1) && <>You can add contacts with the button below.</>}</span>}
                    </>
                }
                {asGrid &&
                    <table className={`table-auto divide-y divide-gray-200 w-full`}>
                        <thead>
                            <tr className={`table-${theme}`}>
                                <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'name'))}>Name<SortColumnArrow ascending={getSortByKey(contactSorts, 'name')?.asc}/></th>
                                <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'email'))}>Email<SortColumnArrow ascending={getSortByKey(contactSorts, 'email')?.asc}/></th>
                                <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'address'))}>Address<SortColumnArrow ascending={getSortByKey(contactSorts, 'address')?.asc}/></th>
                                <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'telephone'))}>Telephone<SortColumnArrow ascending={getSortByKey(contactSorts, 'telephone')?.asc}/></th>
                                <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'dateAdded'))}>Date Added<SortColumnArrow ascending={getSortByKey(contactSorts, 'dateAdded')?.asc}/></th>
                                <th className="hover:cursor-pointer" onClick={() => setContactSorts(setSortOn(contactSorts, 'lastAmended'))}>Last Amended<SortColumnArrow ascending={getSortByKey(contactSorts, 'lastAmended')?.asc}/></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {contacts.length > 0 && sort(searchedContacts ? searchedContacts : contacts, contactSorts).map((contact, index) => 
                            <tr className='h-14 hover:bg-gray-100'key={contact.name + index.toString()}>
                                <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.name}</td>
                                <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.email}</td>
                                <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.address}</td>
                                <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.telephone}</td>
                                <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.dateAdded.toLocaleDateString()}</td>
                                <td className="hover:cursor-pointer" onClick={() => setCurrentContactIndex(index)}>{contact.lastAmended.toLocaleString()}</td>
                                <td>
                                {deleteButton(index)}
                                </td>
                            </tr>
                            )}
                            {noContacts() && <tr className='h-14'><td colSpan={7}>No contacts found. {(!searchedContacts || searchedContacts.length < 1) && <>Please add contacts with the button below.</>}</td></tr>}
                        </tbody>
                    </table>
                }
                <button onClick={() => {
                    const newContacts: ContactData[] = [...contacts, {
                        name: '',
                        email: '',
                        address: '',
                        telephone: '',
                        dateAdded: new Date(),
                        lastAmended: new Date()
                    }];
                    newContact.current = true;
                    setContacts(newContacts); 
                    setCurrentContactIndex(newContacts.length - 1);
                }} className={`w-full btn btn-primary mt-4 ${theme === 'green' ? 'btn-green' : ''}`}><i className="fa-solid fa-plus mr-1 text-white"></i>Add</button>
            </div>
            {currentContactIndex !== -1 &&
                <ModalWithChildren modalOpen={true} setModalOpen={() => setCurrentContactIndex(-1)}>
                    <ContactEditor 
                        contactData={contacts[currentContactIndex]} 
                        theme={theme === "green" ? "green" : "blue"} 
                        closeTab={() => {
                            newContact.current = false; 
                            setCurrentContactIndex(-1)
                        }} 
                        deleteContact={() => {
                        contacts.splice(currentContactIndex, 1);
                        setContacts([...contacts]);
                    }}
                    newContact={newContact.current}/>
                </ModalWithChildren>
            }
        </>
    )

}

export default ContactsGrid;