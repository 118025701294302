import { useRef, useState } from "react";
import { AvailableThemes, Email, EmailAttachment } from "../types";
import Attachment from './emailAttachment';

type CommentInputProps = {
    currentUsername: string,
    currentEmail: string,
    currentUserPfp?: string,
    postComment: (text: string, attachments: EmailAttachment[]) => void
    theme?: AvailableThemes
}

type UploadAttachmentObj = {
    fileName: string,
    progressPercentage: number,
    totalFileSizeInBytes: number
}

const CommentInput = ({currentUsername, currentEmail, currentUserPfp, postComment, theme = "green"}: CommentInputProps) => {
    const [commentInput, setCommentInput] = useState('');
    const [attachments, setAttachments] = useState<EmailAttachment[]>([]);
    const uploadRef = useRef<HTMLInputElement>(null);
    const [uploadingAttachments, setUploadingAttachments] = useState<UploadAttachmentObj[]>([])

    const allowedExtensions: string[] = [".txt", ".xlsx", ".xls", ".pdf", ".docx", ".xps", ".png", ".bmp", ".jpeg", ".jpg", ".gif", ".csv", ".doc", 
        ".xml", ".rtf", ".docm", ".mp3", ".mp4", ".flv", ".mid", ".midi", ".mov", "pptx", ".ppt", ".pps", ".psd", ".pub", ".rar", ".7zip", ".zip", 
        ".tif", ".tiff", ".vsd", ".vsdx", ".wav"];

    function clearCommentFields(): void {
        setCommentInput('');
        setAttachments([]);
        setUploadingAttachments([]);
    }

    function isAllowedFileType(fileName: string): boolean {
        return !allowedExtensions.includes('.' + fileName.split('.').reverse()[0]);
    }

    function onCommentPosted(): void {
        if (commentInput) {
            postComment(commentInput, attachments);
            clearCommentFields();
        }
    }

    async function fileUploaded(e: React.ChangeEvent<HTMLInputElement>) {
        const files: File[] = Array.from((e.target as HTMLInputElement).files || []);
        if (files) {
            //Set up all the uploading UI elements first synchronously since setting it up asynchronously causes files to show up in the wrong order.
            //Also, rendering React asynchronously has a horrible habit of not working properly.
            files.forEach((file) => {
                const uploadingAttachmentObj = {
                    fileName: file.name,
                    progressPercentage: 0,
                    totalFileSizeInBytes: file.size
                };
                uploadingAttachments.push(uploadingAttachmentObj);
            })
            setUploadingAttachments([...uploadingAttachments]);
            //Upload asynchronously...
            let newAttachments: EmailAttachment[] = [];
            await Promise.all(files.map(async (file, index) => {
                if (isAllowedFileType(file.name)) {
                    alert(`File ${file.name} is an illegal file type!`);
                } else {
                    newAttachments.push(await attachFile(file, attachments.length + index));
                }
            }));
            //And update the UI...
            /* TODO: This causes the UI to not show the upload boxes as they finish uploading, since it's all updating in one big go. 
             * 
             * More importantly, does this matter?
             */
            setAttachments([...attachments, ...newAttachments]);
        }
    }

    async function attachFile(file: File, attachmentObjIndex: number): Promise<EmailAttachment> {
        //Upload file as a Blob object
        //TODO:
        //Also TODO: Put any file that might be a security risk in its own folder on the server for it to scan and move into the uploads folder itself

        //Get new content ID from backend (probably in the success statement of the API call)
        let contentId: string = '';
        //TODO:

        //Pull attachment data from backend, including thumbnail
        //TODO:

        //TEMP: Simulate an upload.
        return new Promise((resolve) => {
            const attachment: EmailAttachment = {
                fileName: file.name,
                contentId: contentId,
                fileSizeInBytes: file.size
                //thumbnail: ''
            };
        
            const simulateProgress = () => {
                uploadingAttachments[attachmentObjIndex].progressPercentage += 25
                setUploadingAttachments([...uploadingAttachments]);
                console.log(`Progress to ${uploadingAttachments[attachmentObjIndex].progressPercentage}`)

                if (uploadingAttachments[attachmentObjIndex].progressPercentage < 100) setTimeout(simulateProgress, Math.random() * 1000);
                else resolve(attachment);
            }
            setTimeout(() => {
                simulateProgress();
            }, 500);
        });

        //ENDTEMP
        
        //Put in attachments
        // setAttachments([...attachments, attachment]);
    }

    return (
        <div className="dashboard-card min-h-20 flex flex-row w-full pt-2 pb-4 gap-3 hover:bg-gray-50 hover:border-gray-50">
            <div className="min-w-20 w-1/5 p-2">
                {/* Profile picture */}
                <img src={currentUserPfp ?? "tayah.png"} alt={currentUsername} height={64} width={64} className="h-[64px] w-[64px] rounded-full"/>
            </div>
            <div className="flex flex-col w-4/5 gap-2 pr-2">
                <div className="flex flex-row gap-2 place-items-center mt-0.5">
                    <span className="font-bold" title={currentUsername}>{currentUsername}</span>
                    <span className="text-gray-500 w-full truncate" title={currentEmail}>{currentEmail}</span>
                </div>
                <div className="flex flex-row w-full gap-2 flex-wrap">
                    {/* Attachments */}
                    {attachments && attachments?.map((value, index) => 
                        <Attachment
                            fileName={value.fileName}
                            fileSizeInBytes={value.fileSizeInBytes}
                            contentId={value.contentId}
                            thumbnail={value.thumbnail}
                            isFirstComment={true}
                            theme={theme}
                            key={index}
                        />
                    )}
                    {uploadingAttachments && uploadingAttachments?.filter((value) => value.progressPercentage < 100).map((value, index) => 
                        <Attachment
                            fileName={value.fileName}
                            fileSizeInBytes={0}
                            contentId=""
                            uploadingPercentage={value.progressPercentage}
                            theme={theme}
                            key={index}
                        />
                    )}
                    <button onClick={() => uploadRef.current?.click()} title="Add attachment..."><i className="fa-solid fa-plus text-gray-300 hover:text-gray-500" /></button>
                    <input 
                        type="file" 
                        multiple 
                        className="hidden" 
                        ref={uploadRef} 
                        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {await fileUploaded(e)}}
                        accept={allowedExtensions.join(', ')}
                    />
                </div>
                <div className="w-full">
                    <textarea value={commentInput} placeholder="Start typing..." className="resize-none w-full h-28 bg-gray-100" onChange={(e: React.ChangeEvent) => setCommentInput((e.target as HTMLTextAreaElement).value)}/>
                </div>
                <div className="w-full flex flex-row gap-2">
                    <button onClick={onCommentPosted} className={`btn btn-primary bg-${theme}`}>Post Comment</button>
                    <button onClick={clearCommentFields} className={`btn btn-secondary${theme === 'green' ? '-green' : ''}`}>Cancel</button>
                </div>
            </div>
            
            
        </div>
    )
}

export default CommentInput;